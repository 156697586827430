import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { string } from 'yup';
import styled from '@emotion/styled';
import BankAccount from './Components/BankAccount/BankAccount';
import {MpesaComponent} from './Components/Mpesa/MpesaComponent';
import { DefaultBtn } from '../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 6}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



export default function FullWidthTabs({bankData,currency,mobilePaymentmethoad,bankPaymentMethoad,formik , countryId , PayOutMethodsByCountryId}:any) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  
  console.log(countryId , "wajidCNN")
const[asdasdsad,setDjkkkjjj]=React.useState("")
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;


const mobileMoneyMethods = PayOutMethodsByCountryId.filter((method : any) => method.payment_method === "Mobile Money");
const bankTransferMethods = PayOutMethodsByCountryId.filter((method : any ) => method.payment_method === "Bank Transfer");
///I defined the active button here beacause it was throwing an error because
//of mobile Money methods blocked-scope
console.log(mobileMoneyMethods , "wajidmobile");
console.log(bankTransferMethods , "wajidbank");
const [activeButton, setActiveButton] = React.useState(mobileMoneyMethods[0]?.key);
console.log(mobileMoneyMethods[0]?.key , "wajidmobilekey");

React.useEffect(()=>{
  if(bankTransferMethods.length == 0){
    setValue(0)
  }else if(mobileMoneyMethods.length == 0){
    setValue(1)
  }
},[mobileMoneyMethods,bankTransferMethods])

  return (
    <Box sx={{ width:"100%" }}>
      <AppBar 
      style={{boxShadow:"none"}}
      position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#025041",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#025041",
            },
            "& .MuiTab-root": {
              textTransform: "none",
              textAlign:"left !important",
              fontFamily: "Poppins",
            },
          }}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          style={{
            backgroundColor: '#ffffff',
            color: '#000',
            borderBottom:"1px solid #DDDDDD",
            boxShadow:"none",
            fontFamily:"Poppins"
          }}
        >
        <Tab disabled={mobileMoneyMethods.length > 0?false:true} label="Mobile Money" style={{fontSize:"13px"}} {...a11yProps(0)} />
          <Tab disabled={bankTransferMethods.length > 0?false:true} label="Bank account" style={{fontSize:"13px"}} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <StyledTabPanel value={value} index={1} dir={theme.direction}>
        <BankAccount bankTransferMethods={bankTransferMethods} formik={formik} currency={currency} bankPaymentMethoad={bankPaymentMethoad} bankData={bankData}/>
      </StyledTabPanel>
      <StyledTabPanel value={value} index={0} dir={theme.direction}>
        <MpesaComponent mobileMoneyMethods={mobileMoneyMethods}  currency={currency} activeButton={activeButton} setActiveButton={setActiveButton} formik={formik} mobilePaymentmethoad={mobilePaymentmethoad}/>
      </StyledTabPanel>
      <DefaultBtn text="Add recipient" style={{width:"100%" , marginTop:"30px"}} />
    </Box>
  );
}
