import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Checkbox, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DynamicSelectComponent, InputSelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CustomInput, InputDropBox } from '../../../../../../../ui/ReuseableComponents/Inputs/Inputs';
import './step2.scss'
import { DefaultBtn, DefaultGoBackBtn, LightBtn } from '../../../../../../../ui/ReuseableComponents/Buttons/Buttons';
import { isDataView } from 'util/types';
import AddNewPayee from '../Step1/Components/AddNewPayee';
import AddPaymentMethod from '../../../../../../../ui/ReuseableComponents/Payment/AddPaymentMethod';
import { TheSelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import { Select, MenuItem, ListItemText, InputLabel, FormControl, Chip, OutlinedInput } from '@mui/material';
import MultipleSelectCheckmarks from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CurrencySelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs';
import { payablesFlowactions } from '../../../../../../../../_actions';
import { useDispatch } from 'react-redux';
import { UploadIcon } from '../../../../../../../ui/ReuseableComponents/Icons/Icons';
import { handleFileUpload } from '../../../../../../../utils';
import { ErrorMessage } from '../../../../../../../ui/eroor';

////Static Vat List

const vatList = ["Exempt", "0%", "8%", "16%"]




const Step2 = ({ errorsMsg, formik, payee, onBack, onNextStep, stepupdatefun, step, teamData, categoriesData, selectpayees, isAddPayeeComponent, setIsAddPayeeComponent, selectedCurrency, selectedFlag }: any) => {
  const [selectedValue, setSelectedValue] = useState<string>('default');
  const [inputValue, setInputValue] = useState<string>('');
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const[amount,setAmount]=useState(0)
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [IsVatOpen, setIsVatOpen] = useState(false);
  const [isMethodOpen, setIsMethodOpen] = useState(false);
  const [selectedVat, setSelectedVat] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const [currency, setCurrency] = useState([]);
  const [render, setRender] = useState(true)
  const [invoiceImage, setinvoiceImage] = useState<any>("");
  const [IsDocumentOpen, setIsDocumentOpen] = useState(false)
  const [SelectFleg, setSelectFleg] = useState('https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Kenya-Flag.png')
  const [paymentData, setPaymentData] = useState<any>({
    amount: "",
    input_field_data: "",
    bankName: "",
  });
  const [fileSize, setFileSize] = useState(0);
  const [invoicefile, setinvoicefile] = useState("Upload supporting document");
  const [description, setDescription] = useState("")
  const [IsDescriptionOpen, setIsDescriptionOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [errorsmsgapi, setErrorMsgapi] = useState("")
  const dispatch = useDispatch()

  const handleTeamsChange = (event: any) => {
    const { target: { value } } = event;
    setSelectedTeams(typeof value === 'string' ? value.split(',') : value);
  };



  const handleCategoryChange = (event: any) => {
    const { target: { value } } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };

  const handleVatChange = (event: any) => {
    setSelectedVat(event.target.value);
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleBankAndMethod = () => {
    setIsMethodOpen(!isMethodOpen)
  }

  // Function to mask the input value except last 4 digits
  const getMaskedValue = (value: string) => {
    if (!value) return '';
    const lastFourDigits = value.slice(-4);
    const maskedPart = '*'.repeat(Math.max(0, value.length - 4));
    return maskedPart + lastFourDigits;
  };

  const handleCategoryOpen = () => {
    setIsCategoryOpen(!isCategoryOpen)
    setIsVatOpen(false)
    setIsMethodOpen(false)
    setIsTeamOpen(false)
  }
  const handleTeamOpen = () => {
    setIsTeamOpen(!isTeamOpen)
    setIsCategoryOpen(false)
    setIsMethodOpen(false)
    setIsVatOpen(false)
    console.log(teamData)

  }
  const handleVatOpen = () => {
    setIsVatOpen(!IsVatOpen)
    setIsTeamOpen(false)
    setIsCategoryOpen(false)
    setIsMethodOpen(false)
  }
  const CurrancyList = (data: any) => {
    // setCurrency(data)
    var NewArray: any = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if (element.currency == "GHS") {

      }
      else {
        NewArray.push(element)
      }
    }
    setCurrency(NewArray)
  }
  useEffect(() => {
    if (render) {

      dispatch(payablesFlowactions.getApipayment("/get-all-currencies-details-by-user", CurrancyList) as any);
    }
  }, [render])

  console.log(selectpayees, "selectpayees");

  const handleImage = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileSize(file.size);
      setinvoicefile(file.name);
      const fileBase64 = await handleFileUpload(e);
      if (file.size && file.size <= 1024 * 1024 * 5) {
        setinvoiceImage(fileBase64);
        formik.setFieldValue("invoice_record", fileBase64)
      } else {
        setinvoiceImage('');
      }
    }
  };

  const ChackBlence = (paymentchannel: any, payment_method: any) => {

    var body = {
      "currency": formik.values.currency,
      "country_id": '1',
      // formik.values.country_id,
      "type": "pay_out", //payout, payin
      "payment_method": 'Mobile Money',
      // payment_method,
      "amount": formik.values.amount,
      "paymentchannel": 'send money'
      //  paymentchannel
    }
    const CheckBalance = (data: any) => {
      // formik.setFieldValue("", data)
    }
    dispatch(payablesFlowactions.PostPaymentfull("/bank/check-balance-v3", body, CheckBalanceRES, setLoader) as any);
  }
  const CheckBalanceRES = (data: any) => {
    console.log(data, "datadata852");
    if (data.success == true) {

    } else if (data.success == false) {
      setErrorMsgapi(data.message)
    }
  }
  useEffect(() => {
    if (!!formik.values.currency && !!formik.values.country_id && !!formik.values.type) {
      ChackBlence(formik.values.payment_channel,formik.values.payment_method)
    }
  }, [amount])
  return (
    <Box>
      <DefaultGoBackBtn style={{ marginTop: "-60px" }} onClick={() => stepupdatefun(0)} />
      <Box className="d-flex flex-column">
        <Typography className="step2_heading">How much are you sending?</Typography>
        <Box className="inputs_container">
          <Box>
            <Typography className="label">You send</Typography>
            <InputSelectBox onChange={(e: any) => {
              console.log(e.target.value, "e.target.value");
              setAmount(e.target.value)
              formik.setFieldValue("amount", e.target.value)
            }} value={formik.values.amount} placeholder="Enter amount" currencyList={<Box className="d-flex gap-1 align-items-center">
              <img src={selectedFlag} alt='' style={{ width: "22px", height: "18px", borderRadius: "50%", padding: "0px", margin: "0px" }} />
              <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }}>{selectedCurrency}</Typography>
            </Box>} />
            {formik.touched.amount && formik.errors.amount ? (
              <ErrorMessage>{formik.errors.amount}</ErrorMessage>
            ) : null}
          </Box>
          <Box>
            <Typography className="label">{selectpayees.name}'s Payment Details</Typography>
            <InputDropBox
              inputValue={getMaskedValue(paymentData.input_field_data)}
              disabled={!!formik.values.amount ? false : true}
              style={{ color: "black" }}
              placeholder={paymentData.input_field_data}
              arrowForwardIcon={(<Box style={{ width: "max-content" }} className="d-flex align-items-center gap-3">
                <Typography onClick={() => {
                  if (!!formik.values.amount) {
                    handleBankAndMethod()
                  }
                }} style={{ cursor: "pointer", fontFamily: "Poppins" }}>{paymentData.bankName}</Typography>
                <ArrowForwardIosIcon
                  style={{
                    transform: `rotate(${isMethodOpen ? 90 : 0}deg)`,
                    transition: "transform 0.3s ease",
                  }}
                  className="arrowforwardicon"
                  onClick={() => {
                    if (!!formik.values.amount) {
                      console.log(formik.values, "wajidformikvalues");
                      console.log(formik, "wajidformik");
                      handleBankAndMethod()
                    }
                  }}
                />
              </Box>)} />
            {formik.touched.type && formik.errors.type ? (
              <ErrorMessage>{formik.errors.type}</ErrorMessage>
            ) : null}
            <Box style={{ height: isMethodOpen ? "500px" : 0, overflowY: "scroll", transition: "height 0.3s ease" }}>
              {isMethodOpen && (
                <Box>
                  <AddPaymentMethod ChackBlence={ChackBlence} formik={formik} SelectCurrency={SelectCurrency} enterAmount={formik.values.amount} setIsAddPayeeComponent={setIsAddPayeeComponent} stepupdatefun={stepupdatefun} paymentData={paymentData} setPaymentData={setPaymentData} selectedPayee={selectpayees} payee={selectpayees} />
                </Box>
              )}
            </Box>
          </Box>

        </Box>


        <Box className="paydata_container d-flex gap-4 flex-column">
          <Typography className="paydata_heading" style={{ fontFamily: "Poppins" }}>Paying from</Typography>
          <Box className="d-flex justify-content-between align-items-center">
            <Box className="d-flex gap-3">
              <img src="https://picsum.photos/200" alt='' style={{ width: "45px", height: "45px", borderRadius: "50%" }} />
              <Box className="d-flex flex-column">
                <Typography className='bank_account'> Niobi Account *0343</Typography>
                <Typography className='available_balance'>{formik.values.currency} {formik.values.balance / 100} available</Typography>
              </Box>
            </Box>
            <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
              {/* <Typography className="paydata_title" style={{ cursor: "pointer", color: "#025041", fontSize: "15px" }}>Change</Typography> */}
              {/* <ArrowForwardIosIcon className='arrowicon' /> */}
            </Box>
          </Box>
          <Box className="paydata_item d-flex justify-content-between align-items-center">
            <Typography className='paydata_title'>Fees</Typography>
            <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
              <Typography className="info">0.00 KES</Typography>
              <ArrowForwardIosIcon className="arrowicon" />
            </Box>
          </Box>


          <Box className="payadata_item_wrapper d-flex flex-column">
            <Box className="paydata_item d-flex justify-content-between ">
              <Typography className='paydata_title'>Category</Typography>
              <Box className="d-flex gap-3 " style={{ cursor: "pointer" }}>
                <Typography className='info' onClick={() => handleCategoryOpen()}>
                  {formik.values.tags.length === 0 ? "Select Category" : formik.values.tags.map((category: any, index: number) => (
                    <Typography key={index} style={{ color: "#025041", fontFamily: "Poppins" }}>
                      {category}
                    </Typography>

                  ))}
                </Typography>
                <ArrowForwardIosIcon className='arrowicon'
                  onClick={() => handleCategoryOpen()}
                  style={{
                    transform: `rotate(${isCategoryOpen ? 90 : 0}deg)`,
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
            </Box>
            <Box className="vat_box" style={{ height: isCategoryOpen ? "160px" : 0, overflow: "hidden", transition: "height 0.3s ease", overflowY: "auto" }}>
              <Typography className='vat_heading'>Select Categories</Typography>
              {isCategoryOpen && (
                <Box>
                  <Autocomplete
                    multiple
                    options={categoriesData.filter((category: any) => category && category.name).map((category: any) => category.name)}
                    value={formik.values.tags}
                    getOptionLabel={(option: any) => option}
                    onChange={(event: any, newValue: any) => {
                      formik.setFieldValue("tags", newValue)
                    }}
                    renderInput={(params: any) => <TextField {...params} placeholder="Categories" />}
                    sx={{
                      '& .MuiInputBase-root': {
                        display: 'flex',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        borderRadius: '8px',
                        padding: '6px 9px',
                      },
                    }}
                  />
                  {formik.touched.tags && formik.errors.tags ? (
                    <ErrorMessage>{formik.errors.tags}</ErrorMessage>
                  ) : null}
                </Box>
              )}
            </Box>
          </Box>



          <Box className="payadata_item_wrapper d-flex flex-column">
            <Box className="paydata_item d-flex justify-content-between">
              <Typography className='paydata_title'>Teams</Typography>
              <Box className="d-flex gap-3" style={{ cursor: "pointer" }}>
                <Typography className='info' onClick={() => handleTeamOpen()}>
                  {formik.values.teams.length === 0 ? "Select team" : formik.values.teams.map((team: any, index: number) => (
                    <Typography key={index} style={{ color: "#025041", fontFamily: "Poppins", textAlign: "right" }}>
                      {team}
                    </Typography>
                  ))}

                </Typography>
                <ArrowForwardIosIcon className='arrowicon'
                  onClick={() => handleTeamOpen()}
                  style={{
                    transform: `rotate(${isTeamOpen ? 90 : 0}deg)`,
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
            </Box>
            <Box className="vat_box" style={{ height: isTeamOpen ? "160px" : 0, overflow: "hidden", transition: "height 0.3s ease", overflowY: "auto" }}>
              <Typography className='vat_heading'>Select Team</Typography>
              {isTeamOpen && (
                <Box>
                  <Autocomplete
                    multiple
                    options={teamData.filter((team: any) => team && team.name).map((team: any) => team.name)}
                    value={formik.values.teams}
                    getOptionLabel={(option: any) => option}
                    onChange={(event: any, newValue: any) => {
                      formik.setFieldValue("teams", newValue)
                    }}
                    renderInput={(params: any) => <TextField {...params} placeholder="Teams" />}
                    sx={{
                      '& .MuiInputBase-root': {
                        display: 'flex',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        borderRadius: '8px',
                        padding: '6px 9px',
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box className="paydata_item_wrapper d-flex flex-column">
            <Box className="paydata_item d-flex justify-content-between align-items-center">
              <Typography className='paydata_title'>Vat</Typography>
              <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                <Typography className='info' onClick={() => handleVatOpen()}>{formik.values.vat ? formik.values.vat : "Select Vat"}</Typography>
                <ArrowForwardIosIcon className='arrowicon'
                  onClick={() => handleVatOpen()}
                  style={{
                    transform: `rotate(${IsVatOpen ? 90 : 0}deg)`,
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
            </Box>
            <Box className="vat_box" style={{ height: IsVatOpen ? "160px" : 0, overflow: "hidden", transition: "height 0.3s ease" }}>
              <Typography className='vat_heading'>Select Vat %</Typography>
              {IsVatOpen && (
                <Box>
                  <TheSelectBox
                    value={formik.values.vat}
                    options={vatList}
                    defaultOption="Select Vat"
                    onChange={(e: any) => {
                      formik.setFieldValue("vat", e.target.value)

                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box className="paydata_item_wrapper d-flex flex-column">
            <Box className="paydata_item d-flex justify-content-between align-items-center">
              <Typography className='paydata_title'>Description</Typography>
              <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                <Tooltip title={description ? description : ""}>
                  <Typography className='info' onClick={() => setIsDescriptionOpen(!IsDescriptionOpen)}>{formik.values.description ? `${formik.values.description.slice(0, 11)}...` : "Enter description"}</Typography>
                </Tooltip>
                <ArrowForwardIosIcon className='arrowicon'
                  onClick={() => setIsDescriptionOpen(!IsDescriptionOpen)}
                  style={{
                    transform: `rotate(${IsDescriptionOpen ? 90 : 0}deg)`,
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
            </Box>
            <Box className="vat_box" style={{ height: IsDescriptionOpen ? "160px" : 0, overflow: "hidden", transition: "height 0.3s ease" }}>
              <Typography style={{ borderBottom: "1px solid #e0e0e0", paddingBottom: "10px" }} className='vat_heading'>Description</Typography>
              {IsDescriptionOpen && (
                <Box className="mt-4">

                  <CustomInput
                    value={formik.values.description}
                    onChange={(e: any) => formik.setFieldValue("description", e.target.value)
                    }
                    placeholder="Enter description"
                  />
                </Box>
              )}
            </Box>
          </Box>





          {formik.values.currency === "USD" && parseFloat(formik.values.amount) >= 10000 && (
            <Box className="paydata_item_wrapper d-flex flex-column">
              <Box className="paydata_item d-flex justify-content-between align-items-center">
                <Typography className='paydata_title'>Supporting document</Typography>
                <Box className="d-flex gap-3 align-items-center " style={{ cursor: "pointer" }}>
                  <Tooltip title={invoicefile ? invoicefile : ""}>
                    <Typography className='info' onClick={() => setIsDocumentOpen(!IsDocumentOpen)}>{invoicefile ? invoicefile.slice(0, 20) + (invoicefile.length > 20 ? "..." : "") : "Select Document"}</Typography>
                  </Tooltip>
                  <ArrowForwardIosIcon className='arrowicon'
                    onClick={() => setIsDocumentOpen(!IsDocumentOpen)}
                    style={{
                      transform: `rotate(${IsDocumentOpen ? 90 : 0}deg)`,
                      transition: "transform 0.3s ease",
                    }}
                  />
                </Box>
              </Box>
              <Box className="vat_box" style={{ height: IsDocumentOpen ? "160px" : 0, overflow: "hidden", transition: "height 0.3s ease" }}>
                <Typography style={{ borderBottom: "1px solid #e0e0e0", paddingBottom: "10px" }} className='vat_heading'>Upload Supporting document</Typography>
                {IsDocumentOpen && (
                  <Box className="mt-4">
                    <label className="complete-profile__upload mb-0">
                      <div style={{ height: "48px" }} className="complete-profile__upload__label">
                        <span className="complete-profile__upload__txt">{invoicefile}</span>
                        <UploadIcon style={{ width: "20px" }} />
                      </div>
                      <input type="file" name="invoice_record" onChange={(e) => handleImage(e)} />
                      {fileSize > 1024 * 1024 * 5 && (
                        <div className="error-message">File size too large, max file size is 5 Mb</div>
                      )}
                    </label>
                  </Box>
                )}
              </Box>
            </Box>
          )}

          {!!errorsMsg ? <ErrorMessage>{errorsMsg}</ErrorMessage> : ""}
          {!!errorsmsgapi ? <ErrorMessage>{errorsmsgapi}</ErrorMessage> : ""}
        </Box>

        <Box className="d-flex justify-content-between align-items-center" style={{ fontFamily: "Poppins" }}>
          <LightBtn text="Schedual for later" style={{ fontFamily: "Poppins" }} />
          <DefaultBtn
            onClick={() => {
              // stepupdatefun(2)
            }}
            text="Pay now" />
        </Box>
      </Box>
    </Box>

  );
};

export default Step2;