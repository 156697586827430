import { Form, InputGroup } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getBase64, getKESFormate } from "../../_helpers/common";


export const AmountInput = ({ velue, onChangeset, currancy, name }: any) => {
    const numberMask = createNumberMask({
        prefix: "",
        suffix: "", // This will put the dollar sign at the end, with a space.
    });
    return (
        <>
            <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>
                <MaskedInput
                    mask={numberMask}
                    style={{ height: "100%" }}
                    className="doc-form-input form-control"
                    placeholder="Amount"
                    value={velue}
                    name={name}
                    onChange={onChangeset}
                    aria-describedby="basic-addon3"
                />
                <InputGroup.Text className="input-mask" id="basic-addon3">
                    {currancy}</InputGroup.Text>
            </InputGroup>
        </>
    )
}
export const InputNumber = ({ onChangeset, label, value, name, mainDevstyle,disabled,requiredFild }: any) => {

    return (
        <>
            <div style={mainDevstyle} className={requiredFild=="NotActive"?"input-form-wrapper":"input-form-wrapper dot"}>
                <label>{label}</label>
                <InputGroup style={{ fontSize: '12px' }} className="doc-input-wrapper">
                    <Form.Control
                    disabled={disabled}
                        className={
                            'doc-form-input test valid'}
                        placeholder={label}

                        value={value}
                        name={name}
                        type="text"
                        onChange={onChangeset}

                    />
                </InputGroup>
            </div>
        </>
    )
}

export const DateInput = ({mainDevstyle,label,velue,onChangeset,name }: any) => {

    return (
        <div style={mainDevstyle} className="input-form-wrapper dot">
            <label>{label}</label>
            <input type="date"
                className="doc-date doc-form-input form-control"
                // onChange={setInvoiceDate}
                value={velue}
                // format="yyyy-MM-dd"
                name={onChangeset}
                onChange={onChangeset}
            />
        </div>
    )
}