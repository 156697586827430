import styles from './Preview_fils.module.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Grid, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { paymentJourneyActions } from '../../../../../../../_actions/paymentJourney.actions';
import Dialog from '@mui/material/Dialog';
import React, { useMemo, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { selectPaymentJourneyData } from '../../../../../../../_reducers/paymentJourney.reducer';
import posthog from 'posthog-js';
import amplitude from 'amplitude-js';
import { getProperties } from '../../../../../../../_helpers';
import countryList from 'react-select-country-list';
import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Preview_fils = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [ShareButton, setShareButton] = React.useState('');
  const [showModal, setShow] = React.useState(false);
  const [datainline, seDatainline] = React.useState<any>('');
  // const [redusar, setRedusar] = React.useState(false);
  // const [redusar_ERROR, setRedusar_ERROR] = React.useState(false);
  // const [redusar_two, setRedusar_two] = React.useState(false);
  // const [redusar_two_ERROR, setRedusar_two_ERROR] = React.useState(false);
  // const [invoiceStatus, setInvoiceStatus] = useState('')
  const [message, setMessage] = React.useState('');
  const [messageBody, setMessageBody] = React.useState('');
  const [loader, setLoader] = React.useState(false)
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const properties = getProperties();
  const options = useMemo(() => countryList().getData(), []);

  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };

  const onClose = () => {
    setShow(false);
  };
  const dispatch = useDispatch();
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });


  const handleClickOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Handsubmit = () => {
    if (props.validesanpayload == true) {
      handleClickOpenShare();
    } else {
      props.onCancel();
    }
  };


  const Shareapi = (ButtonType: String) => {
    const skipValidation = true;

    if (props.validesanpayload == true) {
      const findcurrencies: any = options.find(
        (data: any) => data.label == props?.data?.company_info_Country
      );

      const findcurrenciescustomer: any = options.find(
        (data: any) => data.label == props?.data?.Receiver_info_Country
      );

      const body: {
        invoice_name: any;
        logo: any;
        invoice_no: any;
        type: string;
        currency: any;
        due_date: any;
        company_info: any;
        customer: any;
        discount_type: any;
        discount_value: any;
        memo: any;
        sub_total: any;
        tax_percentage: any;
        tax: any;
        discount: any;
        total: any;
        line_items?: any[];
        existing_products?: any[];
        payment_link: boolean;
        invoice_status: any;
      } = {
        invoice_name: props.newInvoiceName,
        logo: !!props?.invoiceImage?props?.invoiceImage:null,
        invoice_no: props?.info?.Invoice,
        type: "invoice",
        invoice_status: ButtonType === 'Download_PDF' ? 'issued':'draft' ,
        payment_link: props?.paymentLink,
        currency: props?.info?.Currency,
        due_date: props?.info?.Due_date,

        company_info: {
          name: props?.data?.company_info_Name,
          title: props?.data?.company_info_Title,
          email: props?.data?.company_info_Email,
          mobile: props?.data?.company_info_Mobile,
          address: props?.data?.company_info_Address,
          country: findcurrencies?.value,
        },
        customer: {},
        discount_type: props?.footerdata.Discount === 0 ? null : props?.footerdata?.Discount_type,
        discount_value: props?.footerdata?.Discount_value,
        memo: props?.footerdata?.textrea,
        sub_total: props?.footerdata?.totel,
        tax_percentage: props?.footerdata?.Tax,
        tax: props?.totalTax ? props?.totalTax : props?.productTotalTax,
        discount:
          props?.footerdata.Discount_type == "Percentage"
            ? (Number(props?.footerdata.totel) * Number(props?.footerdata.Discount)) / 100
            : props?.footerdata.Discount,
        total: props?.footerdata?.pagetotel,
        line_items: undefined,
        existing_products: undefined,
      };
      var ItemBodyset: any = []
      var ItemEdit: any = []
      if (!!props?.idPage) {
        body.line_items = ItemEdit;
      } else {
        body.line_items = ItemBodyset;
      }
      for (let index = 0; index < props.bill_items.length; index++) {
        const element = props.bill_items[index];


        var itemBody = {
          name: element.Item,
          quantity: element.Quantity,
          price: element.Price,
          total: element.total,
          description: element.description,
          tax: element.tax,
          calculatedTax: element.calculatedTax,
          monite_product_id: element.monite_product_id,
        }
        var EditPayload = {
          "name": element.Item,
          "quantity": Number(element.Quantity),
          "tax": element.tax,
          "price": Number(element.Price),
          description: element.description,

        }
        ItemEdit.push(EditPayload)
        ItemBodyset.push(itemBody)
      }

      if (props?.ReceiverHide == true) {
        if (props?.data?.Type == "individual") {
          var obj = {
            "type": "individual",
            "first_name": props?.data?.Receiver_info_First_name,
            "last_name": props?.data?.Receiver_info_Last_name,
            "email": props?.data?.Receiver_info_Email,
            "phone": props?.data?.Receiver_info_Mobile,
            "country": findcurrenciescustomer?.value,
            "city": "nairobi",
            "postal_code": "11225",
            "state": null,
            "line1": props?.data?.Receiver_info_Address,
            tax_identification_number:props?.TaxIdentificationNumber
          }
          body.customer = obj
        } else {
          var objorganization = {
            "type": "organization",
            "legal_name": props?.data?.Receiver_info_First_name,
            "email": props?.data?.Receiver_info_Email,
            "phone": props?.data?.Receiver_info_Mobile,
            "country": findcurrenciescustomer?.value,
            "city": "nairobi",
            "postal_code": "11225",
            "state": null,
            "line1": props?.data?.Receiver_info_Address,
            title: props?.data?.Receiver_info_First_name,
            tax_identification_number:props?.TaxIdentificationNumber
          }
          body.customer = objorganization
        }
      } else {
        body.customer = props?.selectReceiver
      }

      if (!!props?.idPage&&!props?.apicallData) {
        setLoader(true)

        
        dispatch(paymentJourneyActions.editInvoice(body, props?.idPage, ButtonType, setLoader) as any);
      } else {
        setLoader(true)
       
        dispatch(paymentJourneyActions.cereteInvoice(body, ButtonType, setLoader) as any);
        const event = 'Invoice Shared with Owner';
        amplitude.getInstance().logEvent(event, eventProperties);
        posthog.capture(event, eventProperties);
      }

    } else {
      props.onCancel();
    }
  };


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          onClose={handleClose}
        >
          <div
            style={{ border: '0px solid', marginTop: '0px' }}
            className={styles.div_pirwushdata}
          >
            <div className={styles.main_div_class}>
              <p className={styles.name_heding}>{props.newInvoiceName ? props.newInvoiceName : "INVOICE"}</p>

              {!!props && !!props.invoiceImage ? (
                <div style={{ width: '100px', height: '70px' }}>
                  <img
                    width={'100%'}
                    height={'100%'}
                    src={props?.invoiceImage}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.contenar_secasan}>
              <div className={styles.fast_divdass}>
                <p className={styles.hedingpaylod_popup}>
                  Invoice #{props?.info.Invoice}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  Issued {moment(props?.info.Date_issued).format('DD-MMM-YYYY')}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  Due {moment(props?.info.Due_date).format('DD-MMM-YYYY')}
                </p>
              </div>
              <div className={styles.classname_two_div}>
                <p className={styles.ptages_hedingbill}>Bill from:</p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.company_info_Name}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.company_info_Title}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.company_info_Mobile}
                </p>

                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.company_info_Address}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.company_info_Country}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.company_info_Email}
                </p>
              </div>
              <div className={styles.classname_two_div}>
                <p className={styles.ptages_hedingbill}>Bill to:</p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_First_name}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Last_name}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Name}
                </p>
                {/* <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Title}
                </p> */}
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Mobile}
                </p>

                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Address}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Country}
                </p>
                <p className={styles.hedingpaylod_popup}>
                  {props?.data?.Receiver_info_Email}
                </p>
                <p className={styles.hedingpaylod_popup}>
                {props?.TaxIdentificationNumber}
                </p>
              </div>
            </div>
            <div
              style={{ height: '266px' }}
              className={styles.grid_contenar_item}
            >
              <ThemeProvider theme={theme}>
                <Grid container spacing={1}>
                  <Grid item md={4} xs={4} sm={4}>
                    <Typography className={styles.item_typography_popup}>
                      Item
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={2}
                    sm={2}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Typography className={styles.item_typography_popup}>
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={2}
                    sm={2}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Typography className={styles.item_typography_popup}>
                      Unit Cost
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={2}
                    sm={2}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Typography className={styles.item_typography_popup}>
                      VAT %
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={2}
                    sm={2}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Typography className={styles.item_typography_popup}>
                      Line Total
                    </Typography>
                  </Grid>


                </Grid>
                <div className={styles.div_buttomborder}></div>
                <div>
                  {props.bill_items.map((x: any, i: any) => {
                    return (
                      <>
                        <Grid
                          container
                          spacing={1}
                          className={styles.loop_contenardata}
                        >
                          <Grid item md={4} sm={4} xs={4}>
                            <Typography
                              className={styles.item_typography_popup}
                            >
                              {x.Item}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={2}
                            sm={2}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            <Typography
                              className={styles.item_typography_popup}
                            >
                              {x.Quantity}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={2}
                            sm={2}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            <Typography
                              className={styles.item_typography_popup}
                            >
                              {(x.Price).toLocaleString(
                                'en',
                                { minimumFractionDigits: 2 }
                              )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            md={2}
                            xs={2}
                            sm={2}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            <Typography
                              className={styles.item_typography_popup}
                            >
                              {`${x.tax}${x.tax=='Exempt'?"":'%'}`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={2}
                            sm={2}
                            display={'flex'}
                            justifyContent={'center'}
                          >
                            <Typography
                              className={styles.item_typography_popup}
                            >
                              {(x.total).toLocaleString(
                                'en',
                                { minimumFractionDigits: 2 }
                              )}
                            </Typography>
                          </Grid>

                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              className={styles.item_typography_popup}
                            >
                              {x.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </div>
              </ThemeProvider>
            </div>
            <div>
              <Grid container spacing={1} className={styles.Gridcontainer}>
                <Grid item md={6} sm={8} xs={6}>
                  <Typography className={styles.Typography_heding}>
                    Payment Details
                  </Typography>
                  <Typography className={styles.item_typography_popup}>
                    {props?.footerdata?.textrea}
                  </Typography>
                </Grid>
                <Grid item md={3} sm={2} xs={3}>
                  <Typography className={styles.item_typography_popup}>
                    Subtotal
                  </Typography>
                  <Typography className={styles.item_typography_popup}>
                    Tax
                  </Typography>
                  <Typography className={styles.item_typography_popup}>
                    Discount
                  </Typography>
                  <Typography className={styles.item_typography_popup}>
                    Total
                  </Typography>
                </Grid>
                <Grid item md={3} sm={2} xs={2}>
                  <Typography
                    style={{ textAlign: 'end' }}
                    className={styles.item_typography_popup}
                  >
                    {props?.info?.Currency}
                    {(props?.footerdata?.totel).toLocaleString(
                      'en',
                      { minimumFractionDigits: 2 }
                    )}
                  </Typography>
                  <Typography
                    style={{ textAlign: 'end' }}
                    className={styles.item_typography_popup}
                  >
                    {props?.info?.Currency}
                    {(props.totalTax).toLocaleString(
                      'en',
                      { minimumFractionDigits: 2 }
                    )}

                    {/* {((Number(props?.footerdata?.totel) - Number(props?.footerdata?.Discount_value)) - ((Number(props?.footerdata?.totel) - Number(props?.footerdata?.Discount_value)) / (1 + Number(props?.footerdata?.Tax) / 100))).toLocaleString(
                      'en',
                      { minimumFractionDigits: 2 }
                    )} */}
                  </Typography>
                  <Typography
                    style={{ textAlign: 'end' }}
                    className={styles.item_typography_popup}
                  >
                    {props?.info?.Currency}
                    {props?.footerdata.Discount_type == 'Percentage'
                      ? (Number(props?.footerdata.totel) *
                        Number(props?.footerdata.Discount)) /
                      100
                      : props?.footerdata.Discount}
                  </Typography>
                  <Typography
                    style={{ textAlign: 'end' }}
                    className={styles.item_typography_popup}
                  >
                    {props?.info?.Currency}
                    {(props?.footerdata?.pagetotel)?.toLocaleString(
                      'en',
                      { minimumFractionDigits: 2 }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={styles.footer_logotypo}>
              <Typography>Powered by</Typography>
              <img width={57} src="./logo 3.svg" />
            </div>
          </div>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={openShare}
          onClose={handleCloseShare}
        >
          <div className={styles.contenar_divdialog}>
            <div className={styles.Share_Invoice}>
              <Typography>
                Share Invoice{' '}
                {ShareButton == 'me' ? 'with Me' : 'with Receiver'}
              </Typography>
              <IconButton
                onClick={() => {
                  handleCloseShare();
                }}
              >
                <Close />
              </IconButton>
            </div>
            <div className={styles.div_buttomborder}></div>
            <Typography className={styles.text_Typography}>
              Share invoice via
            </Typography>
            <div className={styles.img_and_inputcls}>
              <div id={styles.div_classname} className="input-form-wrapper">
                <label>
                  {ShareButton == 'me' ? 'My email' : "Receiver's email"}
                </label>
                <InputGroup className="doc-input-wrapper">
                  <Form.Control
                    disabled
                    className={'doc-form-input test valid'}
                    placeholder="Invoice name"
                    value={
                      ShareButton == 'me' ?
                        props?.data?.company_info_Email :
                        props?.data?.Receiver_info_Email
                    }
                    name="name"
                    type="text"
                  />
                </InputGroup>
              </div>
            </div>
            <div>
              <Button
                className={styles.two_button_invoice_data}
              >
                Share
              </Button>
            </div>
          </div>
        </Dialog>

        <Modal
          show={showModal}
          onHide={onClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup_modal"
          centered
          size="lg"
        >
          <>
            {datainline == 'Filea' ? (
              <div className="niobi-payment-error">
                <div className="niobi-balance-detail__heading color-red niobi-payment-error__text-red" style={{ color: '#025041' }}>
                  {message}
                  <p style={{ fontSize: "20px", marginTop: "30px" }}>{messageBody}</p>
                </div>

                <div className="pay-initiated-container__btn-row-two">
                  <Button
                    className="pay-initiated-container__btn-row__green"
                    style={{ marginLeft: "30%" }}
                    onClick={() => handleCloseShare()}
                  >
                    Return to Account
                  </Button>
                </div>
              </div>
            ) : datainline == 'success' ? (
              <div
                style={{
                  borderRadius: '5px',
                  background: '#FFF',
                  width: '100%',
                  height: '35vh',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div
                    style={{ textAlign: 'center', padding: '0px 0px 15px 0px' }}
                  >
                    <svg
                      width="57"
                      height="56"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z"
                        fill="#025041"
                      />
                    </svg>
                  </div>
                  <div className="heding_tesyssjsj"> {message}
                    {messageBody}</div>
                </div>
              </div>
            ) : (
              ''
            )}
          </>{' '}
        </Modal>
      </ThemeProvider>
      <div className={styles.div_pirwushdata}>
        <div className={styles.main_div_class}>
          <p className={styles.name_heding}>{props.newInvoiceName ? props.newInvoiceName : "INVOICE"}</p>

          <div style={{ width: '100px', height: '70px' }}>
            {!!props && !!props.invoiceImage ? (
              <div style={{ width: '100px', height: '70px' }}>
                <img
                  width={'50%'}
                  height={'50%'}
                  src={props?.invoiceImage}
                  style={{ marginTop: '20px' }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={styles.contenar_secasan}>
          <div className={styles.fast_divdass}>
            <p className={styles.hedingpaylod}>
              Invoice #{props?.info.Invoice}
            </p>
            <p className={styles.hedingpaylod}>
              Issued {moment(props?.info.Date_issued).format('DD-MMM-YYYY')}
            </p>
            <p className={styles.hedingpaylod}>
              Due {moment(props?.info.Due_date).format('DD-MMM-YYYY')}
            </p>
          </div>
          <div className={styles.classname_two_div}>
            <p className={styles.ptages_hedingbill}>Bill from:</p>
            <p className={styles.hedingpaylod}>
              {props?.data?.company_info_Name}
            </p>

            <p className={styles.hedingpaylod}>
              {props?.data?.company_info_Title}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.company_info_Mobile}
            </p>

            <p className={styles.hedingpaylod}>
              {props?.data?.company_info_Address}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.company_info_Country}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.company_info_Email}
            </p>
          </div>
          <div className={styles.classname_two_div}>
            <p className={styles.ptages_hedingbill}>Bill to:</p>
            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_First_name}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Last_name}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Name}
            </p>
            {/* <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Title}
            </p> */}
            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Mobile}
            </p>

            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Address}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Country}
            </p>
            <p className={styles.hedingpaylod}>
              {props?.data?.Receiver_info_Email}
            </p>
            <p className={styles.hedingpaylod}>
                {props?.TaxIdentificationNumber}
                </p>
          </div>
        </div>

        <div className={styles.grid_contenar_item}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={1}>
              <Grid item md={4} xs={4}>
                <Typography className={styles.item_typography}>Item</Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <Typography className={styles.item_typography}>
                  Quantity
                </Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <Typography className={styles.item_typography}>
                  Unit Cost
                </Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <Typography className={styles.item_typography}>
                  VAT%
                </Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <Typography className={styles.item_typography}>
                  Line Total
                </Typography>
              </Grid>

            </Grid>
            <div className={styles.div_buttomborder}></div>
            <div>
              {props.bill_items.map((x: any, i: any) => {
                return (
                  <>
                    <Grid
                      container
                      spacing={1}
                      className={styles.loop_contenardata}
                    >
                      <Grid item md={4} xs={4}>
                        <Typography className={styles.item_typography}>
                          {x.Item}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography className={styles.item_typography}>
                          {x.Quantity}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography className={styles.item_typography}>
                          {(x.Price).toLocaleString(
                            'en',
                            { minimumFractionDigits: 2 }
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography className={styles.item_typography}>
                          {`${x.tax}${x.tax=='Exempt'?"":'%'}`}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography className={styles.item_typography}>
                          {(x.total).toLocaleString(
                            'en',
                            { minimumFractionDigits: 2 }
                          )}
                        </Typography>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography className={styles.item_typography}>
                          {x.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </div>
          </ThemeProvider>
        </div>

        <div>
          <Grid container spacing={1} className={styles.Gridcontainer}>
            <Grid item md={6} xs={8}>
              <Typography className={styles.Typography_heding}>
                Payment Details
              </Typography>
              <Typography
                style={{ padding: '6px 30px 6px 0px' }}
                className={styles.item_typography}
              >
                {props?.footerdata?.textrea}
              </Typography>
            </Grid>
            <Grid item md={3} xs={2}>
              <Typography className={styles.item_typography}>
                Subtotal
              </Typography>
              <Typography className={styles.item_typography}>
                Tax
              </Typography>
              <Typography className={styles.item_typography}>
                Discount
              </Typography>
              <Typography className={styles.item_typography}>Total</Typography>
            </Grid>
            <Grid item md={3} xs={2}>
              <Typography
                style={{ textAlign: 'end' }}
                className={styles.item_typography}
              >
                {props?.info?.Currency}
                {(props?.footerdata?.totel).toLocaleString(
                  'en',
                  { minimumFractionDigits: 2 }
                )}
                {/* {props?.footerdata?.totel} */}
              </Typography>
              <Typography
                style={{ textAlign: 'end' }}
                className={styles.item_typography}
              >
                {props?.info?.Currency}
                {(props.totalTax).toLocaleString(
                  'en',
                  { minimumFractionDigits: 2 }
                )}
                {/* {((Number(props?.footerdata?.totel) - Number(props?.footerdata?.Discount_value)) - ((Number(props?.footerdata?.totel) - Number(props?.footerdata?.Discount_value)) / (1 + Number(props?.footerdata?.Tax) / 100))).toLocaleString('en', { minimumFractionDigits: 2 })} */}
              </Typography>
              <Typography
                style={{ textAlign: 'end' }}
                className={styles.item_typography}
              >
                {props?.info?.Currency}
                {props?.footerdata.Discount_type == 'Percentage'
                  ? (Number(props?.footerdata.totel) *
                    Number(props?.footerdata.Discount)) /
                  100
                  : props?.footerdata.Discount}
              </Typography>
              <Typography
                style={{ textAlign: 'end' }}
                className={styles.item_typography}
              >
                {props?.info?.Currency}
                {(props?.footerdata?.pagetotel).toLocaleString(
                  'en',
                  { minimumFractionDigits: 2 }
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={styles.footer_logotypo}>
          <Typography>Powered by</Typography>
          <img width={57} src="./logo 3.svg" />
        </div>
      </div>
      <div>
        <Button onClick={handleClickOpen} className={styles.preview_button}>
          <Preview />
          <Typography>Preview</Typography>
        </Button>
        <div className={styles.Share_Invoicecontean}>
          <Button
            style={{ height: "49.5px", padding: "16px 0px" }}
            className={styles.fast_button_data}
            onClick={() => {
              Shareapi("Save");
            }}
          >
            {loader == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
            {!!props?.idPage&&!props?.apicallData ? "Update" : "Create"}
          </Button>
          <Button
            className={styles.two_button_invoice}
            // style={{height:"49.5px",padding:"16px 0px"}}
            id="dropdown-button"
            aria-controls={openMenu ? 'dropdown-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
          >
            {loader == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
            Create & Send
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'dropdown-button',
            }}
          >
            <MenuItem
              className={styles.Menuitemclass}
              style={{ fontSize: "13px" }}
              onClick={() => {
                Shareapi("Send_via_Email");
              }}>
              Send via Email</MenuItem>
            <MenuItem
              className={styles.Menuitemclass}
              onClick={() => {
                Shareapi("Download_PDF");
              }} style={{ fontSize: "13px" }}>

              Download PDF

            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
};
export default Preview_fils;

const Preview = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 1.375C9.65482 1.375 9.375 1.65482 9.375 2C9.375 2.34518 9.65482 2.625 10 2.625H12.4911L8.89139 6.22472C8.64731 6.4688 8.64731 6.86453 8.89139 7.10861C9.13547 7.35269 9.5312 7.35269 9.77527 7.10861L13.375 3.50888V6C13.375 6.34518 13.6548 6.625 14 6.625C14.3452 6.625 14.625 6.34518 14.625 6V2C14.625 1.65482 14.3452 1.375 14 1.375H10ZM2.625 10C2.625 9.65482 2.34518 9.375 2 9.375C1.65482 9.375 1.375 9.65482 1.375 10V14C1.375 14.3452 1.65482 14.625 2 14.625H6C6.34518 14.625 6.625 14.3452 6.625 14C6.625 13.6548 6.34518 13.375 6 13.375H3.50888L7.10861 9.77527C7.35269 9.5312 7.35269 9.13547 7.10861 8.89139C6.86453 8.64731 6.4688 8.64731 6.22472 8.89139L2.625 12.4911V10Z"
      fill="#1D1D1D"
    />
  </svg>
);
const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 18 18"
    fill="none"
  >
    <circle cx="9" cy="9" r="8.5" stroke="#EAEDED" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.18611 5.19427C6.30532 5.07497 6.46697 5.00796 6.63553 5.00796C6.80409 5.00796 6.96574 5.07497 7.08495 5.19427L9.49603 7.608L11.9071 5.19427C11.9657 5.1335 12.0359 5.08502 12.1134 5.05166C12.191 5.01831 12.2744 5.00076 12.3588 5.00002C12.4432 4.99929 12.5269 5.01539 12.605 5.04739C12.6832 5.07938 12.7541 5.12664 12.8138 5.18639C12.8735 5.24614 12.9207 5.31719 12.9527 5.39539C12.9846 5.4736 13.0007 5.5574 13 5.64189C12.9992 5.72639 12.9817 5.80989 12.9484 5.88753C12.9151 5.96517 12.8667 6.03539 12.8059 6.09409L10.3949 8.50782L12.8059 10.9215C12.9217 11.0416 12.9858 11.2023 12.9844 11.3692C12.9829 11.536 12.9161 11.6956 12.7982 11.8136C12.6803 11.9316 12.5209 11.9985 12.3542 12C12.1876 12.0014 12.027 11.9373 11.9071 11.8214L9.49603 9.40764L7.08495 11.8214C6.96506 11.9373 6.80449 12.0014 6.63782 12C6.47115 11.9985 6.31172 11.9316 6.19386 11.8136C6.076 11.6956 6.00915 11.536 6.0077 11.3692C6.00625 11.2023 6.07032 11.0416 6.18611 10.9215L8.5972 8.50782L6.18611 6.09409C6.06695 5.97476 6 5.81292 6 5.64418C6 5.47544 6.06695 5.31361 6.18611 5.19427Z"
      fill="#1D1D1D"
    />
  </svg>
);
