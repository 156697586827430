import { Box, TextField, Typography } from "@mui/material"
import { LightBtn, DefaultBtn, TextBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons"
import "./step4.scss"
import { CustomInput } from "../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import { useState } from "react"
import * as Yup from 'yup';
import { Formik } from "formik";
import { Form } from 'react-bootstrap';


const Step4 = ({ stepupdatefun,ExpressPayment }: any) => {

  const [filedValue, setFiledValue] = useState({
    Otp: ""
  })
  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .length(6, 'OTP must be exactly 6 digits')
      .required('OTP is required'),
  });

  return (
    <Formik

      initialValues={filedValue}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        ExpressPayment(values.Otp)
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} >
          <Box className="step4_container d-flex flex-column justify-content-center">

            <Box className="otp_box d-flex flex-column justify-content-center gap-4">
              <Box className="d-flex flex-column justify-content-center align-items-center gap-3">
                <Typography className="authorize_heading">Authorise Transaction</Typography>
                <Typography className="authorize_sub_heading">To authorise transaction enter security code that
                  was send via SMS to phone number +49 211 *****62</Typography>
              </Box>
              <Box>
                <label className="otp_label">Security code</label>
                <CustomInput className="otp_input"
                  placeholder="Enter security code"
                  value={formik.values.Otp}
                  onChange={(e: any) => formik.setFieldValue('Otp', e.target.value)}
                />
              </Box>
              <Box className="btn_wrapper">
                <TextBtn onClick={() => {
                  stepupdatefun(2)
                }} text="Cancel" style={{ border: "none", width: "35%" }} />
                <DefaultBtn onClick={() => {
                  stepupdatefun(3)
                }} text="Confirm" style={{ width: "55%" }} />
              </Box>
            </Box>

          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default Step4