import React from 'react'
import { Box } from '@mui/material'
import Step1 from './Components/Step1/Step1'
import Step2 from './Components/Step2/Step2'
import Step4 from './Components/Step4/Step4'

const BulkPayment = () => {
  return (
    <Box>
       {/* <Step1/> */}
       {/* <Step2/> */}
       <Step4/>
    </Box>
  )
}

export default BulkPayment