import "../components/topup-modal/Topup.scss";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { getUser } from "../../../../../_helpers";
import { Button } from "react-bootstrap";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import styles from "./Choose_currency/ChooseCurrency.module.scss";
import Dashboard_nodata from '../../../../../features/app/Layout/Dashboard/components/dashboard_nodata/dashboard_nodata';




const Select_source_account = (props: any) => {
  const [showModal, setShow] = useState(props.show);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selcetCurrency, setSelectCurrency] = useState("");
  const [countryList, setCountrtList] = useState([]);

  const user = getUser()?.data ?? null;
  useEffect(() => {
    if (props?.countryList) {
      var array: any = []
      for (let index = 0; index < props?.countryList.length; index++) {
        const element = props?.countryList[index];
        // if (element.currency == "KES" || element.currency == "USD") {
        array.push(element)
        // }
      }
      setCountrtList(array)
    }
  }, [props?.countryList])
  const onClose = () => {
    setShow(false);
    props.onClose(false);
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  
  interface CurrencyMethodItem {
    isBalance: boolean;
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
        <ThemeProvider theme={theme}>
          <Box className="CloseContenar">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>


          {
            props?.currencyAndMethodData?.length > 0 &&
            props.currencyAndMethodData.some((item: CurrencyMethodItem) => item.isBalance) && (
              <Box className="heding_contean">
                <Typography>Select source account </Typography>

              </Box>
            )
          }
        </ThemeProvider>
        <Modal.Body>
          <Grid container className={styles.grid_currency_box}>
            {props?.currencyAndMethodData?.length > 0 ? (
              props?.currencyAndMethodData?.filter((element: any) => element.isBalance).length > 0 ? (
                props?.currencyAndMethodData?.filter((element: any) => element.isBalance).map((element: any, index: number) => {
                  return (
                    <Grid
                      key={index}
                      item
                      md={6}
                      xs={12}
                      sm={12}
                      className={styles.grid_button_contean}
                    >
                      <Button
                        style={
                          selcetCurrency == element.currency
                            ? { background: "#fff", border: "1px solid #025041" }
                            : { background: "#F2F8F7" }
                        }
                        onClick={() => {
                          props.onSave(element.currency);
                          setSelectedMethod(element.currency);
                          // props.setCurrancylist(element.currency)
                          // props.onSavecurrancy(element.currency)
                          // navigate(`/balance?currency=${element.currency}`);
                          // localStorage.setItem("currency",element.currency)
                        }}
                        className={styles.box_currency}
                      >
                        <div className={styles.currency_img}>
                          <img src={element.flag} alt={`${element.currency} flag`} />
                        </div>
                        <div className={styles.main_divtext}>
                          <Typography className={styles.currency_text}>
                            {element.currency}
                          </Typography>
                          <Typography className={styles.country_text}>
                            {element.name}
                          </Typography>
                          <Typography className={styles.Typography_clsamount}>
                            {element.currency}{" "}
                            {!!element && element?.balance?.total_balance
                              ? (element?.balance?.total_balance / 100).toLocaleString("en", {
                                minimumFractionDigits: 2,
                              })
                              : "0.00"}
                          </Typography>
                        </div>
                      </Button>
                    </Grid>
                  );
                })
              ) : (
                <div
                  style={{ marginLeft: "20px" }}
                >
                  <Dashboard_nodata button="" Link={``} img="/uil_transaction.svg" text="" />
                  <Typography>
                    Insufficient balance in the currency accounts. Please top-up to proceed.
                  </Typography>
                </div>
              )
            ) : (
              <div
                style={{ marginLeft: "20px" }}
              >
                <Dashboard_nodata button="" Link={``} img="/uil_transaction.svg" text="" />
                <Typography>
                  Insufficient balance in the currency accounts. Please top-up to proceed.
                </Typography>
              </div>
            )}
          </Grid>
        </Modal.Body>

      </Modal>
    </>
  );
};
export default Select_source_account;
interface CountryType {
  label: string;
  img: string;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly CountryType[] = [
  { label: "KES", img: "/kenya-flag.png" },
  {
    label: "USD",
    img: "/kenya-flag.png",
  },
];
