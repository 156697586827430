import { Box } from '@mui/material'
import "./model.scss"

const Model = ({children} : any) => {
  return (
     <Box className="model_container d-flex flex-column justify-content-center">
        {children}
     </Box>
  )
}

export default Model
