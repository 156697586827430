import './makepayment.scss'
import { DefaultBtn, LightBtn } from '../../../ui/ReuseableComponents/Buttons/Buttons'
import { DefaultGoBackBtn } from '../../../ui/ReuseableComponents/Buttons/Buttons'
import { Box, createTheme, Grid, ThemeProvider, Typography, TextField, SelectChangeEvent } from '@mui/material'
import { orange } from '@mui/material/colors'
import MakePaymentLayout from '../../../ui/ReuseableComponents/Layouts/MakePaymentLayout/MakePaymentLayout'
import { SearchInput, CustomInput } from '../../../ui/ReuseableComponents/Inputs/Inputs'
import { VerticalStepNav } from '../../../ui/ReuseableComponents/Navigations/Navigations'
import { CurrencySelectBox, DynamicSelectComponent } from '../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import { InputSelectBox } from '../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import React, { useEffect, useState } from 'react'
import FullWidthTabs from './Components'
import MakePaymentHeader from './Components/MakePaymentHeader'
import AddANewPayee from '../../../ui/ReuseableComponents/Payee/PayeeComponents'
import { payablesFlowactions } from '../../../../_actions'
import { useDispatch } from 'react-redux'



//////Just for Dummy Payee Data
const users = [
  {
    name: 'Payee 1',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 2',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 3',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 4',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 5',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 7',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 8',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 9',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },
  {
    name: 'Payee 10',
    currencyLabelImage: 'https://www.example.com/coin.png',
  },

];


const MakePayment = () => {
  const [Step, setStep] = React.useState(0)
  const [isAddPayeeComponent, setIsAddPayeeComponent] = useState(false);
  const [render, setRender] = useState(true)
  const [currency, setCurrency] = useState([])
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const [SelectCountry_id, setSelectCountry_id] = useState(1)
  const [SelectBlence, setSelectBlence] = useState(0)
  const [SelectFleg, setSelectFleg] = useState('https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Kenya-Flag.png')
  const dispatch = useDispatch()

  console.log(SelectCurrency, "SelectCurrency");

  const handleClick = () => {
    return null;
  }
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const stepupdatefun = (setp: any) => {
    setStep(setp)
  }
  const CurrancyList = (data: any) => {
    // setCurrency(data)
    var FindFleg:any = data.find((item: any) => item.currency == SelectCurrency)
    setSelectBlence(FindFleg?.balance?.available_balance)

    var NewArray: any = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if (element.currency == "GHS") {

      }
      else {
        NewArray.push(element)
      }
    }
    setCurrency(NewArray)
  }
  useEffect(() => {
    if (render) {

      dispatch(payablesFlowactions.getApipayment("/get-all-currencies-details-by-user", CurrancyList) as any);
    }
  }, [render])
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* //header */}
        <MakePaymentHeader />
        {/* //body */}
        <Box className='makepayment_body pt-5 mb-5'>
          <Box className='auto_container'>
            <MakePaymentLayout stepupdatefun={stepupdatefun} Step={Step} button={<DefaultGoBackBtn style={{ marginBottom: "25px" }} onClick={handleClick}/>}>
              {Step == 1 || Step == 2 || Step == 3 || isAddPayeeComponent == true ? "" :
                <Box className='payee_header d-flex justify-content-between align-items-center mb-3'>
                  <Typography variant='h5' className='payee_header_text'>Payee</Typography>
                  <CurrencySelectBox countryList={currency}
                    value={SelectCurrency}
                    onChange={(e: any) => {
                      console.log(e.target.value, "asfsafasffasf");
                      var newObject = []
                      // for (let index = 0; index < currency.length; index++) {
                      //   const element:any = currency[index];
                      //   if(e.target.value==element.currency){
                      //     newObject.push(element)
                      //   }
                      // }
                      var FindFleg:any = currency.find((item: any) => item.currency == e.target.value)
                      setSelectFleg(FindFleg.flag)
                      console.log(FindFleg?.balance?.available_balance,"FindFleg?.balance?.available_balance");
                      
                      setSelectBlence(FindFleg?.balance?.available_balance)
                      // setSelectCountry_id()
                      setSelectCurrency(e.target.value)
                    }} />
                </Box>}
              <Box>
                <FullWidthTabs SelectBlence={SelectBlence} SelectCurrency={SelectCurrency} SelectFleg={SelectFleg} isAddPayeeComponent={isAddPayeeComponent} setIsAddPayeeComponent={setIsAddPayeeComponent} stepupdatefun={stepupdatefun} Step={Step} />
              </Box>
            </MakePaymentLayout>
          </Box>
        </Box>
      </ThemeProvider>

    </>
  )
}

export default MakePayment