import React, { useState } from 'react'
import { SearchInput } from '../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import { Box, Typography } from '@mui/material'
import AddANewPayee from '../../../../../../../ui/ReuseableComponents/Payee/PayeeComponents'
import { PayeeList, DetPayeeList } from '../../../../../../../ui/ReuseableComponents/PayeeLists/Payee_List'
import "./step1.scss"
import Step1AddNewPayeeComponent from './Components/AddNewPayee'
import { SkeletonSelectBox } from '../../../../../Skeleton/Skeleton'
import { useDispatch } from 'react-redux'
import { payablesFlowactions } from '../../../../../../../../_actions'
////Dummy Data for Payees
const payees = [
  {
    id: 1,
    name: 'Payee 1',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 2,
    name: 'Payee 2',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 3,
    name: 'Payee 3',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 4,
    name: 'Payee 4',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 5,
    name: 'Payee 5',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 6,
    name: 'Payee 7',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 7,
    name: 'Payee 8',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 8,
    name: 'Payee 9',
    currencyLabelImage: 'https://picsum.photos/200',
  },
  {
    id: 9,
    name: 'Payee 10',
    currencyLabelImage: 'https://picsum.photos/200',
  },
];


const Step1 = ({ formik, stepupdatefun, setIsAddPayeeComponent, isAddPayeeComponent, getPayees, SelectFleg, setSearchPayee, searchPayee, loader, setSelectedUser, bankData }: any) => {
  const [activeButton, setActiveButton] = useState("All");
  const dispatch = useDispatch()

  const handleClickButton = (buttonName: string) => {
    setActiveButton(buttonName);

  }
  console.log(formik,"formikformik");
  
  const handlePayeeClick = (payee: any) => {
   
    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payee/${payee.monite_counterpart_id}/${payee.payee_account.currency}/1`, (data: any) => {
      if (data) {
        setSelectedUser(data);
        // data.counterpart=data
        // formik.setFieldValue('counterpart', data)

        console.log(data, "according to monite id");
        setTimeout(() => {
          stepupdatefun(1)
        }, 2000)
      }
    }) as any);
  }
  const UpdateSeup = (payee: any) => {
    // formik.setFieldValue('counterpart', payee)

    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payee/${payee.monite_counterpart_id}/${formik.values.currency}/1`, (data: any) => {
      if (data) {
        setSelectedUser(data);
        // data.counterpart=data
       
        console.log(data, "according to monite id");
        setTimeout(() => {
          stepupdatefun(1)
        }, 2000)
      }
    }) as any);
  }

  return (
    <Box>
      {
        isAddPayeeComponent === false ? <>
          <SearchInput onChange={(e: any) => {
            console.log(e.target.value, "eeeeeeee");

            setSearchPayee(e.target.value)
          }} velue={searchPayee} placeholder="Search by name" />
          <Box style={{ borderBottom: "1px solid #e0e0e0" }}>
            <AddANewPayee onClick={() => setIsAddPayeeComponent(true)} style={{ marginTop: "20px" }} />
          </Box>

          <Box className="recent_payee_container">
            <Typography className="recent_payee_heading">Recent</Typography>
            <SkeletonSelectBox
              width={"100%"}
              height={'100px'}
              loader={loader}
              style={{ textAlign: "center", margin: '10px 0px' }}
              element={
                <>
                  <PayeeList
                    SelectFleg={SelectFleg}
                    payees={getPayees?.recent_payees}
                    onClick={(payee: any) => handlePayeeClick(payee)}
                  />
                </>
              }></SkeletonSelectBox>
          </Box>

          <Box>
            <Box className="d-flex gap-3 mt-1 mb-1">
              <button
                onClick={() => handleClickButton("All")}
                className={`list_all_payee_btn ${activeButton === "All" ? "active" : ""}`}
              >
                All
              </button>
              <button
                onClick={() => handleClickButton("Frequent")}
                className={`list_frequent_payee_btn ${activeButton === "Frequent" ? "active" : ""}`}
              >
                Frequent
              </button>
            </Box>
            <SkeletonSelectBox
              width={"100%"}
              height={'350px'}
              loader={loader}
              style={{ textAlign: "center", margin: '10px 0px' }}
              element={
                <>
                  <DetPayeeList SelectFleg={SelectFleg} onClick={(payee: any) => UpdateSeup(payee)} payees={activeButton == "All" ? getPayees?.all_payees : getPayees?.frequent_payees} />
                </>}></SkeletonSelectBox>
          </Box>
        </> : <Box>
          <Step1AddNewPayeeComponent bankData={bankData} isAddPayeeComponent={isAddPayeeComponent} setIsAddPayeeComponent={setIsAddPayeeComponent} />
        </Box>

      }
    </Box>
  )
}

export default Step1