import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from "./navigations.module.scss";


export const VerticalStepNav= ({  steps, activeStepIndex, onStepChange} : any) => {
    const handleStepClick = (stepIndex: number) => {
        if (onStepChange) {
            onStepChange(stepIndex);
        }
    };

    return (
        <Box className={styles.verticalStepNavContainer}>
            {steps.map((step : any, index : any) => (
                <Box 
                    key={index} 
                    className={`
                        ${styles.stepItem} 
                        ${index === activeStepIndex ? styles.active : ''} 
                        ${index < activeStepIndex ? styles.completed : ''}
                    `}
                    // onClick={() => handleStepClick(index)}
                >
                    {/* Vertical Line */}
                    {index > 0 && (
                        <Box 
                            className={`
                                ${styles.connectingLine} 
                                ${index <= activeStepIndex ? styles.lineCompleted : ''}
                            `}
                        />
                    )}

                    {/* Step Circle */}
                    <Box 
                        className={`
                            ${styles.stepCircle} 
                            ${index <= activeStepIndex ? styles.circleCompleted : ''}
                        `}
                    >
                    </Box>

                    {/* Step Content */}
                    <Box className={styles.stepContent}>
                        <Typography 
                            className={`
                                ${styles.stepLabel} 
                                ${index <= activeStepIndex ? styles.labelCompleted : ''}
                            `}
                        >
                            {step.label}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};