import { Grid, Box } from '@mui/material'
import "../MakePaymentLayout/makepaymentlayout.scss"
import { VerticalStepNav } from '../../Navigations/Navigations'
import { useEffect, useState } from 'react';

////Steps for VerticleStepNav
const steps = [

    { label: 'Choose payee' },
    { label: 'Amount, schedule/pay now' },
    { label: 'Pay' },
    { label: 'OTP' }

];

const MakePaymentLayout = ({ children, button, Step,stepupdatefun }: any) => {
    const [currentStep, setCurrentStep] = useState(0);
    useEffect(() => {
        if (!!Step) {
            setCurrentStep(Step)
        }
    }, [Step])
    return (
        <>
            <Grid container>
                <Grid item md={2.5}>
                    {button}
                    <VerticalStepNav
                        steps={steps}
                        activeStepIndex={currentStep}
                        onStepChange={setCurrentStep}
                    />
                </Grid>
                <Grid item md={7}>
                    <Box className="makepayment_frame">
                        {children}
                    </Box>
                </Grid>
                <Grid item md={2.5}>
                </Grid>
            </Grid>
        </>
    )
}

export default MakePaymentLayout