import { Grid, IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import React from 'react';
import { TebalNoData } from '../../../Skeleton/TebalNoData';
import { useNavigate } from 'react-router-dom';
import { TransactionDetails } from '../../../Balance/components/TransactionDetails/TransactionDetails';
import TransactionDetailsLoop from '../../../../../ui/TransactionDetails/TransactionDetailsLoop';
import { InvoiceTransactionDetails } from '../../../../../ui/TransactionDetails/InvoiceTransactionDetails';
import { NormalText } from '../../../../../ui/Text';


interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'Invoice_date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'Number',
        numeric: false,
        disablePadding: true,
        label: 'Ref Number',
    },
    // {
    //     id: 'Customer',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Customer',
    // },

    // {
    //     id: 'Due_date',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Due date',
    // },
    // {
    //     id: 'Issuing_date',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Payment date',
    // },


    {
        id: 'Type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'Amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    // {
    //     id: 'Payin',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Payin',
    // },
    {
        id: 'Balance',
        numeric: true,
        disablePadding: false,
        label: 'Balance',
    },
    {
        id: 'Link_Invoice',
        numeric: true,
        disablePadding: false,
        label: 'Link Invoice',
    }

];
const Invoices_Statements = ({ datatabal, data }: any) => {
    const [dense, setDense] = React.useState(false);
    const [showTransMoodal, setshowTransMoodal] = React.useState(false);
    const [showInvoiceMoodal, setshowInvoiceMoodal] = React.useState(false);

    const [transData, settransData] = React.useState({});
    const [InvoiceDataData, setInvoiceData] = React.useState({});


    const Funreset = () => {

    }
    const onCloseset = () => {
        setshowTransMoodal(false)
    }
    const onCloseInvoiceset = () => {
        setshowInvoiceMoodal(false)
    }
    return (
        <>
            {showTransMoodal && (
                <TransactionDetailsLoop
                    data={transData}
                    showModal={showTransMoodal}
                    onCloseset={onCloseset}
                />
                // <TransactionDetails
                //     show={showTransMoodal}
                //     onClose={() => {
                //         setshowTransMoodal(false);
                //         settransData('');
                //     }}
                //     data={transData}
                //     resetData={Funreset}
                // />
            )}
            {showInvoiceMoodal &&

                <InvoiceTransactionDetails showModal={showInvoiceMoodal} onCloseset={onCloseInvoiceset} data={InvoiceDataData} />
            }
            <Grid container >
                <Grid item md={12} sm={12} xs={12}>
                    <Paper sx={{ width: '100%', marginTop: "0px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                        <TableContainer className='tab_table_container'>
                            <Table

                                // sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <TableHead>
                                    <TableRow>

                                        {headCells.map((headCell) => (
                                            <TableCell className='tabs_table_cell' style={headCell.label == "Description" ? { textAlign: "center" } : {}} >
                                                {headCell.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {datatabal && datatabal.length > 0 ?
                                        datatabal.map((data: any, index: any) => {
                                            // const isItemSelected = isSelected(data.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                
                                            const Tamount: any = data.amount / 100
                                            return (
                                                <>
                                                    <TableRow

                                                        // hover
                                                        // onClick={(event) => handleClick(event, row.id)}
                                                        role="checkbox"
                                                        // aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index}
                                                    // selected={isItemSelected}
                                                    // sx={{ cursor: 'pointer' }}
                                                    >
                                                        {data?.type == "payin" ? <TableCell onClick={() => {
                                                        }}>

                                                            {data?.linked_invoices?.map((item: any, index: any) => {
                                                                {
                                                                 
                                                                }
                                                                return (

                                                                    <NormalText>
                                                                        {moment(item?.created_at).format(
                                                                            'DD-MMM-YYYY'
                                                                        )}
                                                                    </NormalText>
                                                                )
                                                            })}
                                                        </TableCell> :
                                                            <TableCell onClick={() => {
                                                            }}>

                                                                {data?.invoices?.map((item: any, index: any) => {
                                                                    {
                                                                       
                                                                    }
                                                                    return (

                                                                        <NormalText>
                                                                            {moment(item?.created_at).format(
                                                                                'DD-MMM-YYYY'
                                                                            )}
                                                                        </NormalText>
                                                                    )
                                                                })}
                                                            </TableCell>}
                                                        {data?.type == "payin" ?
                                                            <TableCell >
                                                                {data?.linked_transactions?.map((item: any, index: any) => {
                                                                    return (
                                                                        <NormalText>{item.reference}</NormalText>
                                                                    )
                                                                })}
                                                            </TableCell>
                                                            : <TableCell >
                                                                {data?.invoices?.map((item: any, index: any) => {
                                                                    return (
                                                                        <NormalText>{item.invoice_no}</NormalText>
                                                                    )
                                                                })}
                                                            </TableCell>}
                                                       

                                                        <TableCell onClick={() => {
                                                        }}>
                                                            {data.type == "invoice" ? "Invoice" : data.type == 'payin' ? 'Payin' : data.type}
                                                        </TableCell>
                                                        <TableCell onClick={() => {
                                                        }}>
                                                          
                                                            {data?.type == "payin" ? "-" : '+'}{data?.currency}{" "}{Number(parseFloat(data.amount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                                          
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "left" }} onClick={() => {
                                                        }}>
                                                            {/* {data.currancy} */}
                                                            {data?.currency}{" "}{Number(parseFloat(data?.balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "left" }}>
                                                            {data?.linked == 1 ?
                                                                <IconButton style={{padding:"0px"}} onClick={() => {
                                                                    if (data.type == "invoice") {
                                                                        setshowTransMoodal(true)
                                                                        settransData(data?.linked_transactions)
                                                                    } else {
                                                                        setshowInvoiceMoodal(true)
                                                                        setInvoiceData(data?.linked_invoices)
                                                                    }
                                                                }}>
                                                                    <IconLink />
                                                                </IconButton> :
                                                                <IconUnlink />}
                                                        </TableCell>

                                                    </TableRow>
                                                   
                                                </>
                                            );
                                        }) : null}
                                    {datatabal?.length === 0 && (
                                        <TableCell colSpan={10}>
                                            <TebalNoData text={'No Statements found'} />
                                        </TableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
export default Invoices_Statements;

const IconLink = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8H17C18.333 8 21 8.8 21 12C21 15.2 18.333 16 17 16H15M9 8H7C5.667 8 3 8.8 3 12C3 15.2 5.667 16 7 16H9M8 12H16" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
const IconUnlink = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8H17C18.333 8 21 8.8 21 12C21 15.2 18.333 16 17 16H15M9 8H7C5.667 8 3 8.8 3 12C3 15.2 5.667 16 7 16H9M8 12H16" stroke="#DDDDDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)