import { Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DefaultBtn, DefaultGoBackBtn, LightBtn } from '../../../../../../../ui/ReuseableComponents/Buttons/Buttons';
import "./step3.scss";

const Step3 = ({ stepupdatefun, formik , selectedCurrency , selectedFlag}: any) => {
  const counterpart = formik.values.counterpart || {};
  const currency = formik.values.currency || '';
  const amount = formik.values.amount || 0;
  const vat = formik.values.vat || 0;
  const fee = formik.values.fee || 0;
  const tags = formik.values.tags || [];
  const teams = formik.values.teams || [];
  const balance = formik.values.balance || 0;

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return (
    <Box>
      <DefaultGoBackBtn style={{ marginTop: "-60px" }} onClick={() => stepupdatefun(1)} />
      <Box className="step3_container">
        <Box className="d-flex flex-column gap-2 mb-5 text-center w-100 justify-content-center">
          <AccessTimeIcon className="watch_icon" />
          <Typography className='confirm_heading'>Confirm details</Typography>
          <Typography className="confirm_sub_heading mb-3">Please confirm the payment details before you proceed</Typography>
        </Box>
        {/* ***** Payee ***** */}
        <Box className="d-flex flex-column gap-4">
          <Box className="box payee_box d-flex flex-column gap-2">
            <Typography className="box_heading">Payee</Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Name</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.contact_first_name && counterpart?.contact_last_name 
                  ? `${counterpart.contact_first_name} ${counterpart.contact_last_name}` 
                  : 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Email</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.contact_email || 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Mobile</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.contact_phone || 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Payment Method</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.bank_name && counterpart?.account_number 
                  ? `${counterpart.bank_name} ${counterpart.account_number}` 
                  : 'N/A'}
              </Typography>
            </Box>
          </Box>

          {/* ***** Payment details ***** */}
          <Box className="box payment_details_box d-flex flex-column gap-2">
            <Typography className="box_heading">Payment details</Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Amount</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {currency} {amount || 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Vat</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {vat ? `${vat}` : 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Fee</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {fee ? `${selectedCurrency} ${fee}` : 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Category</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {tags.length > 0 ? tags.map((tag: any) => tag).join(", ") : 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Teams</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {teams.length > 0 ? teams.map((team : any) => team).join(", ") : 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Transaction Date</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {formattedDate || 'N/A'}
              </Typography>
            </Box>
          </Box>

          {/* ***** Account details ***** */}
          <Box className="box account_details_box d-flex flex-column gap-2">
            <Typography className='box_heading'>Account details</Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex gap-3 align-items-center">
                <img src={selectedFlag} alt='' style={{ width: "22px", height: "18px", borderRadius: "50%" , padding:"0px" , margin:"0px" }} />
                <Typography className="kes">{currency}</Typography>
              </Box>
              <Typography style={{ fontFamily: "Poppins" }}>
                {counterpart?.account_number || 'N/A'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Available balance</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {currency} {balance || 'N/A'}
              </Typography>
            </Box>
          </Box>

          <Box className="mt-4 d-flex justify-content-between align-items-center">
            <LightBtn onClick={() => stepupdatefun(1)} text="Cancel" />
            <DefaultBtn onClick={() => stepupdatefun(3)} text="Make payment" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;
