import { alertActions } from ".";
import { Unauthorized } from "../_constants/ErrorMessage";
import { paymentJourneyConstants } from "../_constants/paymentJourney.constants";
import { paymentJourneyService } from "../_services/paymentJourney.service";
import toast from "react-hot-toast";

export const paymentJourneyActions = {
  getFees,
  getFeesNew,
  getValidateAccount,
  getInveact,
  getPesaBankList,
  AddBankDisbursement,
  Express_Payment_actions,
  AddBankDisbursement_experes,
  Create_integration_active,
  getIntegration_active,
  Create_credentials_active,
  getIntegration_apikey_active,
  credentials_delete_active,
  getdurationlist_active,
  Create_budget_active,
  budget_list_data,
  budget_viwe_list,
  budget_delete_active,
  edit_budget_active,
  getBulk_payment,
  getbulk_payments_status_active,
  getSave_data,
  getPaybillNew,
  get_accounting_types_active,
  create_connection,
  get_connections,
  disable_connection,
  getPaymentLinkByShortcode,
  getInvoicePaymentLinkData,
  CreatePayViaLink,
  TransStatusLink,
  TransLinkPdf,
  invoicesend,
  invoicesendreceiver,
  Bankdetails,
  PaymentLinkApiList,
  paymentLinkApiCreate,
  getShareablePdfLink,
  PaymentLinkApiDeleteActive,
  PaymentLinkApiUpdateActive,
  StanbicVerifyAccount,
  getCountries,
  getCountriesDetails,
  getCurrencies,
  getCountriesByCurrency,
  AddBankExperesUsd,
  ChackBalance,
  getCountriesBalances,
  checkTransactionType,
  getMobileMoneyMethods,
  getBankTransfersMethods,
  getPayoutPaymentLimits,
  Express_Payment_v3_actions,
  getAllCurrenciesDetails,
  getBankDetailByCurrency,
  getMobileMethodsByCurrency,
  getSelectedUserCurrency,
  addSelectedUserCurrency,
  cereteInvoice,
  UpdateStatusInvoice,
  editInvoice,
  invoicesendreceiverV2,
  getBankMethodsByCurrency,
  sendInvoiceActive,
  CreateInvoiceLink,
  PaymentLinkApiInactiveActive
};

function getFees(amount: any, payment_method: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getFees(amount, payment_method)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETFEES_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETFEES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETFEES_FAILURE };
  }
}



function ChackBalance(bodyData: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .ChackBalance(bodyData)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success == true) {
            dispatch(success(response));
          } else {
            dispatch(success(response));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.CHACKBALANCE_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.CHACKBALANCE_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.CHACKBALANCE_FAILURE };
  }
}
function getShareablePdfLink(ref: any, id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getShareablePdfLink(ref, id)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETSHAREABLEPDFLINK_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETSHAREABLEPDFLINK_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETSHAREABLEPDFLINK_FAILURE };
  }
}
function getInveact(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getInvite(datalist)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(success(response.data));
          } else {
            toast.error(response.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.INVITE_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.INVITE_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.INVITE_FAILURE };
  }
}
function getSave_data(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getSave_data(datalist)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(success(response.data));
          } else {
            toast.error(response.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.SAVE_DATA_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.SAVE_DATA_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.SAVE_DATA_FAILURE };
  }
}
function getBulk_payment(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getBulk_payment(datalist)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success == true) {
            dispatch(success(response.data));
            setTimeout(() => {
              window.location.href = "/approvals/approve_payment";
            }, 1200);
          } else {
            toast.error(response.message);
            dispatch(success(response));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GET_BULKPAYMENT_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GET_BULKPAYMENT_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GET_BULKPAYMENT_FAILURE };
  }
}
function getFeesNew(amount: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getFeesNew(amount)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETNEWFEES_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETNEWFEES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETNEWFEES_FAILURE };
  }
}
function getAllCurrenciesDetails() {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    paymentJourneyService.getAllCurrenciesDetails()
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          dispatch(success(response.data))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: paymentJourneyConstants.GETALL_CURRENCIES_DETAILS_REQUEST } }
  function success(data: any) { return { type: paymentJourneyConstants.GETALL_CURRENCIES_DETAILS_SUCCESS, data } }
  function failure(s: string) { return { type: paymentJourneyConstants.GETALL_CURRENCIES_DETAILS_FAILURE } }
}

// GET selected-user currency 
function getSelectedUserCurrency(data: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    paymentJourneyService.getSelectedUserCurrency(data)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          dispatch(success(response.data))
        } else {
          dispatch(failure(response.data))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: paymentJourneyConstants.GET_SELECTED_USER_CURRENCIES_DETAILS_REQUEST } }
  function success(data: any) { return { type: paymentJourneyConstants.GET_SELECTED_USER_CURRENCIES_DETAILS_SUCCESS, data } }
  function failure(s: string) { return { type: paymentJourneyConstants.GET_SELECTED_USER_CURRENCIES_DETAILS_FAILURE } }
}

// ADD selected-user currency 
function addSelectedUserCurrency(data: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    paymentJourneyService.addSelectedUserCurrency(data)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          dispatch(success(response.data))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: paymentJourneyConstants.ADD_SELECTED_USER_CURRENCIES_DETAILS_REQUEST } }
  function success(data: any) { return { type: paymentJourneyConstants.ADD_SELECTED_USER_CURRENCIES_DETAILS_SUCCESS, data } }
  function failure(s: string) { return { type: paymentJourneyConstants.ADD_SELECTED_USER_CURRENCIES_DETAILS_FAILURE } }
}


function getCountries() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getCountries()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETCOUNTRIES_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETCOUNTRIES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETCOUNTRIES_FAILURE };
  }
}
function getCountriesBalances() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getCountriesBalances()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETCOUNTRIES_BALANCES_REQUEST };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.GETCOUNTRIES_BALANCES_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETCOUNTRIES_BALANCES_FAILURE };
  }
}
function getCountriesDetails(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getCountriesDetails(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETCOUNTRIES_DETAILS_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETCOUNTRIES_DETAILS_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETCOUNTRIES_DETAILS_FAILURE, s };
  }
}
function getCurrencies() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getCurrencies()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETCURRENCIES_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETCURRENCIES_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETCURRENCIES_FAILURE, s };
  }
}
function getCountriesByCurrency(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getCountriesByCurrency(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_REQUEST };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return {
      type: paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_FAILURE,
      s,
    };
  }
}

function getMobileMethodsByCurrency(data: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    paymentJourneyService.getMobileMethodsByCurrency(data)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          dispatch(success(response.data))
        } else {
          dispatch(failure(response.data))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: paymentJourneyConstants.GET_MOBILE_METHODS_BY_CURRENCY_REQUEST } }
  function success(data: any) { return { type: paymentJourneyConstants.GET_MOBILE_METHODS_BY_CURRENCY_SUCCESS, data } }
  function failure(s: string) { return { type: paymentJourneyConstants.GET_MOBILE_METHODS_BY_CURRENCY_FAILURE, } }
}

function getBankMethodsByCurrency(data: any) {


  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    paymentJourneyService.getBankMethodsByCurrency(data)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          dispatch(success(response.data))
        } else {
          dispatch(failure(response.data))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: paymentJourneyConstants.GET_BANK_METHODS_BY_CURRENCY_REQUEST } }
  function success(data: any) { return { type: paymentJourneyConstants.GET_BANK_METHODS_BY_CURRENCY_SUCCESS, data } }
  function failure(s: string) { return { type: paymentJourneyConstants.GET_BANK_METHODS_BY_CURRENCY_FAILURE, } }
}


function getBankDetailByCurrency(data: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    paymentJourneyService.getBankDetailByCurrency(data)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          dispatch(success(response.data))
        } else {
          dispatch(failure(response.data))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: paymentJourneyConstants.GET_BANK_DETAIL_BY_CURRENCY_REQUEST } }
  function success(data: any) { return { type: paymentJourneyConstants.GET_BANK_DETAIL_BY_CURRENCY_SUCCESS, data } }
  function failure(s: string) { return { type: paymentJourneyConstants.GET_BANK_DETAIL_BY_CURRENCY_FAILURE, } }
}

function getPaybillNew(amount: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getPayBILLNew(amount)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response) {
            dispatch(success(response));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GET_PAYBILL_NEW_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GET_PAYBILL_NEW_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GET_PAYBILL_NEW_FAILURE };
  }
}
function getValidateAccount(account_number: any, method: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getValidateAccount(account_number, method)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response) {
            dispatch(success(response));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETVALIDATE_ACCOUNT_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETVALIDATE_ACCOUNT_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETVALIDATE_ACCOUNT_FAILURE };
  }
}
function StanbicVerifyAccount(amount: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .StanbicVerifyAccount(amount)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response) {
            dispatch(success(response));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_REQUEST };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_FAILURE };
  }
}
function checkTransactionType(amount: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .checkTransactionType(amount)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response) {
            dispatch(success(response));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.CHECK_TRANSACTION_TYPE_REQUEST };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.CHECK_TRANSACTION_TYPE_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.CHECK_TRANSACTION_TYPE_FAILURE };
  }
}
function getPesaBankList() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getPesaBankList()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETPESABANKLIST_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETPESABANKLIST_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETPESABANKLIST_FAILURE };
  }
}
function getIntegration_active() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getIntegration()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETINTEGRATION_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETINTEGRATION_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETINTEGRATION_FAILURE };
  }
}
function get_accounting_types_active() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .get_accounting_types()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GET_ACCOUNTING_TYPE_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GET_ACCOUNTING_TYPE_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GET_ACCOUNTING_TYPE_FAILURE };
  }
}
function get_connections() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .get_connections()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GET_CONNECTION_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GET_CONNECTION_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GET_CONNECTION_FAILURE };
  }
}
function getdurationlist_active() {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getdurationlist()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETDURATIONLIST_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETDURATIONLIST_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETDURATIONLIST_FAILURE };
  }
}
function getIntegration_apikey_active(data: any, setaddexpressSuccrender: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .getintegration_apikey(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
            setaddexpressSuccrender(true)
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETINTEGRATION_APIKEY_REQUEST };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.GETINTEGRATION_APIKEY_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETINTEGRATION_APIKEY_FAILURE };
  }
}
function credentials_delete_active(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .credentials_delete(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.DELETE_CREDENTIALS_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.DELETE_CREDENTIALS_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.DELETE_CREDENTIALS_FAILURE };
  }
}
function budget_delete_active(id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .budget_delete_servicers(id)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
            window.location.href = "/budget";
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.DELETE_BUDGET_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.DELETE_BUDGET_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.DELETE_BUDGET_FAILURE };
  }
}
function PaymentLinkApiDeleteActive(id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .PaymentLinkApiDeleteServicers(id)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Payment link deleted successfully"));
            dispatch(success(response.message));
            window.location.href = "/settings/Payment_Link";
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.DELETE_PAYMENT_LINK_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.DELETE_PAYMENT_LINK_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.DELETE_PAYMENT_LINK_FAILURE };
  }
}
function PaymentLinkApiInactiveActive(id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .PaymentLinkApiInactiveActive(id)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Payment link deleted successfully"));
            dispatch(success(response.message));
            window.location.reload()
            // window.location.href = "/settings/Payment_Link";
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.PAYMENTLINK_INACTIVE_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.PAYMENTLINK_INACTIVE_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.PAYMENTLINK_INACTIVE_FAILURE };
  }
}
function budget_list_data(data: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .budget_list_data_service(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          } else {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.LIST_BUDGET_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.LIST_BUDGET_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.LIST_BUDGET_FAILURE };
  }
}
function budget_viwe_list(data: any, setrender?: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    paymentJourneyService
      .budget_viwe_list_service(data)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
            if (typeof setrender == "function") {
              setrender(true);
            }
          } else {
            if (typeof setrender == "function") {
              setrender(true);
            }
            dispatch(alertActions.success("OTP verified successful"));
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request() {
    return { type: paymentJourneyConstants.GETBUDGET_LIST_REQUEST };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETBUDGET_LIST_SUCCESS, data };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETBUDGET_LIST_FAILURE };
  }
}
function AddBankDisbursement(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));

    paymentJourneyService
      .AddBankDisbursement(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_SUCCESS, data };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_FAILURE,
      error,
    };
  }
}
function Create_budget_active(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .Create_budget(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.CREATE_BUDGET_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.CREATE_BUDGET_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.CREATE_BUDGET_FAILURE, data };
  }
}
function edit_budget_active(datalist: any, id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .edit_budget(datalist, id)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.EDIT_BUDGET_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.EDIT_BUDGET_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.EDIT_BUDGET_FAILURE, data };
  }
}
function editInvoice(datalist: any, id: any, ButtonType: any, setLoader: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    dispatch(request(datalist))
    paymentJourneyService.editInvoice(datalist, id)
      .then(data => {
        dispatch(alertActions.clear());
        return data.json()
      })
      .then(response => {
        if (response.success) {
          // toast.success(response.message, { duration: 8000 });
          dispatch(alertActions.success('Add Bank disbursemennt'))
          dispatch(success(response.data))
          if (ButtonType == "Save") {
            setLoader(false)
            toast.success(response.message);
            setTimeout(() => {
              window.location.href = "/invoice"
            }, 3000);
          } else if (ButtonType == "Send_via_Email") {
            setLoader(false)
            toast.success(response.message);
            setTimeout(() => {
              window.location.href = `/ComposeEmail?invoiceId=${response?.data?.monite_invoice_id}&email=${datalist?.customer?.contact_email}&name=${response?.data?.invoice_name}&status=Draft`
            }, 3000);
          } else if (ButtonType == "Download_PDF") {
            setLoader(false)
            toast.success(response.message);
            setTimeout(() => {
              window.open(
                response?.date?.path,
                '_blank' // <- This is what makes it open in a new window.
              );
              // window.location.href=otp?.date?.path
            }, 3000);
          }
        } else {
          dispatch(failure(response.message));
          setLoader(false)
          dispatch(alertActions.error(response.message));
          toast.error(response.message, { duration: 8000 });
        }
      },
        error => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(data: any) { return { type: paymentJourneyConstants.EDIT_INVOICE_REQUEST, data } }
  function success(data: any) { return { type: paymentJourneyConstants.EDIT_INVOICE_SUCCESS, data } }
  function failure(data: any) { return { type: paymentJourneyConstants.EDIT_INVOICE_FAILURE, data } }
}
function PaymentLinkApiUpdateActive(datalist: any, id: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .PaymentLinkApiUpdateActive(datalist, id)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message || "Payment link failed to update.");
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKUPDATE_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKUPDATE_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKUPDATE_FAILURE, data };
  }
}
function sendInvoiceActive(datalist: any, id: any, Status: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .sendInvoiceActive(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response));
            if (Status == "Draft") {
              dispatch(paymentJourneyActions.UpdateStatusInvoice(id, "issue", "", '') as any);
            } else {
              window.location.href = "/invoice"
            }
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message || "Payment link failed to update.");
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.SEND_INVOICE_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.SEND_INVOICE_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.SEND_INVOICE_FAILURE, data };
  }
}
function Create_integration_active(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .Create_integration(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response.data));
            window.location.href = "/settings/apikey";
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.CREATE_INTEGRATION_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.CREATE_INTEGRATION_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.CREATE_INTEGRATION_FAILURE, data };
  }
}
function getbulk_payments_status_active(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .getbulk_payments_status_active(datalist)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return {
      type: paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_REQUEST,
      data,
    };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_SUCCESS,
      data,
    };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_FAILURE,
      error,
    };
  }
}
function Create_credentials_active(datalist: any, setaddexpressSuccrender: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .Create_credentials(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response.data));
            setaddexpressSuccrender(true)
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.CREATE_CREDENTIALS_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.CREATE_CREDENTIALS_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.CREATE_CREDENTIALS_FAILURE, data };
  }
}
function AddBankDisbursement_experes(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .AddBankDisbursement_experes(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("Add Bank disbursemennt"));
            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return {
      type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_REQUEST,
      data,
    };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_SUCCESS,
      data,
    };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_FAILURE,
      error,
    };
  }
}
function AddBankExperesUsd(url: any, datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .AddBankExperesUsd(url, datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(alertActions.success("Add Bank disbursemennt"));
          

            dispatch(success(response.data));
          } else {
            dispatch(failure(response.message));
            dispatch(alertActions.error(response.message));
            toast.error(response.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.ADDBANK_EXPERES_USD_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.ADDBANK_EXPERES_USD_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.ADDBANK_EXPERES_USD_FAILURE, error };
  }
}
function create_connection(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .create_connection(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(success(response.data));
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.CREATE_CONNECTION_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.CREATE_CONNECTION_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.CREATE_CONNECTION_FAILURE, data };
  }
}
function disable_connection(datalist: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(datalist));
    paymentJourneyService
      .disable_connection(datalist)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          } else {
            dispatch(success(response.data));
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.DISABLE_CONNECTION_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.DISABLE_CONNECTION_SUCCESS, data };
  }
  function failure(data: any) {
    return { type: paymentJourneyConstants.DISABLE_CONNECTION_FAILURE, data };
  }
}
function Express_Payment_actions(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .Express_Payment_Service(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.EXPRESSPAYMENT_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.EXPRESSPAYMENT_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.EXPRESSPAYMENT_FAILURE, error };
  }
}
function Express_Payment_v3_actions(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .Express_Payment_v3_Service(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.EXPRESSPAYMENT_V3_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.EXPRESSPAYMENT_V3_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.EXPRESSPAYMENT_V3_FAILURE, error };
  }
}

function getPaymentLinkByShortcode(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .getPaymentLink_shortcode(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKSHORTCODE_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKSHORTCODE_SUCCESS, otp };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.PAYMENTLINKSHORTCODE_FAILURE,
      error,
    };
  }
}

function getInvoicePaymentLinkData(req: any, setlinkRender: any,setShow:any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .getInvoicePaymentLink_data(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
            setlinkRender(true)
          } else {
            setShow(true)
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.INVOICE_PAYMENT_LINK_DATA_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.INVOICE_PAYMENT_LINK_DATA_SUCCESS, otp };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.INVOICE_PAYMENT_LINK_DATA_FAILURE,
      error,
    };
  }
}





function CreatePayViaLink(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .CreatePayViaLink(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.PAYLINK_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.PAYLINK_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.PAYLINK_FAILURE, error };
  }
}
function CreateInvoiceLink(req: any, setpayReqrender: any, setpayReqResrender: any, setSuccess_data: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    setSuccess_data("PANDING")
    dispatch(request(req));
    paymentJourneyService
      .CreateInvoiceLink(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
            setpayReqrender(true)
          } else {
            setpayReqResrender(false)
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.INVOICE_PAYMENTLINK_CREATE_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.INVOICE_PAYMENTLINK_CREATE_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.INVOICE_PAYMENTLINK_CREATE_FAILURE, error };
  }
}
function TransStatusLink(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .TransStatusLink(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.TRANSPAYLINK_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.TRANSPAYLINK_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.TRANSPAYLINK_FAILURE, error };
  }
}
function PaymentLinkApiList(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    paymentJourneyService
      .PaymentLinkApiList(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKAPILIST_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKAPILIST_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.PAYMENTLINKAPILIST_FAILURE, error };
  }
}
function TransLinkPdf(Link:any,req: any, data: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    paymentJourneyService
      .TransLinkPdf(Link,req, data)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(
              alertActions.success(" Express payment Request successful")
            );
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return { type: paymentJourneyConstants.TRANSPAYLINK_PDF_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.TRANSPAYLINK_PDF_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.TRANSPAYLINK_PDF_FAILURE, error };
  }
}
function invoicesend(req: any, URL: any) {
  return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
    dispatch(request(req))

    paymentJourneyService.invoicesend(req, URL)
      .then(response => {
        return response.json()
      })
      .then(
        otp => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(alertActions.success(' Express payment Request successful'))
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
          }
        },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(otp: any) {
    return { type: paymentJourneyConstants.INVOICE_SENDTOME_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.INVOICE_SENDTOME_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.INVOICE_SENDTOME_FAILURE, error };
  }
}
function cereteInvoice(req: any, ButtonType: any, setLoader: any) {


  return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
    dispatch(request(req))

    paymentJourneyService.cereteInvoice(req)
      .then((response: any) => {
        return response.json()
      })
      .then(
        otp => {
          if (otp.success) {
            dispatch(success(otp));
      
            if (ButtonType == "Save") {
              setLoader(false)
              toast.success(otp.message);
              setTimeout(() => {
                window.location.href = "/invoice"
              }, 3000);
            } else if (ButtonType == "Send_via_Email") {
              setLoader(false)
              toast.success(otp.message);
              setTimeout(() => {
                window.location.href = `/ComposeEmail?invoiceId=${otp?.data?.monite_invoice_id}&email=${req?.customer?.contact_email}&name=${otp?.data?.invoice_name}&status=Draft`
              }, 3000);
            } else if (ButtonType == "Download_PDF") {
              setLoader(false)
              toast.success(otp.message);
              setTimeout(() => {
                window.open(
                  otp?.data?.path,
                  '_blank' // <- This is what makes it open in a new window.
                );
                // window.location.href=otp?.date?.path
              }, 2000);
              setTimeout(() => {
                      window.location.href = "/invoice"
                    }, 4500);
            }
            // if (ButtonType == "Create" || "Save") {
            //   setLoader(false)
            //   toast.success(otp.message);
            //   if (Download == "Download") {
            //  
            //     setTimeout(() => {
            //       window.open(
            //         otp?.data?.path,
            //         '_blank'
            //       );
            //     }, 1500)
            //     setTimeout(() => {
            //       window.location.href = "/invoice"
            //     }, 5000);
            //   }
            //   else {
            //     setTimeout(() => {
            //       window.location.href = "/invoice"
            //     }, 3000);

            //   }
            // }
            // else if (Download == "Download") {
            //   toast.success(otp.message);
            //   

            //   setTimeout(() => {
            //     window.open(
            //       otp?.date?.path,
            //       '_blank' // <- This is what makes it open in a new window.
            //     );
            //     // window.location.href=otp?.date?.path
            //   }, 3000);
            // }
            else {
              setLoader(false)
              toast.error("Invice not created");
            }
            dispatch(alertActions.success(' Express payment Request successful'))
          } else {
            dispatch(failure(otp.message));
            setLoader(false)
            dispatch(alertActions.error(otp.message));
          }
        },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(otp: any) { return { type: paymentJourneyConstants.CREATE_INVOICE_REQUEST, otp } }
  function success(otp: any) { return { type: paymentJourneyConstants.CREATE_INVOICE_SUCCESS, otp } }
  function failure(error: any) { return { type: paymentJourneyConstants.CREATE_INVOICE_FAILURE, error } }
}
function UpdateStatusInvoice(id: any, status: any, path: any, amount: any) {
  return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
    dispatch(request(id))

    paymentJourneyService.UpdateStatusInvoice(id, status, amount)
      .then(response => {
        return response.json()
      })
      .then(
        otp => {
          if (otp.success) {
            dispatch(success(otp));
            if (!!path) {
              window.location.href = "/invoice"
            } else {
              window.location.reload()
            }
            dispatch(alertActions.success(' Express payment Request successful'))
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
          }
        },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(otp: any) { return { type: paymentJourneyConstants.UPDATE_STATUS_INVOICE_REQUEST, otp } }
  function success(otp: any) { return { type: paymentJourneyConstants.UPDATE_STATUS_INVOICE_SUCCESS, otp } }
  function failure(error: any) { return { type: paymentJourneyConstants.UPDATE_STATUS_INVOICE_FAILURE, error } }
}
function paymentLinkApiCreate(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));
    paymentJourneyService
      .paymentLinkApiCreate(req)
      .then((response) => {
        return response.json();
      })
      .then(
        (otp) => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(alertActions.success("Payment link creation successful"));
            toast.success(otp.message);
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(otp: any) {
    return {
      type: paymentJourneyConstants.PAYMENT_LINK_API_CREATE_REQUEST,
      otp,
    };
  }
  function success(otp: any) {
    return {
      type: paymentJourneyConstants.PAYMENT_LINK_API_CREATE_SUCCESS,
      otp,
    };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.PAYMENT_LINK_API_CREATE_FAILURE,
      error,
    };
  }
}
function invoicesendreceiver(req: any, URL: any) {
  return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
    dispatch(request(req))

    paymentJourneyService.invoicesendreceiver(req, URL)
      .then(response => {
        return response.json()
      })
      .then(
        otp => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(alertActions.success(' Express payment Request successful'))
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message, { duration: 8000 });
          }
        },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(otp: any) {
    return {
      type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_REQUEST,
      otp,
    };
  }
  function success(otp: any) {
    return {
      type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_SUCCESS,
      otp,
    };
  }
  function failure(error: any) {
    return {
      type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_FAILURE,
      error,
    };
  }
}
function invoicesendreceiverV2(req: any, id: any) {
  return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
    dispatch(request(req))

    paymentJourneyService.invoicesendreceiverV2(req)
      .then(response => {
        return response.json()
      })
      .then(
        otp => {
          if (otp.success) {
            dispatch(success(otp));
            dispatch(alertActions.success(' Express payment Request successful'))
            dispatch(paymentJourneyActions.UpdateStatusInvoice(id, "issued", "", '') as any);
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message, { duration: 8000 });
          }
        },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(otp: any) { return { type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_V2_REQUEST, otp } }
  function success(otp: any) { return { type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_V2_SUCCESS, otp } }
  function failure(error: any) { return { type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_V2_FAILURE, error } }
}
function Bankdetails(req: any) {
  return (
    dispatch: (arg0: {
      type: string;
      otp?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(req));

    paymentJourneyService.Bankdetails(req)
      .then(response => {
        return response.json()
      })
      .then(
        otp => {
          if (otp.success) {
            dispatch(success(otp.data));
            dispatch(alertActions.success(' Express payment Request successful'))
          } else {
            dispatch(failure(otp.message));
            dispatch(alertActions.error(otp.message));
            toast.error(otp.message, { duration: 8000 });
          }
        },
        error => {

          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
  }

  function request(otp: any) {
    return { type: paymentJourneyConstants.BANK_DETELS_REQUEST, otp };
  }
  function success(otp: any) {
    return { type: paymentJourneyConstants.BANK_DETELS_SUCCESS, otp };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.BANK_DETELS_FAILURE, error };
  }
}
// Mobile Money
function getMobileMoneyMethods(currency: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(currency));
    paymentJourneyService
      .getMobileMoneyMethods(currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(data: any) {
    return {
      type: paymentJourneyConstants.GETMOBILE_MONEY_METHODS_REQUEST,
      data,
    };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.GETMOBILE_MONEY_METHODS_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETMOBILE_MONEY_METHODS_FAILURE };
  }
}
function getBankTransfersMethods(currency: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(currency));
    paymentJourneyService
      .getBankTransfersMethods(currency)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            dispatch(success(response.data));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.message));
        }
      );
  };

  function request(data: any) {
    return {
      type: paymentJourneyConstants.GETBANK_TRANSFERS_METHODS_REQUEST,
      data,
    };
  }
  function success(data: any) {
    return {
      type: paymentJourneyConstants.GETBANK_TRANSFERS_METHODS_SUCCESS,
      data,
    };
  }
  function failure(s: string) {
    return { type: paymentJourneyConstants.GETBANK_TRANSFERS_METHODS_FAILURE };
  }
}
function getPayoutPaymentLimits(payload: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    dispatch(request(payload));
    paymentJourneyService
      .getPayoutPaymentLimits(payload)
      .then((data) => {
        dispatch(alertActions.clear());
        return data.json();
      })
      .then(
        (response) => {
          if (response.success == true) {
            dispatch(success(response));
          } else {
            dispatch(success(response));
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data: any) {
    return { type: paymentJourneyConstants.GETPAYOUT_PAYMENTLIITS_REQUEST, data };
  }
  function success(data: any) {
    return { type: paymentJourneyConstants.GETPAYOUT_PAYMENTLIITS_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: paymentJourneyConstants.GETPAYOUT_PAYMENTLIITS_FAILURE, error };
  }
}