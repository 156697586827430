
import "./inputs.scss"
import React from 'react';
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export const SearchInput = ({ value, onChange, placeholder, disabled, style, ...props }: any) => {
  return (
    <Box style={{ position: 'relative', width: '100%' }}>

      <SearchIcon
        className="payment_search_icon"
        style={{
          position: 'absolute',
          left: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
          pointerEvents: 'none',
          fontSize: '22px',
          minHeight: "45px",
          maxHeight: "45px",
        }}
      />

      <input
        {...props}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className="form-control"
        style={{
          width: '100%',
          padding: '12px 10px 12px 50px',
          borderRadius: '15px',
          border: '1px solid #ccc',
          fontSize: '12px',
          // margin:'10px 0px 10px 0px',
          minHeight: "50px",
          maxHeight: "50px",
          fontFamily: "Poppins",
          ...style,
        }}
      />
    </Box>
  );
};


export const CustomInput = ({ value, type, onChange, placeholder, disabled, name, style, ...props }: any) => {
  return (
    <Box>
      <input
        {...props}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className="form-control"
        name={name}
        style={{
          width: '100%',
          padding: '12px 22px 12px 22px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          fontSize: '12px',
          margin: '10px 0px 10px 0px',
          minHeight: "50px",
          maxHeight: "50px",
          fontFamily: "Poppins",
          ...style,
        }}
      />
    </Box>

  )
}


export const InputDropBox = ({
  inputValue,
  placeholder,
  onInputChange,
  onArrowClick,
  arrowForwardIcon,
  style,
  disabled
}: any) => {

  return (
    <Box>
      <TextField
      disabled={disabled}
        value={inputValue}
        onChange={onInputChange}
        placeholder={placeholder}
        variant="outlined"
        style={{
          width: "100%",
          minHeight: "50px",
          maxHeight: "50px",
          fontFamily: "Poppins",
          borderRadius: "5px",
          ...style
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: "50px",
            maxHeight: "50px",
            borderRadius: "5px",
            fontSize: "12px",
            paddingLeft: "9px",
            fontFamily: "Poppins"
          },
        }}
        InputProps={{
          endAdornment: (
            <Box style={{ width: "max-content" }}>{arrowForwardIcon}</Box>
          )
        }}
        className="inputdropbox"
      />
    </Box>
  );
};






