import React, { useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material"
import { SearchInput } from "../../../../../ui/ReuseableComponents/Inputs/Inputs"
import AddANewPayee from "../../../../../ui/ReuseableComponents/Payee/PayeeComponents"
import "./expresspayment.scss"
import Step1 from './Components/Step1/Step1';
import Step2 from "./Components/Step2/Step2";
import Step3 from './Components/Step3/Step3';
import Step4 from './Components/Step4/Step4';
import AddNewPayee from './Components/Step1/Components/AddNewPayee';
import Model from '../../../../../ui/ReuseableComponents/Model/Model';
import { useDispatch } from 'react-redux';
import { payablesFlowactions } from '../../../../../../_actions';
import SchedulePayment from '../SchedulePayment/SchedulePayment';



const ExpressPayment = ({ errorsMsg, setErrorMsg, SelectBlence, stepupdatefun, Step, setSelectedUser, selectedUser, setIsAddPayeeComponent, isAddPayeeComponent, SelectFleg, SelectCurrency, formik }: any) => {
  const [render, setRender] = useState(true)
  const [getPayees, setGetPayees] = useState([])
  // const [selectpayees, setSelectPayees] = useState<any>()
  const [searchPayee, setSearchPayee] = useState("")
  const [loader, setLoader] = useState(false)
  const [loaderPayment, setLoaderPayment] = useState(false)
  const [teamData, setTeamData] = useState<any[]>([]);
  const [categoriesData, setCategoriesData] = useState<any[]>([])
  const [loaderStatus, setLoaderStatus] = useState('')
  const [bankData, setBankData] = useState<any[]>([])
  const [setpprops, setStepprops] = useState(0)

  const dispatch = useDispatch()
  console.log(selectedUser, "searchPayee");

  const getCounterparts = (data: any) => {
    console.log(data, "datadata");

    setGetPayees(data)
    setLoader(false)
  }
  useEffect(() => {
    setStepprops(Step)

  }, [Step])
  useEffect(() => {
    if (render) {
      setLoader(true)
      dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);

    }
  }, [render])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      doneTyping();
    }, 1000);
    return () => clearTimeout(timeoutId);
    // }
  }, [SelectCurrency, searchPayee])
  const doneTyping = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);
  }
  const getTeam = (data: any) => {
    setTeamData(data)
  }
  useEffect(() => {
    dispatch(payablesFlowactions.getApipayment("/team/get-team", getTeam) as any);
  }, []);
  const getTagName = (data: any) => {
    setCategoriesData(data)
  }
  console.log(categoriesData, "saaaaa")
  useEffect(() => {
    dispatch(payablesFlowactions.getApipayment("/counterparts/get-tags", getTagName) as any);
  }, [])
  const getBank = (data: any) => {
    setBankData(data)
  }
  console.log(bankData, "bankData")
  useEffect(() => {
    dispatch(payablesFlowactions.getApipayment("/bank/get-pesalink-bank-list", getBank) as any);
  }, [])

  useEffect(() => {
    if (!!selectedUser) {
      formik.setFieldValue('counterpart', selectedUser)
    }
  }, [selectedUser])
  useEffect(() => {
    if (!!SelectCurrency) {
      formik.setFieldValue('currency', SelectCurrency)
      // formik.setFieldValue('country_id',method.country_id)

    }

  }, [SelectCurrency])
  useEffect(() => {
    if (!!SelectBlence) {
      formik.setFieldValue('balance', SelectBlence)
    }
  }, [SelectBlence])
  const ExpeshPaymentRes = (data: any) => {

    ExpressPaymentStatus(data?.data?.reference)
  }
  const ExpressPaymentStatusRes = (data: any) => {
    if (data?.data?.status == "pending") {

      setTimeout(() => {
        ExpressPaymentStatus(data?.data?.id)
      }, 2000)
    } else if (data?.data?.status == "failed") {
      setLoaderStatus("failed")

    } else if (data?.data?.status == "success") {
      setLoaderStatus("success")

    }
  }
  const ExpressPaymentStatus = (id: any) => {
    dispatch(payablesFlowactions.getApipayment(`/wallet/get-transaction-status/${id}`, ExpressPaymentStatusRes) as any);

  }
  const ExpressPayment = (otp: any) => {
    var body = {
      "otp": otp, // Newly added
      "account_number": 80000003, // Newly added
      "counterpart": formik.values.counterpart,
      "invoice_record": formik.values.invoice_record,
      "vat": formik.values.vat,
      "amount": formik.values.amount,
      "description": formik.values.description,
      "tags": formik.values.tags,
      "teams": formik.values.teams,
      "payment_method_type": formik.values.type,
      "currency": formik.values.currency,
      "country_id": formik.values.country_id,
      // "verified_account_name": ""
    }
    setLoaderStatus("pending")
    dispatch(payablesFlowactions.PostPaymentfull("/v4/express-payment", body, ExpeshPaymentRes, setLoaderPayment) as any)
  }

  useEffect(() => {
    if (formik.values.currency == "USD" && formik.values.amount <= 100) {
      setErrorMsg("Please enter an amount greater than or equal to USD 100.")
    } else {
      setErrorMsg("")
    }
    if (formik.values.amount > 999999 && !formik.values.invoice_record) {
      setErrorMsg("The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.Please attach a supporting document for the transaction.")
    } else {
      setErrorMsg("")
    }
    if (formik.values.currency == "USD" && formik.values.invoice_record) {
      setErrorMsg("Please attach a supporting document for the transaction.")

    } else {
      setErrorMsg("")
    }
  }, [formik])
  return (
    <Box className="express_payment_conatiner">
      {/* <Step1/> */}

      {/* ///This is here just for Information that from where and I am passing 
        payee data in step2 
        //// and payee={selectedUser} selected payee is a state in step1.tsx */}
      {/* <Step2
          onNextStep={() => setCurrentStep("step3")}
          // payee={selectedUser}
          // onBack={handleBackToList}
        /> */}
      {Step == 0 ?
        <Step1 formik={formik} loader={loader} bankData={bankData} searchPayee={searchPayee} setSelectedUser={setSelectedUser} setSearchPayee={setSearchPayee} SelectFleg={SelectFleg} getPayees={getPayees} stepupdatefun={stepupdatefun} setIsAddPayeeComponent={setIsAddPayeeComponent} isAddPayeeComponent={isAddPayeeComponent} />
        : Step == 1 ?
          <Step2 errorsMsg={errorsMsg} selectedCurrency={SelectCurrency} selectedFlag={SelectFleg} formik={formik} selectpayees={selectedUser} categoriesData={categoriesData} teamData={teamData} stepupdatefun={stepupdatefun} isAddPayeeComponent={isAddPayeeComponent} setIsAddPayeeComponent={setIsAddPayeeComponent} />
          : Step == 2 ?
            <Step3 formik={formik} stepupdatefun={stepupdatefun} />
            : Step == 3 ?
              <Step4 ExpressPayment={ExpressPayment} formik={formik} stepupdatefun={stepupdatefun} /> : ""}
      {/* <SchedulePayment/> */}
    </Box>
  )
}

export default ExpressPayment;