import { Box, Typography } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import { InputNumber } from "../../../../../../../../../../../../ui/input"

export const SendMoneyComponent = ({ formik, currency, activeButton }: any) => {
    return (
        <Box>
            <Box>
                {
                    currency == "KES" && activeButton == "send money" ?
                    <Typography className='mb-2 label'>Phone Number</Typography> : ""
                }
                {currency == "KES" && activeButton == "send money" ?
                    <PhoneInput
                        inputProps={{
                            name: "phone_number",
                            required: true,
                            autoFocus: true,
                        }}
                        country={"ke"}
                        prefix=""
                        value={formik.values.send_money_number}
                        onChange={(value, country, e, formattedValue) => {
                            formik.setFieldValue('send_money_number', value)
                        }} /> : (currency == "XOF" || currency == "XAF" || currency == "RWF") && activeButton == "mtn" ?
                        <PhoneInput
                            inputProps={{
                                name: "phone_number",
                                required: true,
                                autoFocus: true,
                            }}
                            country={"ke"}
                            prefix=""
                            value={formik.values.mtn_number}
                            onChange={(value, country, e, formattedValue) => {
                                formik.setFieldValue('mtn_number', value)
                            }} /> :
                        currency == "XOF" && activeButton == "Moov" ?
                            <PhoneInput
                                inputProps={{
                                    name: "phone_number",
                                    required: true,
                                    autoFocus: true,
                                }}
                                country={"ke"}
                                prefix=""
                                value={formik.values.moov_number}
                                onChange={(value, country, e, formattedValue) => {
                                    formik.setFieldValue('moov_number', value)
                                }} /> :
                            (currency == "XOF" || currency == "XAF" || currency == "CDF") && activeButton == "Orange" ?
                                <PhoneInput
                                    inputProps={{
                                        name: "phone_number",
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    country={"ke"}
                                    prefix=""
                                    value={formik.values.orange_number}
                                    onChange={(value, country, e, formattedValue) => {
                                        formik.setFieldValue('orange_number', value)
                                    }} /> :
                                currency == "CDF" && activeButton == "Free" ?
                                    <PhoneInput
                                        inputProps={{
                                            name: "phone_number",
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        country={"ke"}
                                        prefix=""
                                        value={formik.values.free_number}
                                        onChange={(value, country, e, formattedValue) => {
                                            formik.setFieldValue('free_number', value)
                                        }} /> : (currency == "XOF" || currency == "MWK") && activeButton == "Airtel" ?
                                        <PhoneInput
                                            inputProps={{
                                                name: "phone_number",
                                                required: true,
                                                autoFocus: true,
                                            }}
                                            country={"ke"}
                                            prefix=""
                                            value={formik.values.airtel_number}
                                            onChange={(value, country, e, formattedValue) => {
                                                formik.setFieldValue('airtel_number', value)
                                            }} />
                                        : currency == "XOF" && activeButton == "Vodacom" ?
                                            <PhoneInput
                                                inputProps={{
                                                    name: "phone_number",
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                country={"ke"}
                                                prefix=""
                                                value={formik.values.vodacom_number}
                                                onChange={(value, country, e, formattedValue) => {
                                                    formik.setFieldValue('vodacom_number', value)
                                                }} /> :
                                            currency == "MWK" && activeButton == "tnm" ?
                                                <PhoneInput
                                                    inputProps={{
                                                        name: "phone_number",
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                    country={"ke"}
                                                    prefix=""
                                                    value={formik.values.tnm_number}
                                                    onChange={(value, country, e, formattedValue) => {
                                                        formik.setFieldValue('tnm_number', value)
                                                    }} /> : ""}
            </Box>

            {/* <DefaultBtn text="Add recipient" style={{ width: "100%", marginTop: "30px" }} /> */}
        </Box>
    )
}
export const TillNumberComponent = (formik: any) => {
    console.log(formik, "formikformikformik");

    return (
        <Box>
            <InputNumber
                requiredFild="NotActive" mainDevstyle={{ margin: '0px' }}
                value={formik.formik.values.till_number}
                name="till_number"
                onChangeset={(e: any) => {
                    console.log(e.target.value, "e.target.value");

                    formik.formik.setFieldValue('till_number', e.target.value)
                    e.preventDefault()
                }}
                placeholder="Input till number here"
            />
        </Box>
    )
}
export const PayBilComponent = ({ formik }: any) => {
    return (
        <Box>
            <Box>
                <InputNumber
                    value={formik.values.paybill_number}
                    onChangeset={(e: any) => {
                        formik.setFieldValue('paybill_number', e.target.value)
                    }}
                    placeholder="Input paybill number here"
                />
                <InputNumber
                    value={formik.values.account_number}
                    onChangeset={(e: any) => {
                        formik.setFieldValue('account_number', e.target.value)
                    }}
                    placeholder="Input account number here"
                />
            </Box>
            {/* <DefaultBtn text="Add recipient" style={{ width: "100%", marginTop: "30px" }} /> */}
        </Box>
    )
}