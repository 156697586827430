import { toast } from "react-toastify";
import { PaymentFlowservice } from "../_services";
import { alertActions } from "./alert.actions";
import { paymentFlowaconstants } from "../_constants/paymentFlow.constants";
import { Unauthorized } from "../_constants/ErrorMessage";

export const payablesFlowactions = {
  getApipayment,
  PostPayment,
  getPaymentdelete,
  PostPaymentfull
}
function getApipayment(req: any, ListFun: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payables?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    PaymentFlowservice
      .getApiFlow(req)
      .then((response) => {
     
        return response.json();
      })
      .then(
        (transaction) => {
          if (transaction.success) {
            ListFun(!!transaction.data?transaction.data:transaction)
            dispatch(alertActions.success('Transactions Success'));
          } else {
            ListFun([])
            dispatch(alertActions.error(transaction.message));
          }
        },
        (error) => {
          toast.error(Unauthorized);
          //   dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  //   function request(payables: any) {
  //     return { type: paymentFlowaconstants.TRANSACTION_REQUEST, payables };
  //   }
  //   function success(payables: any) {
  //     return { type: paymentFlowaconstants.TRANSACTION_SUCCESS, payables };
  //   }
  //   function failure(error: any) {
  //     return { type: paymentFlowaconstants.TRANSACTION_FAILURE, error };
  //   }
}
function PostPayment(Link: any, payload: any, path: any,setLoader:any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    setLoader(true)
    PaymentFlowservice
      .PostPayment(Link, payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Payment link deleted successfully"));
            path(response.data)
            setLoader(false)
          } else {
            toast.error(response.message);
            setLoader(false)
          }
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      );
  };
}
function getPaymentdelete(Link: any, path: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    PaymentFlowservice
      .getPaymentdelete(Link)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Payment link deleted successfully"));
            path()
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      );
  };
}

function PostPaymentfull(Link: any, payload: any, path: any,setLoader:any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    setLoader(true)
    PaymentFlowservice
      .PostPaymentfull(Link, payload)
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(alertActions.success("Payment link deleted successfully"));
            path(response)
            setLoader(false)
          } else {
            path(response)
            toast.error(response.message);
            setLoader(false)
          }
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      );
  };
}