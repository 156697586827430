import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { string } from 'yup';
import ExpressPayment from './ExpressPayment/ExpressPayment';
import BulkPayment from './BulkPayment/BulkPayment';
import BillPayment from './BillPayment/BillPayment';
import styled from '@emotion/styled';
import { StepList } from './ExpressPayment/setpList';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 6 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ SelectBlence,stepupdatefun, Step, isAddPayeeComponent, setIsAddPayeeComponent, SelectFleg, SelectCurrency }: any) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;

  console.log(selectedUser, 'selectedUser');

  return (
    <Box sx={{ width: "100%" }}>
      {Step == 1 || Step == 2 || Step == 3 || isAddPayeeComponent == true ? "" :
        <AppBar
          style={{ boxShadow: "none" }}
          position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#025041",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#025041",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontFamily: "Poppins"
              },
            }}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            style={{
              backgroundColor: '#ffffff',
              color: '#000',
              borderBottom: "1px solid #DDDDDD",
              boxShadow: "none",
              fontFamily: "Poppins"
            }}
          >
            <Tab label="Express payment" style={{ fontSize: "13px" }} {...a11yProps(0)} />
            <Tab label="Bulk payment" style={{ fontSize: "13px" }} {...a11yProps(1)} />
            <Tab label="Bill payment" style={{ fontSize: "13px" }} {...a11yProps(2)} />
          </Tabs>
        </AppBar>}
      <StyledTabPanel value={value} index={0} dir={theme.direction}>
        <StepList SelectBlence={SelectBlence} setSelectedUser={setSelectedUser} selectedUser={selectedUser} SelectCurrency={SelectCurrency} SelectFleg={SelectFleg} setIsAddPayeeComponent={setIsAddPayeeComponent} isAddPayeeComponent={isAddPayeeComponent} stepupdatefun={stepupdatefun} Step={Step} />
      </StyledTabPanel>
      <StyledTabPanel value={value} index={1} dir={theme.direction}>
        <BulkPayment />
      </StyledTabPanel>
      <StyledTabPanel value={value} index={2} dir={theme.direction}>
        <BillPayment />
      </StyledTabPanel>
    </Box>
  );
}

