import { Box, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomInput } from '../../../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import { DefaultBtn } from '../../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { TheSelectBox } from '../../../../../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import "./bankaccount.scss"
import { AddCircleIcon } from '../../../../../../../../../../../ui/ReuseableComponents/Icons/Icons'
import { LightIconAndTextButton } from '../../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { InputNumber } from '../../../../../../../../../../../ui/input'


const BankAccount = ({ bankData, bankPaymentMethoad, currency , bankTransferMethods }: any) => {
    const [selectedBank, setSelectedBank] = useState(null);
    const [activeButton, setActiveButton] = useState(bankTransferMethods[0]?.key);

    const [addNewPaymentdisabled, setAddNewPaymentDisabled] = useState(true);
    const handleBankChange = (event: any) => {
        setSelectedBank(event.target.value);
    };
    console.log(activeButton,"activeButton");
    
    const renderComponent = () => {
        switch (activeButton) {
            case 'pesalink':
                return <BankTabe />;
            case 'rtgs':
                return <BankTabe />;
            case 'eft':
                return <BankTabe />;
            case 'Swift':
                return <Swiftteb />
                case 'swift':
                    return <Swiftteb />
            default:
                return null;
        }
    };
    const BankTabe = () => {

        return (
            <Box className="d-flex flex-column gap-3">
                <Box>
                    <Typography className='mb-2 label'>Bank Name</Typography>
                    <TheSelectBox
                        value={selectedBank}
                        options={bankData.map((bank: any) => bank.bank_name)}
                        defaultOption="--Select recipient bank--"
                        onChange={handleBankChange}
                    />
                </Box>
                <Box>
                    <Typography className='label'>Bank branch</Typography>
                    <CustomInput placeholder="Enter bank branch" />
                </Box>
                <Box>
                    <Typography className='label'>Account name</Typography>
                    <CustomInput placeholder="Enter account name" />
                </Box>
                <Box>
                    <Typography className='label'>Account Number</Typography>
                    <CustomInput placeholder="Enter account number" />
                </Box>
                <LightIconAndTextButton disabled={addNewPaymentdisabled} icon={<AddCircleIcon style={{ width: "20px" }} />} text="Save & add another payment detail" />

            </Box>
        )
    }
    const Swiftteb = () => {
        return (
            <>
                <Box className="d-flex flex-column gap-3">
                    <InputNumber mainDevstyle={{ margin: '0px' }} label="Account name" name="account_name" />
                    <InputNumber mainDevstyle={{ margin: '0px' }} label="Account number / IBAN" name="account_name" />
                    <InputNumber mainDevstyle={{ margin: '0px' }} label="Beneficiary bank name" name="account_name" />
                    <InputNumber mainDevstyle={{ margin: '0px' }} label="Beneficiary bank SWIFT code" name="account_name" />
                    <InputNumber mainDevstyle={{ margin: '0px' }} label="Beneficiary bank address" name="account_name" />
                    <InputNumber requiredFild="NotActive" mainDevstyle={{ margin: '0px' }} label="Correspondent bank name" name="account_name" />
                    <InputNumber requiredFild="NotActive" mainDevstyle={{ margin: '0px' }} label="Correspondent bank SWIFT code" name="account_name" />

                </Box>
            </>
        )
    }
    
    const handleButtonClick = (buttonName: any) => {
        setActiveButton(buttonName);
    };
    
    return (

        <Box>
            <Box className="d-flex justify-content-center align-items-center gap-2 mb-5">
                {bankTransferMethods.map((item: any, index: number) => {

                    return (
                        <>
                            {/* {item.key == "pesalink" ? "" : */}
                            <Button
                                // className={`mpesa_btn ${activeButton === item.key ? 'active-btn' : 'inactive-btn'}`}
                                className={`mpesa_btn ${activeButton === item.key ? 'active-btn' : index === 0 && !bankTransferMethods?.find((c: any) => c.key === activeButton) ? "active-btn" : ""}`}
                                onClick={() => handleButtonClick(item.key)}
                            >
                                {item.title}
                            </Button>
                        </>
                    )
                })}
            </Box>
            {renderComponent()}
        </Box>

    )
}

export default BankAccount