
export const DownloadIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 11.4412V12.9706C1.5 13.3762 1.65804 13.7652 1.93934 14.052C2.22064 14.3389 2.60218 14.5 3 14.5H12C12.3978 14.5 12.7794 14.3389 13.0607 14.052C13.342 13.7652 13.5 13.3762 13.5 12.9706V11.4412M3.75 6.85294L7.5 10.6765M7.5 10.6765L11.25 6.85294M7.5 10.6765V1.5" stroke="#025041" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export const UplaodIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 11.4412V12.9706C1.5 13.3762 1.65804 13.7652 1.93934 14.052C2.22064 14.3389 2.60218 14.5 3 14.5H12C12.3978 14.5 12.7794 14.3389 13.0607 14.052C13.342 13.7652 13.5 13.3762 13.5 12.9706V11.4412M3.75 6.85294L7.5 10.6765M7.5 10.6765L11.25 6.85294M7.5 10.6765V1.5" stroke="#025041" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export const BankIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 5.36426V10.3643M2.75 5.36426V10.3643M1.25 5.36426V10.3643M9.25 5.36426V10.3643M1 11.3643H11M0 12.6143H12M6 6.36426H5.5C5.36739 6.36426 5.24021 6.41694 5.14645 6.5107C5.05268 6.60447 5 6.73165 5 6.86426V7.05176C5 7.15994 5.03509 7.26521 5.1 7.35176C5.16491 7.4383 5.25614 7.50146 5.36 7.53176L6.64 7.90526C6.74386 7.93555 6.83509 7.99871 6.9 8.08526C6.96491 8.1718 7 8.27707 7 8.38526V8.86426C7 8.99686 6.94732 9.12404 6.85355 9.21781C6.75979 9.31158 6.63261 9.36426 6.5 9.36426H6M6 6.36426H6.5C6.63261 6.36426 6.75979 6.41694 6.85355 6.5107C6.94732 6.60447 7 6.73165 7 6.86426V7.11426M6 6.36426V5.36426M6 9.36426H5.5C5.36739 9.36426 5.24021 9.31158 5.14645 9.21781C5.05268 9.12404 5 8.99686 5 8.86426V8.61426M6 9.36426V10.3643M11.75 3.98926V4.36426H0.25V3.98926C2.75 3.11426 4.25 2.36426 5.875 1.11426H6.125C7.75 2.36426 9.25 3.11426 11.75 3.98926Z" stroke="#025041" stroke-width="0.7" />
        </svg>
    )
}
export const PesaIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.83952 5.34436H7.43961C7.21683 5.34436 7.00317 5.43286 6.84564 5.59039C6.68811 5.74792 6.59961 5.96158 6.59961 6.18436C6.59961 6.40714 6.68811 6.6208 6.84564 6.77833C7.00317 6.93586 7.21683 7.02436 7.43961 7.02436H7.99952C8.2223 7.02436 8.43596 7.11286 8.59349 7.27039C8.75102 7.42792 8.83952 7.64158 8.83952 7.86436C8.83952 8.08714 8.75102 8.3008 8.59349 8.45833C8.43596 8.61586 8.2223 8.70436 7.99952 8.70436H6.59961M7.7197 9.26427V8.70436M7.7197 5.34436V4.78418" stroke="#025041" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.04 10.9443H1.55991C1.41141 10.9443 1.269 10.8853 1.16399 10.7803C1.05899 10.6753 1 10.5328 1 10.3843V1.42417C1 1.27567 1.05899 1.13326 1.16399 1.02825C1.269 0.923248 1.41141 0.864258 1.55991 0.864258H7.15982C7.23337 0.864223 7.30621 0.878679 7.37417 0.906801C7.44213 0.934924 7.50389 0.976161 7.55591 1.02816C7.60793 1.08015 7.6492 1.14189 7.67735 1.20984C7.70551 1.27779 7.72 1.35062 7.72 1.42417V2.54426" stroke="#025041" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.48014 0.864258H3.24023L3.52032 1.98435H5.20032L5.48014 0.864258Z" stroke="#025041" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export const UserIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 14V13.2C0 12.6333 0.146 12.1127 0.438 11.638C0.73 11.1633 1.11733 10.8007 1.6 10.55C2.63333 10.0333 3.68333 9.646 4.75 9.388C5.81667 9.13 6.9 9.00067 8 9C9.1 8.99933 10.1833 9.12867 11.25 9.388C12.3167 9.64733 13.3667 10.0347 14.4 10.55C14.8833 10.8 15.271 11.1627 15.563 11.638C15.855 12.1133 16.0007 12.634 16 13.2V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14Z" fill="#025041" />
        </svg>
    )
}
export const AddUserIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 15.4997V14.6663C0.5 12.8247 1.99167 11.333 3.83333 11.333H7.16667C9.00833 11.333 10.5 12.8247 10.5 14.6663V15.4997" stroke="#025041" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.5 8.83301C4.11667 8.83301 3 7.71634 3 6.33301C3 4.94967 4.11667 3.83301 5.5 3.83301C6.88333 3.83301 8 4.94967 8 6.33301C8 7.71634 6.88333 8.83301 5.5 8.83301Z" stroke="#025041" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.5 3H15.5" stroke="#025041" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 0.5V5.5" stroke="#025041" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
export const ArrowForwardIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33398 9.66634L5.50065 5.49967L1.33398 1.33301" stroke="#1D1D1D" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export const AddCircleIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15C4.14 15 1 11.86 1 8C1 4.14 4.14 1 8 1C11.86 1 15 4.14 15 8C15 11.86 11.86 15 8 15ZM8 2C4.69 2 2 4.69 2 8C2 11.31 4.69 14 8 14C11.31 14 14 11.31 14 8C14 4.69 11.31 2 8 2Z" fill="#025041" />
            <path d="M8 11.5C7.72 11.5 7.5 11.28 7.5 11V5C7.5 4.72 7.72 4.5 8 4.5C8.28 4.5 8.5 4.72 8.5 5V11C8.5 11.28 8.28 11.5 8 11.5Z" fill="#025041" />
            <path d="M11 8.5H5C4.72 8.5 4.5 8.28 4.5 8C4.5 7.72 4.72 7.5 5 7.5H11C11.28 7.5 11.5 7.72 11.5 8C11.5 8.28 11.28 8.5 11 8.5Z" fill="#025041" />
        </svg>

    )
}

export const BinIcon: any = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 3.33301C13.5101 3.33301 13.6797 3.40325 13.8047 3.52827C13.9298 3.65329 14 3.82286 14 3.99967C14 4.17649 13.9298 4.34605 13.8047 4.47108C13.6797 4.5961 13.5101 4.66634 13.3333 4.66634H12.6667L12.6647 4.71367L12.0427 13.4277C12.0187 13.7641 11.8682 14.0789 11.6214 14.3087C11.3746 14.5386 11.0499 14.6663 10.7127 14.6663H5.28667C4.94943 14.6663 4.62471 14.5386 4.37792 14.3087C4.13114 14.0789 3.98061 13.7641 3.95667 13.4277L3.33467 4.71434L3.33333 4.66634H2.66667C2.48986 4.66634 2.32029 4.5961 2.19526 4.47108C2.07024 4.34605 2 4.17649 2 3.99967C2 3.82286 2.07024 3.65329 2.19526 3.52827C2.32029 3.40325 2.48986 3.33301 2.66667 3.33301H13.3333ZM11.3313 4.66634H4.66867L5.28733 13.333H10.7127L11.3313 4.66634ZM9.33333 1.33301C9.51014 1.33301 9.67971 1.40325 9.80474 1.52827C9.92976 1.65329 10 1.82286 10 1.99967C10 2.17649 9.92976 2.34605 9.80474 2.47108C9.67971 2.5961 9.51014 2.66634 9.33333 2.66634H6.66667C6.48986 2.66634 6.32029 2.5961 6.19526 2.47108C6.07024 2.34605 6 2.17649 6 1.99967C6 1.82286 6.07024 1.65329 6.19526 1.52827C6.32029 1.40325 6.48986 1.33301 6.66667 1.33301H9.33333Z" fill="#FF0000" />
        </svg>


    )
}

export const UploadIcon = ({ style }: any) => (
    <svg
        style={style}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.67773 14V16C1.67773 16.5304 1.88845 17.0391 2.26352 17.4142C2.63859 17.7893 3.1473 18 3.67773 18H15.6777C16.2082 18 16.7169 17.7893 17.0919 17.4142C17.467 17.0391 17.6777 16.5304 17.6777 16V14M4.67773 6L9.67773 1L14.6777 6M9.67773 1V13"
            stroke="#B8B8B8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);



export const SuccessIcon = ({ style }: any) => {
    return (
        <svg style={style} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z" fill="#025041" />
        </svg>
    )
}






















