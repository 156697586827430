import { Formik } from "formik"
import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import * as Yup from "yup";
import ExpressPayment from "./ExpressPayment";


export const StepList = ({ SelectBlence, stepupdatefun, Step, setSelectedUser, selectedUser, setIsAddPayeeComponent, isAddPayeeComponent, SelectFleg, SelectCurrency }: any) => {
    const [fildForm, setFildFrom] = useState({
        counterpart: {},
        currency: "KES",
        invoice_record: "",
        vat: "",
        amount: 0,
        description: "",
        tags: [],
        teams: [],
        type: '',
        country_id: "",
        verified_account_name: "",
        Fees: '',
        balance: "",
        payment_channel:"",
        payment_method:""
    })
    const[errorsMsg,setErrorMsg]=useState('')
    console.log(errorsMsg,"errorsMsgerrorsMsg");
    
    const validationSchema = Yup.object().shape({
       
        counterpart: Yup.object()
            .required("Please select the Counterpart."),
        currency: Yup.string()
            .required("Please select the Currency."),
        amount: Yup.number()
            .min(1, "Please enter the amount.")
            // .max(1000000, "The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.Please attach a supporting document for the transaction.")
            .required("Please enter the Amount."),

        tags: Yup.array()
            .when(".length", {
                is: 0,
                then: (s) => s.strip(),
            })
            .required("Please select the tags."),
        type: Yup.string()
            .required("Please select the Type."),
        country_id: Yup.string()
            .required("Please select the Country Id."),
        // country_id: Yup.array()
        // .required("Please select the Country Id."),
    });

    return (
        <>
            <Formik
                // innerRef={formdata}
                initialValues={fildForm}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (values.currency == "USD" && values.amount <= 100) {

                    }
                }}
            >
                {(formik) => (


                    <>
                        {console.log(formik, formik.values, formik.errors, "formikformik")}
                        <form onSubmit={formik.handleSubmit} >
                            <ExpressPayment errorsMsg={errorsMsg} setErrorMsg={setErrorMsg} SelectBlence={SelectBlence} formik={formik} stepupdatefun={stepupdatefun} Step={Step} setSelectedUser={setSelectedUser} selectedUser={selectedUser} setIsAddPayeeComponent={setIsAddPayeeComponent} isAddPayeeComponent={isAddPayeeComponent} SelectFleg={SelectFleg} SelectCurrency={SelectCurrency} />
                        </form>
                    </>)}
            </Formik>
        </>
    )
}