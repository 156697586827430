import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import './Dashboard.scss';
import { Link, useNavigate } from 'react-router-dom';
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from '../../../../_helpers';
import { selectAllData } from '../../../../_reducers/dashboard.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../../_actions';
import { settingActions } from '../../../../_actions/setting.actions';
import { settingSelector } from '../../../../_reducers/setting.reducer';
import Charts from './charts/charts';
import DatePickerll from 'react-datepicker';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { TopupModal } from '../Balance/components/topup-modal/Topup';
import TopupDetail from '../Balance/components/TopupDetail/TopupDetail';
import { TopupModal_two } from '../Balance/components/topup-modal/Topup_two';
import { PayableGrid } from '../Balance/components/PayableGrid/PayableGrid';
import { Virefily_account } from '../Balance/components/TransactionDetails/virefiy_account';
import Topup_balk_data from '../Balance/components/topup-modal/csv_balk';
import { Button, Form, Modal, InputGroup, Table } from 'react-bootstrap';
import Topup_fasttime from './components/Topup_fasttime';
import amplitude from 'amplitude-js';
import axios from 'axios';
import { Box, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Chart_bar from './chart_bar/chart_bar';
import Total_Balance from './components/Total_Balance/Total_Balance';
import Recent_Transactions from './components/Recent_Transactions/Recent_Transactions';
import Expense_split from './components/Expense_split/Expense_split';
import Donut_chart from './gradient_chart/donut_chart';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Dashboard_nodata from './components/dashboard_nodata/dashboard_nodata';
import Menu from '@mui/material/Menu';
import Pending_Transactions_popup from './components/Pending_Transactions_popup/Pending_Transactions_popup';
import SelectMethod from '../Balance/components/topup-modal/selectMethod';
import Select_source_account from '../Balance/components/Select_source_account';
import posthog from 'posthog-js';
import Inflows from './method/Inflows';
import Outflows from './method/Outflows';
import NewCharts from './charts/newCharts';
import MyAccounts from './components/MyAccounts_sec/MyAccounts';
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import { selectPaymentJourneyData } from '../../../../_reducers/paymentJourney.reducer';
import { SkeletonCom, SkeletonDashboardV3 } from '../Skeleton/Skeleton';
import WhatsNewModal from './whats_new_popup';
import WhatsNewBadge from './Badge';
import FeedbackModal from '../Menu/Model/FeedbackModal';
import CloseIcon from '@mui/icons-material/Close';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});
const FXrateIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <path
      d="M16.2398 12.9999C15.8203 12.6483 15.287 12.4616 14.7398 12.4749H11.2223C11.0619 12.479 10.9024 12.4506 10.7532 12.3916C10.604 12.3325 10.4683 12.244 10.3542 12.1312C10.2401 12.0185 10.1498 11.8839 10.0889 11.7354C10.028 11.587 9.99773 11.4278 9.9998 11.2674C9.99589 11.205 9.99589 11.1424 9.9998 11.0799C10.0471 10.7933 10.1974 10.5338 10.4225 10.3501C10.6476 10.1664 10.932 10.0712 11.2223 10.0824H14.6873C14.9699 10.0822 15.2435 10.1823 15.4592 10.365C15.6748 10.5477 15.8186 10.8011 15.8648 11.0799H16.9898C16.9427 10.5016 16.6797 9.96222 16.2531 9.56897C15.8265 9.17571 15.2675 8.95739 14.6873 8.95742H13.5023V7.23242H12.5648V8.95742H11.2223C10.6421 8.95739 10.0831 9.17571 9.6565 9.56897C9.22989 9.96222 8.96689 10.5016 8.9198 11.0799C8.91606 11.1424 8.91606 11.205 8.9198 11.2674C8.9198 11.5698 8.97935 11.8692 9.09507 12.1486C9.21078 12.4279 9.38038 12.6817 9.59418 12.8955C10.026 13.3273 10.6116 13.5699 11.2223 13.5699H14.7773C14.9364 13.5659 15.0947 13.5938 15.2429 13.6519C15.391 13.7101 15.5261 13.7973 15.64 13.9084C15.7539 14.0195 15.8444 14.1524 15.9062 14.299C15.968 14.4457 15.9998 14.6033 15.9998 14.7624C16.0076 14.8472 16.0076 14.9326 15.9998 15.0174C15.9402 15.2813 15.7927 15.5171 15.5815 15.6861C15.3703 15.8551 15.1078 15.9473 14.8373 15.9474H11.3123C11.0418 15.9473 10.7793 15.8551 10.5681 15.6861C10.3569 15.5171 10.2093 15.2813 10.1498 15.0174H9.0173C9.08175 15.5809 9.35127 16.101 9.77451 16.4786C10.1977 16.8562 10.7451 17.0649 11.3123 17.0649H12.5648V18.7674H13.5023V17.0649H14.7773C15.3445 17.0649 15.8919 16.8562 16.3151 16.4786C16.7383 16.101 17.0078 15.5809 17.0723 15.0174V14.7624C17.0714 14.4255 16.9963 14.093 16.8525 13.7884C16.7086 13.4838 16.4994 13.2146 16.2398 12.9999Z"
      fill="#025041"
    />
  </svg>
);
const UpadetIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <g clip-path="url(#clip0_10807_20781)">
      <path
        d="M15.5 17.5V18C15.6326 18 15.7598 17.9473 15.8536 17.8536C15.9473 17.7598 16 17.6326 16 17.5H15.5ZM11.5 17.5H11C11 17.6326 11.0527 17.7598 11.1464 17.8536C11.2402 17.9473 11.3674 18 11.5 18V17.5ZM12 20H15V19H12V20ZM9.58 11.4L9.57 11.47L10.56 11.61L10.571 11.54L9.58 11.4ZM13.5 8C12.5467 7.99984 11.6254 8.34364 10.9053 8.96823C10.1852 9.59283 9.7146 10.4563 9.58 11.4L10.571 11.54C10.6719 10.8349 11.0227 10.1898 11.561 9.72317C12.0992 9.25658 12.7877 8.9998 13.5 9V8ZM17.42 11.4C17.2854 10.4563 16.8148 9.59283 16.0947 8.96823C15.3746 8.34364 14.4533 7.99984 13.5 8V9C14.2123 9.00001 14.9006 9.25686 15.4388 9.72341C15.977 10.19 16.3289 10.8349 16.43 11.54L17.42 11.4ZM17.43 11.47L17.42 11.4L16.43 11.54L16.439 11.61L17.43 11.47ZM16.64 14.459C17.27 13.645 17.588 12.585 17.43 11.47L16.44 11.611C16.4973 12.0042 16.4745 12.405 16.3731 12.7892C16.2718 13.1734 16.0938 13.5332 15.85 13.847L16.64 14.459ZM15 15.9V17.5H16V15.901L15 15.9ZM15.5 17H11.5V18H15.5V17ZM12 17.5V15.901H11V17.5H12ZM9.57 11.47C9.49398 11.9957 9.52477 12.5314 9.6605 13.045C9.79624 13.5585 10.0341 14.0395 10.36 14.459L11.151 13.847C10.9072 13.5332 10.7282 13.1734 10.6269 12.7892C10.5255 12.405 10.5027 12.0032 10.56 11.61L9.57 11.47ZM12 15.9C12 15.077 11.562 14.377 11.15 13.846L10.36 14.459C10.743 14.954 11 15.426 11 15.9H12ZM15.85 13.846C15.437 14.378 15 15.077 15 15.9H16C16 15.426 16.257 14.954 16.64 14.459L15.85 13.846ZM13 5V7H14V5H13ZM6 13H8V12H6V13ZM19 13H21V12H19V13ZM9.354 8.646L7.854 7.146L7.146 7.854L8.646 9.354L9.354 8.646ZM18.354 9.354L19.854 7.854L19.146 7.146L17.646 8.646L18.354 9.354Z"
        fill="#025041"
      />
    </g>
    <defs>
      <clipPath id="clip0_10807_20781">
        <rect width="15" height="15" fill="white" transform="translate(6 5)" />
      </clipPath>
    </defs>
  </svg>
);
const ComingSoonIcon = () => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <path
      d="M13.6123 5.6L9.84615 1.928V3.896L9.14051 4C5.6041 4.488 3.2082 6.296 1.83795 9.064C3.74154 7.752 6.10462 7.12 9.02564 7.12H9.84615V9.272M8.20513 7.936C4.53744 8.104 1.91179 9.392 0 12C0.820513 8 3.28205 4 9.02564 3.2V0L14.7692 5.6L9.02564 11.2V7.92C8.75487 7.92 8.4841 7.928 8.20513 7.936Z"
      fill="#025041"
    />
  </svg>
);
const LoyaltyRewardsIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <path
      d="M14.781 15.687L15.9999 18.1245L17.2374 16.8555L19.125 16.8744L17.3688 13.3869V13.3809M12.219 15.687L11.0001 18.1245L9.7626 16.8555L7.875 16.8744L9.6312 13.3869V13.3809"
      stroke="#025041"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0502 11.2498C18.0502 11.725 17.6314 12.1249 17.4937 12.55C17.3563 12.9748 17.4502 13.5562 17.1814 13.9249C16.9126 14.2936 16.3375 14.3749 16 14.65C15.6625 14.9251 15.3751 15.4375 14.9377 15.5812C14.5 15.7249 14.0062 15.4498 13.5313 15.4498C13.0564 15.4498 12.5503 15.7186 12.1252 15.5812C11.7001 15.4435 11.4313 14.9122 11.0626 14.65C10.6936 14.3875 10.1188 14.2936 9.84995 13.9249C9.58115 13.5559 9.68135 12.9874 9.53765 12.55C9.39365 12.1123 8.94995 11.725 8.94995 11.2498C8.94995 10.7746 9.36875 10.375 9.50615 9.94986C9.64355 9.52476 9.54995 8.94366 9.81875 8.57496C10.0876 8.20626 10.6627 8.12496 11.0314 7.84986C11.4001 7.57476 11.6251 7.06236 12.0937 6.91866C12.5626 6.77466 13.0252 7.04976 13.5001 7.04976C13.975 7.04976 14.4811 6.78096 14.9065 6.91866C15.3313 7.05606 15.6001 7.58736 16 7.84986C16.3999 8.11236 16.9438 8.20596 17.2126 8.57496C17.4814 8.94396 17.3815 9.51246 17.5252 9.94986C17.6689 10.3873 18.0502 10.7749 18.0502 11.2498ZM13.5001 9.37476C13.0027 9.37476 12.5258 9.57232 12.1741 9.92398C11.8225 10.2756 11.6249 10.7526 11.6249 11.2499C11.6249 11.7472 11.8225 12.2242 12.1741 12.5758C12.5258 12.9275 13.0027 13.1251 13.5001 13.1251C13.9973 13.1251 14.4742 12.9275 14.8259 12.5759C15.1775 12.2243 15.3751 11.7473 15.3751 11.2501C15.3751 10.7528 15.1775 10.2759 14.8259 9.92424C14.4742 9.5726 13.9973 9.37476 13.5001 9.37476Z"
      stroke="#025041"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
interface Update {
  title: string;
  summary: string;
  content: string;
  image_link: string;
}
export const DashboardV3 = () => {
  const [value, onChange] = useState(new Date());
  const navigate = useNavigate();
  const sm = moment().subtract(1, 'M').format('YYYY-MM-DD HH:mm:ss');
  const [startDate, setStartDate] = useState(new Date(sm));
  const fm = moment(startDate).add(1, 'M').format('YYYY-MM-DD HH:mm:ss');
  const [endDate, setEndDate] = useState(new Date(fm));
  const [total_payouts, settotal_payouts] = useState<any[]>([]);
  const [bills, setbills] = useState([]);
  const [deposits, setdeposits] = useState([]);
  const [chartdates, setchartdates] = useState<string[]>([]);
  const [showTopup, setShowTopup] = useState(false);
  const [topup_balk_data, setTopup_balk_data] = React.useState(false);
  const [show_fast, setShow_fast] = React.useState(false);
  const [popupList, setPopupLine] = useState(true);
  const [selectMethod, setSelectMethod] = useState(false);
  const [chart_data, setChart_data] = useState<any>([]);
  const [chart_payment, setChart_payment] = useState<any>([]);
  const [chart_payment_name, setChart_payment_name] = useState<any>([]);
  const [payment_nodata, setPayment_nodata] = useState('');
  const [montheFilter, setMontheFilter] = useState('Monthly');
  const [selectInflow, setSelectInflow] = useState('Monthly');
  const [selectOutflow, setSelectOutFlow] = useState('Monthly');
  const [ChartCurrncy, setChartCurrncy] = useState('');

  const categories_data = ['', '', ''];
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const user = getUser()?.data ?? null;
  //   useEffect(() => {
  //     if (!user && !isUserAuthenticated()) {
  //       navigate('/login');
  //     } else if (user && isUserAuthenticated()) {
  //       navigate(user.hasEntity ? '/dashboard' : '/entity');
  //     }
  //   }, []);
  const [balanceRender, setBalanceRender] = useState(false);
  const [billRender, setBillRender] = useState(true);
  const [ExpensesRender, setExpensesRender] = useState(true);

  const [budgetRender, setBedgetRender] = useState(true);
  const [paymentRender, setPaymentRender] = useState(false);
  const [updateRender, setUpdateRender] = useState(true);
  const [update, setUpdate] = useState<Update | null>(null);
  const [updatebill, setUpdatebill] = useState({
    pending: '0',
    approved: '0',
  });

  const [RecentRender, setRecentRender] = useState(true);
  const [balance, setBalance] = useState<any>();
  const [bill, setBill] = useState<any>();
  const [bill_nodata, setBill_nodata] = useState<any>();

  const [CategoryExpenses, setCategoryExpenses] = useState<any>();
  const [CategoryExpenses_nodata, setCategoryExpenses_nodata] =
    useState<any>('');

  const [PayeeExpenses, setPayeeExpenses] = useState<any>();

  const [recent, setRecent] = useState<any>();
  const [recent_nodata, setRecent_nodata] = useState<any>('');
  const [SelectCurrency, setSelectCurrency] = useState('');
  const [render, setRender] = useState(true);
  const [render_user, setRender_user] = useState(true);
  const [resetChart, setresetChart] = useState(false);
  const [onloadChart, setonloadChart] = useState(true);
  const [chart, setChart] = useState<any>();
  const [chartrender, setChartrender] = useState(true);
  const [userId, setUserId] = useState(true);
  const [userRender, setUserRender] = useState(true);
  const [userDetail, setUserDetail] = useState<any>();
  const [showBalanceGrid, setShowBalanceGrid] = useState(true);
  const [topUpMethod, setTopupMethod] = useState(false);
  const [showTopup_two, setShowTopup_two] = useState(false);
  const [topUp, setTopup] = useState(false);
  const [paymentGrid, setPaymentGrid] = useState(false);
  const [verefire_data, setVirefay_data] = useState(false);
  const [select_data, setSelect_data] = useState('');
  const [counterpartsList_two, setCounterpartsList_two] = useState<any>();
  const [montheone, setMontheone] = useState('Monthly');
  const [monthetwo, setMonthetwo] = useState('Monthly');
  const [budget_nodata, setBudget_nodata] = useState('');
  const [payees_nodata, setPayee_nodata] = useState('');
  const [currencies, setCurrencies] = useState<any>([]);
  const [getWalletapiData, setgetWalletapiData] = useState<any>({});
  const [currencySelect, setCurrencySelect] = React.useState(false);
  const [paymentMethodPayinsName, setPaymentMethodPayinsName] = useState([]);
  const [paymentMethodPayinsdata, setPaymentMethodPayinsdata] = useState([]);
  const [data_saving, setData_saving] = useState<any>();
  const [payeeExpensesRender, setPayeeExpensesRender] = useState(true);
  const [monthe, setMonthe] = useState('Monthly');
  const [monthe_payees, setMonthe_payees] = useState('Monthly');
  const [payins, setPayins] = useState<any>();
  const [countryList, setCountrtList] = useState([]);
  const [startgetapi, setStartgetapi] = useState(true);
  const [RenderData, setRenderData] = useState(true);
  const [monthe_LIST, setMonthe_list] = useState([
    { name: 'This Week', values: 'Weekly' },
    { name: 'This Month', values: 'Monthly' },
    { name: 'This Year', values: 'Yearly' },
  ]);
  const userData = useSelector((state) => settingSelector(state));
  const data = useSelector((state) => selectAllData(state));
  const dispatch = useDispatch();

  const properties = getProperties();
  const [paymentMethodPayinsRedusar, setPaymentMethodPayinsRedusar] =
    useState(false);
  const [balanceRenderList, setBalanceRenderList] = useState(true);
  const [payinsRedusar, setPayinsRedusar] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectCurrencyPopup, setSelectCurrencyPopup] = useState<any>('KES');
  // const [countryList, setCountrtList] = useState([]);
  // const [startgetapi, setStartgetapi] = useState(true);
  const [apistart, setApistart] = useState(true);
  const [Payinsloader, setPayinsloader] = useState(false);
  const [Billsloader, setBillsloader] = useState(false);
  const [ChartDataloader, setChartDataloader] = useState(false);
  const [Updatedetailsloader, setUpdatedetailsloader] = useState(false);
  const [Recentloader, setRecentloader] = useState(false);
  const [PaymentMethodPayinsloader, setPaymentMethodPayinsloader] =
    useState(false);
  const [PaymentExpensesloader, setPaymentExpensesloader] = useState(false);
  const [Balanceloader, setBalanceloader] = useState(false);
  const [latestFeatures, setLatestFeatures] = useState([]);
  const [whatsNewTitle, setWhatsNewTitle] = useState('');
  const [whatsNewBody, setWhatsNewBody] = useState('');
  const [whatsNewImage, setWhatsNewImage] = useState('');
  const [PaymentPaymentMethodnodata, setPaymentPaymentMethodnodata] =
    useState('');
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const [currencyAndMethodData, setCurrencyAndMethodData] = useState('');
  const [showNewModal, setShowNewModal] = useState(false);
  const [showNewModalRender, setShowNewModalRender] = useState(false);
  // get dashboard-currecy api
  useEffect(() => {
    dispatch(dashboardActions.getDashboardCurrencyData() as any);
    dispatch(dashboardActions.getBankDetailData('USD') as any);
  }, [dispatch]);

  useEffect(() => {
    if (data && data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
      setCurrencyAndMethodData(data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
    }
  }, [data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS]);

 

  // const[apistart,setApistart]=useState(true)
  const open = Boolean(anchorEl);
  var today: any = new Date();
  // const dataList = useSelector((state) => selectPaymentJourneyData(state));


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose_popup = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!user && userId) {
      setUserId(false);
      if (!!user.user_id) {
        const bodyReq = {
          id: user.user_id,
        };
        dispatch(settingActions.getUserDetail(bodyReq, 'no') as any);
      }
    }
  }, [userId, user]);
  useEffect(() => {
    if (apistart) {
      setApistart(false);
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
    }
  }, [apistart]);
  useEffect(() => {
    if (
      updateRender &&
      data?.GET_DASHBOARD_UPDATEDETAILS_SUCCESS !== undefined
    ) {
      setUpdateRender(false);
      setgetWalletapiData(data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS);
      if (!!data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.updates) {
        setUpdate(data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.updates);
      }

      if (!!data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.bills) {
        const updatedata = data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.bills;
        setUpdatebill({
          pending: updatedata.pending,
          approved: updatedata.approved,
        });
      }
      if (!!data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.currencies) {
        const currencies = data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.currencies;
        var currenciesArr: any = [];
        for (let index = 0; index < currencies.length; index++) {
          const element = currencies[index];
          currenciesArr.push(element);
        }
        setCurrencies(currenciesArr);
      }
    } else {
    }
  }, [data, updateRender]);
  React.useEffect(() => {
    if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
      setUserRender(false);
      setUserDetail(userData?.GETUSERDETAIL_SUCCESS);
    } else {
    }
  }, [userData, userRender]);
  useEffect(() => {
    const user_verification: any = localStorage.getItem('user_verification');
    if (popupList) {
      const user_DATA = JSON.parse(user_verification);

      if (
        user_DATA == false &&
        !!user &&
        !!user?.entity?.status &&
        user?.entity?.status == 'VERIFIED'
      ) {
        setShow_fast(true);
        setPopupLine(false);
      } else {
        setShow_fast(false);
        setPopupLine(false);
      }
    }
  }, [popupList]);
  useEffect(() => {
    if (
      render_user &&
      (user?.entity?.status == 'UNVERIFIED' || user?.entity?.status == 'NEW')
    ) {
      window.location.href = '/v2/account-setup';
      setRender_user(false);
    }
  }, [render_user]);

  useEffect(() => {
    if (selectCurrencyPopup) {
      dispatch(
        dashboardActions.getBalance(
          `/wallet/get-balance-with-currency/${selectCurrencyPopup}`,
          setBalanceRender,
          setBalanceloader
        ) as any
      );

      dispatch(
        dashboardActions.getBills(
          'Monthly',
          selectCurrencyPopup,
          setBillRender,
          setBillsloader
        ) as any
      );
      dispatch(
        dashboardActions.getRecent(
          selectCurrencyPopup,
          setRecentRender,
          setRecentloader
        ) as any
      );
      dispatch(
        dashboardActions.getPayins(
          'Monthly',
          selectCurrencyPopup,
          setPayinsRedusar,
          setPayinsloader
        ) as any
      );
      dispatch(
        dashboardActions.getPaymentMethodPayins(
          'Monthly',
          selectCurrencyPopup,
          setPaymentMethodPayinsRedusar,
          setPaymentMethodPayinsloader,
          setPayment_nodata
        ) as any
      );
      dispatch(
        dashboardActions.getPayeeExpenses('Monthly', selectCurrencyPopup) as any
      );
      dispatch(
        dashboardActions.getPaymentExpenses(
          'Monthly',
          selectCurrencyPopup,
          setPaymentRender,
          setPaymentExpensesloader,
          setPaymentPaymentMethodnodata
        ) as any
      );
      dispatch(
        dashboardActions.getChartData(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          selectCurrencyPopup,
          setChartrender,
          setChartDataloader
        ) as any
      );
      dispatch(
        dashboardActions.getUpdatedetails(
          setUpdateRender,
          setUpdatedetailsloader
        ) as any
      );
      // setTimeout(() => {
      //     // setPayinsRedusar(true)
      //     // setBillRender(true);

      //     setPaymentMethodPayinsRedusar(true);
      //     setPaymentRender(true)
      //     // setChartrender(true);
      //     // setRecentRender(true);
      // }, 5000)
      setRender(false);
    }
  }, [render, isUserAuthenticated, selectCurrencyPopup]);
  useEffect(() => {
    if (RenderData) {
      setRenderData(false);
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
    }
  }, [RenderData]);
  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined) {
      setCountrtList(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  useEffect(() => {
    if (payinsRedusar && data?.GET_PAYINS_SUCCESS !== undefined) {
      setPayinsRedusar(false);
      setPayins(data?.GET_PAYINS_SUCCESS);
    }
  }, [data, payinsRedusar]);
  useEffect(() => {
    if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
    } else {
    }
  }, [data, balanceRender]);

  useEffect(() => {
    if (billRender && data?.GET_DASHBOARD_BILL_SUCCESS !== undefined) {
      setBillRender(false);
      setBill(data.GET_DASHBOARD_BILL_SUCCESS);
      setBill_nodata('');
    } else if (billRender && data?.GETDASHBOARD_FAILURE !== undefined) {
      setBill_nodata('NODATA');
      setBillRender(false);
    }
  }, [data, billRender]);

  useEffect(() => {
    if (
      updateRender &&
      data?.GET_DASHBOARD_UPDATEDETAILS_SUCCESS !== undefined
    ) {
      setUpdateRender(false);
      setgetWalletapiData(data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS);
      if (!!data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.updates) {
        setUpdate(data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.updates);
      }
      if (!!data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.bills) {
        const updatedata = data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.bills;
        setUpdatebill({
          pending: updatedata.pending,
          approved: updatedata.approved,
        });
      }
      if (!!data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.currencies) {
        const currencies = data.GET_DASHBOARD_UPDATEDETAILS_SUCCESS.currencies;
        var currenciesArr: any = [];
        for (let index = 0; index < currencies.length; index++) {
          const element = currencies[index];
          currenciesArr.push(element);
        }
        setCurrencies(currenciesArr);
      }
    } else {
    }
  }, [data, updateRender]);

  const getLatestFeature = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-latest-feature`,
        requestOptions
      )
      .then((response) => {
    
        if (response.data.success == true) {
          setWhatsNewTitle(response.data.data.title);
         
          setWhatsNewBody(response.data.data.main_content);
        
          setWhatsNewImage(response.data.data.image_link);
        
        }
      });
  };
  const handleNewClick = async () => {
    getLatestFeature();
  
    handleNewClick2();
  };
  const handleNewClick2 = () => {
    setShowNewModal(true);
  };

  React.useEffect(() => {
    const viewedUpdate: any = localStorage.getItem('viewedUpdate');
 
    if (viewedUpdate || viewedUpdate == undefined) {
    } else {
      if (viewedUpdate == false) {
        setShowNewModal(true);
        localStorage.setItem('viewedUpdate', 'true');
        localStorage.setItem('isNewFeatureAvailable', 'true');
        dispatch(dashboardActions.updateViewedLatestFeature() as any);
      } else {
        localStorage.setItem('isNewFeatureAvailable', 'false');
      }
    }
  }, []);
  const isFeatureAvailable: any = localStorage.getItem('isNewFeatureAvailable');
  const [showBadge, setShowBadge] = useState(false);
  React.useEffect(() => {
   
    if (JSON.parse(isFeatureAvailable) == true) {
      setShowBadge(true);
    }
  }, []);
  useEffect(() => {
    if (
      budgetRender &&
      data?.GET_DASHBOARD_BUDGETDETAILS_SUCCESS !== undefined
    ) {
      setBedgetRender(false);
      const budgetdata = data.GET_DASHBOARD_BUDGETDETAILS_SUCCESS;
      const actual_expense = Number(budgetdata.actual_expense);
      const available_balance = Number(budgetdata.available_balance);
      const total_balance = Number(budgetdata.total_balance);
      if (budgetdata.length == 0) {
        setChart_data([0, 0, 0]);
      } else {
        setData_saving(budgetdata);
        setChart_data([total_balance, actual_expense, available_balance]);
      }
      setBudget_nodata('');
    } else if (
      budgetRender &&
      data?.GET_DASHBOARD_BUDGETDETAILS_FAILURE !== undefined
    ) {
      setBudget_nodata('NODATA');
      setBedgetRender(false);
    }
  }, [data, budgetRender]);
  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined) {
      setCountrtList(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  useEffect(() => {
    if (
      paymentMethodPayinsRedusar &&
      data?.GET_PAYMENT_METHOD_PAYINS_SUCCESS !== undefined
    ) {
      setPaymentMethodPayinsRedusar(false);
      const budgetdata = data.GET_PAYMENT_METHOD_PAYINS_SUCCESS;
      var arrdata: any = [];
      var namearr: any = [];
      for (let index = 0; index < budgetdata.length; index++) {
        const element = budgetdata[index];
        var per = !!element.percentage
          ? Number(parseFloat(element.percentage).toFixed(2)).toLocaleString(
            'en',
            { minimumFractionDigits: 0 }
          )
          : element.percentage ?? '0.00';
        if (!!element.payment_channel) {
          arrdata.push(Number(per));
          var data_add = `${element.payment_channel}${'<br>'}${element.currency
            } ${Number(
              parseFloat(element.total_amount).toFixed(2)
            ).toLocaleString('en', { minimumFractionDigits: 2 })}`;
          namearr.push(data_add);
        }
      }
 

      setPaymentMethodPayinsdata(arrdata);
      setPaymentMethodPayinsName(namearr);
      // setPayment_nodata('');
    }
    // else if (
    //     paymentMethodPayinsRedusar &&
    //     data?.GET_PAYMENT_METHOD_PAYINS_FAILURE !== undefined
    // ) {
    //     setPayment_nodata('NODATA');
    //     setPaymentMethodPayinsRedusar(false);
    // }
  }, [data, paymentMethodPayinsRedusar]);


  useEffect(() => {
  
    // if (
    //     paymentRender &&
    //     data?.GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE == "No record found."
    // ) {
  

    //     setPaymentPaymentMethodnodata('NODATA');
    //     setPaymentRender(false);
    // }
    //  else
    if (
      paymentRender &&
      data?.GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS !== undefined
    ) {
      setPaymentRender(false);
      const budgetdata = data.GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS;
      var arrdata: any = [];
      var namearr: any = [];
      for (let index = 0; index < budgetdata.length; index++) {
        const element = budgetdata[index];
        var per = !!element.percentage
          ? Number(parseFloat(element.percentage).toFixed(2)).toLocaleString(
            'en',
            { minimumFractionDigits: 0 }
          )
          : element.percentage ?? '0.00';
        if (!!element.payment_channel) {
          arrdata.push(Number(per));
          var data_add = `${element.payment_channel}${'<br>'}${element.currency
            } ${Number(
              parseFloat(element.total_amount).toFixed(2)
            ).toLocaleString('en', { minimumFractionDigits: 2 })}`;
          namearr.push(data_add);
          // namearr.push(data_add);
        }
      }
    

      setChart_payment(arrdata);
      setChart_payment_name(namearr);
      // setPaymentPaymentMethodnodata('');
    }
  }, [data, paymentRender]);
  useEffect(() => {
    if (RecentRender && data?.GET_DASHBOARD_RECENT_SUCCESS !== undefined) {
      setRecent(data.GET_DASHBOARD_RECENT_SUCCESS);
      setRecentRender(false);
      setRecent_nodata('');
    } else if (
      RecentRender &&
      data?.GET_DASHBOARD_RECENT_FAILURE !== undefined
    ) {
      setRecent_nodata('NODATA');
      setRecentRender(false);
    }
  }, [data, RecentRender]);
  useEffect(() => {
    if (chartrender && data?.GET_DASHBOARD_CHART_SUCCESS !== undefined) {
      if (onloadChart == true) {
        setChartrender(false);
        setChart(data.GET_DASHBOARD_CHART_SUCCESS);
        setChartCurrncy(data?.GET_DASHBOARD_CHART_SUCCESS.data?.currency);
        settotal_payouts(
          Object.values(data.GET_DASHBOARD_CHART_SUCCESS.data.total_payouts)
        );
        setbills(
          Object.values(data.GET_DASHBOARD_CHART_SUCCESS.data.total_bills)
        );
        setdeposits(
          Object.values(data.GET_DASHBOARD_CHART_SUCCESS.data.total_deposits)
        );
        setchartdates(
          Object.keys(data.GET_DASHBOARD_CHART_SUCCESS.data.total_deposits)
        );
      } else {
        setTimeout(() => {
          setChartrender(false);
          setChart(data.GET_DASHBOARD_CHART_SUCCESS);
          settotal_payouts(
            Object.values(data.GET_DASHBOARD_CHART_SUCCESS.data.total_payouts)
          );
          setbills(
            Object.values(data.GET_DASHBOARD_CHART_SUCCESS.data.total_bills)
          );
          setdeposits(
            Object.values(data.GET_DASHBOARD_CHART_SUCCESS.data.total_deposits)
          );
          setchartdates(
            Object.keys(data.GET_DASHBOARD_CHART_SUCCESS.data.total_deposits)
          );
        }, 3000);
      }
    }
  }, [data, chartrender]);
  // useEffect(() => {
  //     if (
  //         ExpensesRender &&
  //         data?.GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS !== undefined
  //     ) {
  //         setExpensesRender(false);
  //         setCategoryExpenses_nodata('');
  //         setCategoryExpenses(
  //             data?.GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS?.sort(
  //                 (a: any, b: any) => b.total_amount - a.total_amount
  //             )
  //         );
  //     } else if (
  //         ExpensesRender &&
  //         data?.GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE !== undefined
  //     ) {
  //         setCategoryExpenses_nodata('NODATA');
  //         setExpensesRender(false);
  //     }
  // }, [data, ExpensesRender]);
  useEffect(() => {
    if (
      payeeExpensesRender &&
      data?.GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS !== undefined
    ) {
      setPayeeExpensesRender(false);
      setPayeeExpenses(data.GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS);
      setPayee_nodata('');
    } else if (
      payeeExpensesRender &&
      data?.GET_DASHBOARD_PAYEE_EXPENSES_FAILURE !== undefined
    ) {
      setPayee_nodata('NODATA');
      setPayeeExpensesRender(false);
    }
  }, [data, payeeExpensesRender]);
  const Filater = (e: any) => {
    dispatch(
      dashboardActions.getCategoryExpenses(
        e.target.value,
        selectCurrencyPopup
      ) as any
    );
    setTimeout(() => {
      setExpensesRender(true);
    }, 2500);
  };
  const payeeFilater = (e: any) => {
    dispatch(
      dashboardActions.getPayeeExpenses(
        e.target.value,
        selectCurrencyPopup
      ) as any
    );
    setTimeout(() => {
      setPayeeExpensesRender(true);
    }, 3000);
  };
  const [feedbackModal, setFeedbackModal] = React.useState(false);
  const onCloseFeedbackModal = () => {
    setFeedbackModal(false);
  };
  return (
    <>
      {showBalanceGrid && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            className='header_Dashboard'
          >
            <div style={{ padding: "0px 0px 0px 45px" }} className="dashboard-container__header">
              Dashboard
            </div>
          </div>
          <div
            className="dashboard-container"
            style={{ padding: '0px 32px 32px 32px' }}
          >
            <ThemeProvider theme={theme}>
              <Grid
                className="selectContainer"
                container
                // sx={{ paddingTop: '20px' }}
              >
                <Grid
                  item
                  md={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <div
                    className="header"
                    style={{ fontSize: '14px', paddingRight: '15px' }}
                    id="totalBalance_header"
                  >
                    Select currency{' '}
                  </div>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="selectBox"
                    value={selectCurrencyPopup}
                    // sx={{
                    //   p: {
                    //     display: "none"
                    //   }
                    // }}
                    onChange={(e: any) => {
                      setSelectCurrencyPopup(e.target.value);
                    }}
                  >
                    {Array.isArray(currencyAndMethodData) &&
                      currencyAndMethodData?.map((item: any, index: number) => {
                        return (
                          <MenuItem value={item.currency} key={index}>
                            <Box className="MenuItemBox">
                              <img
                                loading="lazy"
                                width="25"
                                src={item.flag}
                                alt=""
                              />
                              <p>{item.currency}</p>
                            </Box>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
              {/* // balanceRender */}

              <Grid
                container
                spacing={1.5}
                style={{marginTop:"12px"}}
                className="dashboard-container__balance-wrapper"
              >
                <Grid item className="g_myaccounts">
                  <SkeletonDashboardV3
                    loader={Balanceloader}
                    height={'180px'}
                    width={'100%'}
                    element={
                      <MyAccounts
                        balance={balance}
                        user={user}
                        setShowTopup={setShowTopup}
                        setCurrencySelect={setCurrencySelect}
                        setVirefay_data={setVirefay_data}
                      />
                    }
                  ></SkeletonDashboardV3>
                </Grid>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose_popup}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Pending_Transactions_popup
                    Heading={'Pending Transactions'}
                    transactions={
                      balance?.KES?.transactions?.niobi_unified_transactions
                    }
                  />
                </Menu>
                <Grid item className='g_inflows'>
                  <SkeletonDashboardV3
                    loader={Payinsloader}
                    height={'180px'}
                    width={'100%'}
                    element={
                      <div
                        style={{ padding: '11px 24px 11px 24px' }}
                        className="total-balance"
                      >
                        {/* {showBadge && <WhatsNewBadge text="New" />} */}{' '}
                        {/*Code to be added on new feature icon or on side menu*/}
                        <div className="select_heding">
                          <div
                            style={{ fontSize: '14px' }}
                            className="header"
                            id="Total_Transactions_header"
                          >
                            Inflows{' '}
                            <Tooltip title="Get a clear overview of the number and total amount of your incoming payments at a glance.">
                              <Button>
                                <InfoIcon></InfoIcon>
                              </Button>
                            </Tooltip>
                          </div>
                          <div>
                            <Form.Select
                              style={{ fontSize: '10px' }}
                              name="name_day"
                              onChange={(e: any) => {
                                dispatch(
                                  dashboardActions.getPayins(
                                    e.target.value,
                                    selectCurrencyPopup,
                                    setPayinsRedusar,
                                    setPayinsloader
                                  ) as any
                                );
                                setSelectInflow(e.target.value);
                                setTimeout(() => {
                                  setPayinsRedusar(true);
                                }, 3000);
                              }}
                              value={selectInflow}
                              className={'input_select_data'}
                            >
                              <option selected value="">
                                All
                              </option>
                              {monthe_LIST?.map(
                                (arrayElement: any, index: number) => {
                                  return (
                                    <option
                                      value={arrayElement.values}
                                      key={index + 1}
                                    >
                                      {arrayElement.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                        <Inflows bill={payins} name={'DashboardV3'} />
                      </div>
                    }
                  ></SkeletonDashboardV3>
                </Grid>
                <Grid item className='g_outflows'>
                  <SkeletonDashboardV3
                    loader={Billsloader}
                    height={'180px'}
                    width={'100%'}
                    element={
                      <div
                        style={{ padding: '11px 24px 11px 24px' }}
                        className="total-balance"
                      >
                        <div className="select_heding">
                          <div
                            style={{ fontSize: '14px' }}
                            className="header"
                            id="Total_Transactions_header2"
                          >
                            Outflows{' '}
                            <Tooltip title=" Manage and track your paid and outstanding bills.">
                              <Button>
                                <InfoIcon></InfoIcon>
                              </Button>
                            </Tooltip>
                          </div>
                          <div>
                            <Form.Select
                              style={{ fontSize: '10px' }}
                              name="name_day"
                              onChange={(e: any) => {
                                dispatch(
                                  dashboardActions.getBills(
                                    e.target.value,
                                    selectCurrencyPopup,
                                    setBillRender,
                                    setBillsloader
                                  ) as any
                                );
                                setSelectOutFlow(e.target.value);
                                setTimeout(() => {
                                  setBillRender(true);
                                }, 3000);
                              }}
                              value={selectOutflow}
                              className={'input_select_data'}
                            >
                              <option selected value="">
                                All
                              </option>
                              {monthe_LIST?.map(
                                (arrayElement: any, index: number) => {
                                  return (
                                    <option
                                      value={arrayElement.values}
                                      key={index + 1}
                                    >
                                      {arrayElement.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                        <Outflows bill={bill} />
                      </div>
                    }
                  ></SkeletonDashboardV3>
                </Grid>
              </Grid>
              <Grid container spacing={1.5} className="container_chart_V3">
                <Grid item md={8} xs={12}>
                  <SkeletonDashboardV3
                    loader={ChartDataloader}
                    height={'450px'}
                    width={'100%'}
                    element={
                      <div className="dashboard-container__graph-section">
                        <div
                          id="desplay_contean"
                          className="d-flex space-between"
                        >
                          <div className="left" id="Current_Month_Activity">
                            <div
                              className="header"
                              style={{ fontSize: '14px' }}
                            >
                              Current Month's Activity
                              <Tooltip title=" Analyze your financial activity for the current month here.">
                                <Button>
                                  <InfoIcon></InfoIcon>
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="right">
                            <div className="d-flex gap-10">
                              <DatePickerll
                                dateFormat="dd-MMM-yyyy"
                                className="dashboard-container__datepicker"
                                selected={startDate}
                                onChange={(date: Date) => {
                                  setStartDate(date);
                        
                                  data.GET_DASHBOARD_CHART_SUCCESS = undefined;
                                  setChartrender(true);
                                  setChart('');
                                  settotal_payouts([]);
                                  setbills([]);
                                  setdeposits([]);
                                  setonloadChart(false);
                                  setchartdates([]);
                                  dispatch(
                                    dashboardActions.getChartData(
                                      moment(date).format('YYYY-MM-DD'),
                                      moment(endDate).format('YYYY-MM-DD'),
                                      selectCurrencyPopup,
                                      setChartrender,
                                      setChartDataloader
                                    ) as any
                                  );
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsStart
                              />
                              <DatePickerll
                                dateFormat="dd-MMM-yyyy"
                                className="dashboard-container__datepicker"
                                selected={endDate}
                                onChange={(date: Date) => {
                                  setEndDate(date);
                        
                                  setChartrender(true);
                                  setChart('');
                                  settotal_payouts([]);
                                  setbills([]);
                                  setdeposits([]);
                                  setonloadChart(false);
                                  setchartdates([]);
                                  dispatch(
                                    dashboardActions.getChartData(
                                      moment(startDate).format('YYYY-MM-DD'),
                                      moment(date).format('YYYY-MM-DD'),
                                      selectCurrencyPopup,
                                      setChartrender,
                                      setChartDataloader
                                    ) as any
                                  );
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsEnd
                              />
                            </div>
                          </div>
                        </div>
                        <div className="dashboard-container__chart_cantenar">
                          {!!chart ? (
                            <NewCharts
                              height={370}
                              ChartCurrncy={ChartCurrncy}
                              data={{
                                payouts: total_payouts,
                                bills: bills,
                                deposits: deposits,
                                chartsDate: chartdates,
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    }
                  ></SkeletonDashboardV3>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  style={{ marginTop: '19px' }}
                  container
                  className="dashboard-container__balance-wrapper"
                >
                  <SkeletonDashboardV3
                    loader={Updatedetailsloader}
                    height="450px"
                    width="100%"
                    element={
                      <Box id="contean_dashboardV3">
                        {/* Coming Soon Section */}
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div
                              className="fast_des_footer"
                              onClick={() => setFeedbackModal(true)}
                              style={{
                                minHeight: '120px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                              }}
                            >
                              <div
                                className="select_heding"
                                style={{ paddingBottom: '10px' }}
                              >
                                <div
                                  style={{
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap:'5px',
                                  }}
                                  className="header"
                                >
                                  <ComingSoonIcon /> Feedback Survey
                                </div>
                              </div>
                              <p>We'd love to hear from you.</p>
                              <p>Click to fill in our customer survey.</p>
                            </div>
                          </Grid>

                          {/* Loyalty Rewards Section */}
                          <Grid item md={12} xs={12}>
                            <div
                              className="fast_des_footer"
                              style={{
                                minHeight: '120px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                className="select_heding"
                                style={{ paddingBottom: '10px' }}
                              >
                                <div
                                  style={{
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap:'5px',
                                  }}
                                  className="header"
                                >
                                  <LoyaltyRewardsIcon /> Loyalty Rewards
                                </div>
                              </div>
                              <p>
                                No. of transactions this month:{' '}
                                <span style={{ fontSize: '15px' }}>
                                  {getWalletapiData?.loyalty_section_content
                                    ?.total_transactions || 0}
                                </span>
                              </p>
                              <p>
                                Total cashback earned: KES{' '}
                                <span style={{ fontSize: '15px' }}>
                                  {getWalletapiData?.loyalty_section_content
                                    ?.total_cashback || 0}
                                </span>
                              </p>
                              <p>
                                Remaining Transactions to earn cashback:{' '}
                                <span style={{ fontSize: '15px' }}>
                                  {getWalletapiData?.loyalty_section_content
                                    ?.remaining_transactions || 0}
                                </span>
                              </p>
                            </div>
                          </Grid>

                          {/* What's New Section */}
                          <Grid item md={12} xs={12}>
                            <div
                              className="fast_des_footer"
                              role="button"
                              style={{
                                minHeight: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                              onClick={() => setShowNewModal(true)}
                            >
                              <div
                                className="select_heding"
                                style={{ paddingBottom: '10px' }}
                              >
                                <div
                                  style={{
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap:'5px',
                                  }}
                                  className="header"
                                >
                                  <UpadetIcon /> What's New?
                                </div>
                              </div>
                              {!!update && (
                                <div>
                                  <p>NEW! {update.title}</p>
                                  <p>Click to learn more.</p>
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  />
                  <Modal
                    show={feedbackModal}
                    onHide={onCloseFeedbackModal}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="ModalHeader"
                    centered
                    size="lg"
                  >
                    <>
                      <Modal.Header closeButton>
                        <Modal.Title id="ModalHeader">Feedback</Modal.Title>
                        <IconButton sx={{ color: "#000" }} onClick={() => {
                          onCloseFeedbackModal()
                        }}>
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <Box className="box_dialog_main">
                          <FeedbackModal />
                          {/* <Button
                            className="cancel_btn"
                            onClick={onCloseFeedbackModal}
                            style={{
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              color: '#025141',
                              fontWeight: '400',
                              backgroundColor: '#fff',
                              border: '1px solid #025041',
                            }}
                          >
                            Go back
                          </Button> */}
                        </Box>
                      </Modal.Body>
                    </>
                  </Modal>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginTop: '12px' }}
                spacing={1.5}
                className="dashboard-container__balance-wrapper"
              >
                <Grid item md={4} xs={12} sm={12}>
                  <SkeletonDashboardV3
                    loader={Recentloader}
                    height={'230px'}
                    width={'100%'}
                    element={
                      <div
                        className="total-balance"
                        style={{ position: 'relative' }}
                      >
                        <div className="select_heding">
                          <div
                            className="header"
                            id="Recent_Transactions_header"
                            style={{ fontSize: '14px' }}
                          >
                            Recent Transactions{' '}
                            <Tooltip title="View your most recent transactions at a glance.">
                              <Button>
                                <InfoIcon></InfoIcon>
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                        {!!recent_nodata && recent_nodata == 'NODATA' ? (
                          <Dashboard_nodata
                            button="Click here to start"
                            Link={`/balance?currency=${selectCurrencyPopup}`}
                            img="/uil_transaction.svg"
                            text="Your recent transactions will appear here."
                          />
                        ) : !!user && user.role_name !== 'Associate' ? (
                          <Recent_Transactions
                            fileName={'DashboardV3'}
                            transactions={recent}
                            currency={selectCurrencyPopup}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    }
                  ></SkeletonDashboardV3>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <SkeletonDashboardV3
                    loader={PaymentMethodPayinsloader}
                    height={'230px'}
                    width={'100%'}
                    element={
                      <>
                        {!!payment_nodata && payment_nodata == 'NODATA' ? (
                          <div
                            className="total-balance"
                            style={{ position: 'relative' }}
                          >
                            <div className="select_heding">
                              <div
                                className="header"
                                style={{ fontSize: '14px' }}
                                id="payment_method"
                              >
                                Inflows by Payment Method
                                <Tooltip title="Track your spending based on  different payment methods for a clearer financial overview">
                                  <Button>
                                    <InfoIcon></InfoIcon>
                                  </Button>
                                </Tooltip>
                              </div>
                              <div>
                                <Form.Select
                                  style={{ fontSize: '10px' }}
                                  name="name_day"
                                  onChange={(e: any) => {
                                    dispatch(
                                      dashboardActions.getPaymentMethodPayins(
                                        e.target.value,
                                        selectCurrencyPopup,
                                        setPaymentMethodPayinsRedusar,
                                        setPaymentMethodPayinsloader,
                                        setPayment_nodata
                                      ) as any
                                    );
                                    setMontheFilter(e.target.value);
                                    setTimeout(() => {
                                      setPaymentMethodPayinsRedusar(true);
                                    }, 3000);
                                  }}
                                  value={montheFilter}
                                  className={'input_select_data'}
                                >
                                  <option selected value="">
                                    All
                                  </option>
                                  {monthe_LIST?.map(
                                    (arrayElement: any, index: number) => {
                                      return (
                                        <option
                                          value={arrayElement.values}
                                          key={index + 1}
                                        >
                                          {arrayElement.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>
                            <Dashboard_nodata
                              button="Click here to start"
                              Link="/balance"
                              img="/streamline_money-wallet-money-payment-finance-wallet.svg"
                              text="Payment methods will appear here"
                            />
                          </div>
                        ) : (
                          <div
                            style={{ padding: '0px' }}
                            className="total-balance"
                          >
                            <div
                              style={{ padding: '4px 12px 12px 12px' }}
                              className="select_heding"
                            >
                              <div
                                className="header"
                                style={{ fontSize: '14px' }}
                                id="inflows_payment_method"
                              >
                                Inflows by Payment Method
                                <Tooltip title="Visualize incoming funds by payment type at a glance">
                                  <Button>
                                    <InfoIcon></InfoIcon>
                                  </Button>
                                </Tooltip>
                              </div>
                              <div>
                                <Form.Select
                                  style={{ fontSize: '10px' }}
                                  name="name_day"
                                  onChange={(e: any) => {
                                    dispatch(
                                      dashboardActions.getPaymentMethodPayins(
                                        e.target.value,
                                        selectCurrencyPopup,
                                        setPaymentMethodPayinsRedusar,
                                        setPaymentMethodPayinsloader,
                                        setPayment_nodata
                                      ) as any
                                    );
                                    setMontheFilter(e.target.value);
                                    setTimeout(() => {
                                      setPaymentMethodPayinsRedusar(true);
                                    }, 3000);
                                  }}
                                  value={montheFilter}
                                  className={'input_select_data'}
                                >
                                  <option selected value="">
                                    All
                                  </option>
                                  {monthe_LIST?.map(
                                    (arrayElement: any, index: number) => {
                                      return (
                                        <option
                                          value={arrayElement.values}
                                          key={index + 1}
                                        >
                                          {arrayElement.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>
                            {!!user && user.role_name !== 'Associate' ? (
                              <Donut_chart
                                chart_payment={paymentMethodPayinsdata}
                                name={paymentMethodPayinsName}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </>
                    }
                  ></SkeletonDashboardV3>
                </Grid>
                <Grid item md={4} xs={12}>
                  <SkeletonDashboardV3
                    loader={PaymentExpensesloader}
                    height={'230px'}
                    width={'100%'}
                    element={
                      <>
                        {!!PaymentPaymentMethodnodata &&
                          PaymentPaymentMethodnodata == 'NODATA' ? (
                          <div
                            className="total-balance"
                            style={{ position: 'relative' }}
                          >
                            <div className="select_heding">
                              <div
                                className="header"
                                style={{ fontSize: '14px' }}
                                id="payment_method"
                              >
                                Outflows by Payment Method
                                <Tooltip title="Track your spending based on  different payment methods for a clearer financial overview">
                                  <Button>
                                    <InfoIcon></InfoIcon>
                                  </Button>
                                </Tooltip>
                              </div>
                              <div>
                                <Form.Select
                                  style={{ fontSize: '10px' }}
                                  name="name_day"
                                  onChange={(e: any) => {
                                    dispatch(
                                      dashboardActions.getPaymentExpenses(
                                        e.target.value,
                                        selectCurrencyPopup,
                                        setPaymentRender,
                                        setPaymentExpensesloader,
                                        setPaymentPaymentMethodnodata
                                      ) as any
                                    );
                                    setMonthetwo(e.target.value);
                                    // setTimeout(() => {
                                    //     setPaymentRender(true);
                                    // }, 3000);
                                  }}
                                  value={monthetwo}
                                  className={'input_select_data'}
                                >
                                  <option selected value="">
                                    All
                                  </option>
                                  {monthe_LIST?.map(
                                    (arrayElement: any, index: number) => {
                                      return (
                                        <option
                                          value={arrayElement.values}
                                          key={index + 1}
                                        >
                                          {arrayElement.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>
                            <Dashboard_nodata
                              button="Click here to start"
                              Link="/balance"
                              img="/streamline_money-wallet-money-payment-finance-wallet.svg"
                              text="Payment methods will appear here"
                            />
                          </div>
                        ) : (
                          <div
                            style={{ padding: '0px' }}
                            className="total-balance"
                          >
                            <div
                              style={{ padding: '4px 12px 12px 12px' }}
                              className="select_heding"
                            >
                              <div
                                className="header"
                                style={{ fontSize: '14px' }}
                                id="payment_method"
                              >
                                Outflows by Payment Method
                                <Tooltip title="Track your spending based on  different payment methods for a clearer financial overview">
                                  <Button>
                                    <InfoIcon></InfoIcon>
                                  </Button>
                                </Tooltip>
                              </div>
                              <div>
                                <Form.Select
                                  style={{ fontSize: '10px' }}
                                  name="name_day"
                                  onChange={(e: any) => {
                                    dispatch(
                                      dashboardActions.getPaymentExpenses(
                                        e.target.value,
                                        selectCurrencyPopup,
                                        setPaymentRender,
                                        setPaymentExpensesloader,
                                        setPaymentPaymentMethodnodata
                                      ) as any
                                    );
                                    setMonthetwo(e.target.value);
                                    // setTimeout(() => {
                                    //     setPaymentRender(true);
                                    // }, 3000);
                                  }}
                                  value={monthetwo}
                                  className={'input_select_data'}
                                >
                                  <option selected value="">
                                    All
                                  </option>
                                  {monthe_LIST?.map(
                                    (arrayElement: any, index: number) => {
                                      return (
                                        <option
                                          value={arrayElement.values}
                                          key={index + 1}
                                        >
                                          {arrayElement.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>
                            {!!user && user.role_name !== 'Associate' ? (
                              <Donut_chart
                                chart_payment={chart_payment}
                                name={chart_payment_name}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </>
                    }
                  ></SkeletonDashboardV3>
                </Grid>
              </Grid>
            </ThemeProvider>
          </div>
        </>
      )}
      {show_fast && (
        <Topup_fasttime
          show={show_fast}
          onClose={() => {
            setShow_fast(false);
          }}
        />
      )}

      {/* currency modal  */}
      {showTopup && (
        <TopupModal
          currencyAndMethodData={currencyAndMethodData}
          show={showTopup}
          select_Currency=""
          pageName="Dashboard"
          onClose={() => {
            const event = 'Top up canceled from Dashboard';
            const eventProperties = {
              userId:
                !!properties && !!properties.user_id
                  ? properties.user_id
                  : null,
              userName:
                !!properties && !!properties.name ? properties.name : null,
              email:
                !!properties && !!properties.email ? properties.email : null,
              pageUrl: window.location.href,
            };
            amplitude.getInstance().logEvent(event, eventProperties);
            setShowTopup(false);
            setShowBalanceGrid(true);
          }}
          onSave={(el: boolean) => {
            setShowTopup(false);
            setTopupMethod(el);
            setTopup(true);
            setShowBalanceGrid(false);
          }}
        />
      )}

      {topUp && (
        <TopupDetail
          topupMethod={topUpMethod}
          onReturn={() => {
            setTopup(false);
            setShowBalanceGrid(true);
          }}
        />
      )}
      {verefire_data && (
        <Virefily_account
          show={verefire_data}
          onClose={() => {
            setVirefay_data(false);
          }}
        />
      )}

      {showTopup_two && (
        <TopupModal_two
          values={SelectCurrency}
          countryList={currencyAndMethodData}
          onSaveCurrancy={(el: any) => {
            // setShowTopup_two(true);
            // setCurrencySelect(false)
            // setShowTopup_two(false);
            setSelectCurrency(el);
          }}
          show={showTopup_two}
          onClose={() => {
            setShowTopup_two(false);
            // setShowBalanceGrid(true);
          }}
          onBalkpaymeant={(el: boolean) => {
            setShowTopup_two(false);
            setTopupMethod(el);
            setTopup_balk_data(true);
            setShowBalanceGrid(false);
          }}
          onSave={(el: boolean) => {
            setShowTopup_two(false);
            setTopupMethod(el);
            setTopup(true);
            setShowBalanceGrid(false);
          }}
          onconfiam={(el: boolean) => {
            setPaymentGrid(true);
            setShowBalanceGrid(false);
            setTopup(false);
          }}
        />
      )}
      {topup_balk_data && (
        <Topup_balk_data
          show={topup_balk_data}
          onClose={() => {
            setTopup_balk_data(false);
            setShowBalanceGrid(true);
          }}
        />
      )}
      {paymentGrid && (
        <PayableGrid
          onCancel={() => {
            setPaymentGrid(false);
            setShowBalanceGrid(true);
          }}
        />
      )}
      <ThemeProvider theme={theme}>
        {currencySelect && (
          <Select_source_account
            onSave={(el: any) => {


              if (el == 'KES') {
                setShowTopup_two(true);
                setCurrencySelect(false);
                setSelectCurrency('KES');
                localStorage.setItem('currency', 'KES');
              } else {
                // setSelectMethod(true)
                // setCurrencySelect(false)
                setSelectCurrency(el);
                localStorage.setItem('currency', el);
                navigate(`/payment-journey?currency=${el}`);
              }
            }}
            currencyAndMethodData={currencyAndMethodData}
            onClose={() => {
              setCurrencySelect(false);
            }}
            show={currencySelect}
          />
        )}
      </ThemeProvider>
      {selectMethod && (
        <SelectMethod
          onSave={(el: any) => {
            setSelectMethod(false);
            if (el == 'Express Payment USD') {
              navigate(`/payment-journey?currency=${el}`);
            }
          }}
          onSaveCurrancy={(el: any) => {
            if (el == 'KES') {
              setShowTopup_two(true);
              setCurrencySelect(false);
              setSelectMethod(false);
              setSelectCurrency('KES');
            } else {
              setSelectMethod(true);
              setCurrencySelect(false);
              setSelectMethod(false);
              setSelectCurrency(el);
            }
          }}
          values={SelectCurrency}
          onClose={() => {
            setSelectMethod(false);
          }}
          show={selectMethod}
        />
      )}
      {showNewModal && (
        <WhatsNewModal
          show={showNewModal}
          onClose={() => setShowNewModal(false)}
        // title={whatsNewTitle}
        // body={whatsNewBody}
        // image_link={whatsNewImage}
        />
      )}
    </>
  );
};

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 11 11"
    fill="none"
  >
    <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
    <path
      d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
      fill="#025041"
    />
    <path
      d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
      stroke="#025041"
      stroke-linecap="round"
    />
  </svg>
);
const ArrowDounIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66675 8.4125L2.25425 9L7.08341 4.17083V6.91667H7.91675V2.75H3.75008V3.58333H6.49591"
      fill="#025041"
    />
  </svg>
);
const ArrowUpIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33325 2.92148L7.74575 2.33398L2.91659 7.16315V4.41732H2.08325V8.58398H6.24992V7.75065H3.50409"
      fill="#025041"
    />
  </svg>
);
