import { Box , Typography} from "@mui/material"

const MakePaymentHeader = () => {
    return (
        <Box className='makepayment_header'>
            <Box className="header_auto_container">
                <Typography className='header_name' style={{fontFamily:"Poppins"}}>Make Payments</Typography>
            </Box>
        </Box>
    )
}

export default MakePaymentHeader