
import { Box, Typography, Button } from '@mui/material'
import React, { useState } from 'react'
import { DefaultBtn } from '../../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { CustomInput } from '../../../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import PhoneInput from 'react-phone-input-2'
import "./mpesa.scss"
import { InputNumber } from '../../../../../../../../../../../ui/input'
import { PayBilComponent, SendMoneyComponent, TillNumberComponent } from './methoadCom'

export const MpesaComponent = ({ mobilePaymentmethoad, currency,formik, setActiveButton, activeButton , mobileMoneyMethods}: any) => {

    const handleButtonClick = (buttonName: any) => {
        setActiveButton(buttonName);
    };







    // const renderComponent = () => {
    //     // switch (activeButton) {
    //     //     case 'send money':
    //     //         return <SendMoneyComponent />;
    //     //     case 'pay bill':
    //     //         return <PayBilComponent />;
    //     //     case 'till number':
    //     //         return <TillNumberComponent />;
    //     //     default:
    //     //         return null;
    //     // }
    //     if(activeButton=='send money'){
    //         return <SendMoneyComponent />
    //     }
    // };

    return (
        <Box>
            <Box className="d-flex justify-content-center align-items-center gap-2 mb-5">
                {mobileMoneyMethods.map((item: any, index: number) => {

                    return (
                        <>
                            {item.key == "pesalink" ? "" :
                                <Button
                                className={`mpesa_btn ${activeButton === item.key ? 'active-btn' : index === 0 && !mobileMoneyMethods?.find((c: any) => c.key === activeButton) ? "active-btn" : ""}`}
                                    onClick={() => handleButtonClick(item.key)}
                                >
                                    {item.title}
                                </Button>}
                        </>
                    )
                })}
                {/* <Button
                    className={`mpesa_btn ${activeButton === 'payBill' ? 'active-btn' : 'inactive-btn'}`}
                    onClick={() => handleButtonClick('payBill')}
                >
                    Pay bill
                </Button>
                <Button
                    className={`mpesa_btn ${activeButton === 'tillNumber' ? 'active-btn' : 'inactive-btn'}`}
                    onClick={() => handleButtonClick('tillNumber')}
                >
                    Till number
                </Button> */}
            </Box>
            {/* {renderComponent()} */}
            {activeButton == 'pay bill' ?
                <PayBilComponent formik={formik} />
                : activeButton == 'till number' ?
                    <TillNumberComponent formik={formik} />
                    :activeButton =="send money"?<SendMoneyComponent activeButton={activeButton} currency={currency} formik={formik} />: ""}
        </Box>
    )
}

