import { Avatar, Box, Typography } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Button } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import "./addpaymentmethod.scss";
import { BankIcon, PesaIcon } from '../Icons/Icons';

const AddPaymentMethod = ({ ChackBlence,formik,onClose, style, payee, selectedPayee, enterAmount, SelectCurrency , paymentData, setPaymentData , setIsAddPayeeComponent , stepupdatefun}: any) => {
    console.log(selectedPayee, "selectedPayee data");

    const payeeAccounts = Array.isArray(formik?.values?.counterpart?.payee_account)
        ? formik?.values?.counterpart?.payee_account
        : [formik?.values?.counterpart?.payee_account];

    const recentPayeeAccount = formik?.values?.counterpart?.recent_payee_account?.map((account: any) => {
        const recent = []
        if (account.bank_name) {
            recent.push({
                name: account.bank_name,
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                account: account.bank_account_number,
            })
        }

        if (account.paybill_number) {
            recent.push({
                name: "Mpesa Paybill",
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                description: `Paybill: ${account.paybill_number}`,
                paybill_number: account.paybill_number,
            });
        }

        if (account.till_number) {
            recent.push({
                name: "Mpesa Till",
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                description: account.till_number,
                till_number: account.till_number,
            });
        }

        if (account.send_money_number) {
            recent.push({
                name: "Mpesa Send Money",
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                send_money_number: account.send_money_number,
                description: account.send_money_number,
            })
        }
        if (account.mtn_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.mtn_number,
                description: account.mtn_number,
            })
        }
        if (account.airtel_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.airtel_number,
                description: account.airtel_number,
            })
        }
        if (account.halotel_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.halotel_number,
                description: account.halotel_number,
            })
        }
        if (account.tigo_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.tigo_number,
                description: account.tigo_number,
            })
        }
        if (account.vodacom_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.vodacom_number,
                description: account.vodacom_number,
            })
        }
        if (account.moov_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.moov_number,
                description: account.moov_number,
            })
        }

        if (account.orange_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.orange_number,
                description: account.orange_number,
            })
        }
        if (account.free_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.free_number,
                description: account.free_number,
            })
        }
        if (account.zamtel_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.zamtel_number,
                description: account.zamtel_number,
            })
        }
        if (account.tnm_number) {
            recent.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.tnm_number,
                description: account.tnm_number,
            })
        }
        return recent;


    }).flat().filter(Boolean);


    const bankMethods = (formik?.values?.counterpart?.other_payee_accounts || payeeAccounts)?.map((account: any) => {
        if (account.bank_name) {
            return {
                name: account.bank_name,
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                account: account.bank_account_number,
            }
        }
        return null;
    }).filter(Boolean);


    const otherMethods = (formik?.values?.counterpart?.other_payee_accounts || payeeAccounts)?.map((account: any) => {
        const methods = [];

        // if (account.payment_channel) {
        //     methods.push({
        //         name: account.title,
        //         key:account.payment_channel.key,
        //         country_id:account.payment_channel.country_id,
        //         description: `${account.payment_channel.field}: ${account.send_money_number || ''}`,
        //         type: 'pesalink',
        //         send_money_number: account.send_money_number
        //     });
        // }

        if (account.paybill_number) {
            methods.push({
                name: "Mpesa Paybill",
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                description: `Paybill: ${account.paybill_number}`,
                paybill_number: account.paybill_number,
            });
        }

        if (account.till_number) {
            methods.push({
                name: "Mpesa Till",
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                description: account.till_number,
                till_number: account.till_number,
            });
        }

        if (account.send_money_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.send_money_number,
                description: account.send_money_number,
            })
        }
        if (account.mtn_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.mtn_number,
                description: account.mtn_number,
            })
        }
        if (account.airtel_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.airtel_number,
                description: account.airtel_number,
            })
        }
        if (account.halotel_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.halotel_number,
                description: account.halotel_number,
            })
        }
        if (account.tigo_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.tigo_number,
                description: account.tigo_number,
            })
        }
        if (account.vodacom_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.vodacom_number,
                description: account.vodacom_number,
            })
        }
        if (account.moov_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.moov_number,
                description: account.moov_number,
            })
        }

        if (account.orange_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.orange_number,
                description: account.orange_number,
            })
        }
        if (account.free_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.free_number,
                description: account.free_number,
            })
        }
        if (account.zamtel_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.zamtel_number,
                description: account.zamtel_number,
            })
        }
        if (account.tnm_number) {
            methods.push({
                key: account.key,
                country_id: account.country_id,
                payment_channel: account.payment_channel,
                payment_method: account.payment_method,
                phone_number: account.tnm_number,
                description: account.tnm_number,
            })
        }
        return methods;
    }).flat().filter(Boolean);

    const handleMethodSelect = (method: any) => {
        console.log(method, "methodmethod");

        formik.setFieldValue('type',method.key)
        formik.setFieldValue('payment_channel',method.payment_channel)
        formik.setFieldValue('payment_method',method.payment_method)
        let inputValue = '';


        if (method.payment_method === 'Bank Transfer') {
            inputValue = method.account;
        } else if (method.payment_method === 'Mobile Money') {
            if (method.payment_channel === 'pay bill') {
                inputValue = method.paybill_number;
            } else if (method.payment_channel === 'till number') {
                inputValue = method.till_number;
            } else if (method.payment_channel === 'send money') {
                inputValue = method.send_money_number
            }
        }
        
  
        ChackBlence(method.payment_channel,method.payment_method)
        setPaymentData({
            ...paymentData,
            bankName: method.name,
            input_field_data: inputValue
        });
    };

    return (
        <Box>
            <Box className="add_payment_method_container">
                <Box className="heading_wrapper mt-5">
                    <Typography className="heading">Choose payment method</Typography>
                    <Typography className='sub_heading_note color_secondary'>You're sending <span style={{ color: "#025041" }}>{enterAmount} {SelectCurrency}</span> to {selectedPayee?.name}</Typography>
                </Box>

                {recentPayeeAccount && recentPayeeAccount.length > 0 ? (
                    <Box className="other_methods">
                        <Typography className="sub_heading">Recently Used</Typography>
                        {recentPayeeAccount.map((method: any, index: number) => (
                            <Box style={{ cursor: "pointer", padding: "8px", borderRadius: "10px", backgroundColor: paymentData.bankName === method.name ? '#F5F5F5' : 'white' }} key={index} className="d-flex gap-3 method" onClick={() => handleMethodSelect(method)}>
                                <Avatar sx={{ width: 45, height: 45 }} className='avatar'>
                                    {
                                        method.payment_method === "Bank Transfer" ? (
                                            <BankIcon style={{ width: "22px" }} />
                                        ) : (
                                            <PesaIcon style={{ width: "22px" }} />
                                        )
                                    }
                                </Avatar>
                                <Box>
                                    <Typography style={{ fontFamily: "Poppins" }}>{method.name}</Typography>
                                    <Typography className="color_secondary" style={{ fontFamily: "Poppins" }}>{`Account ${method.account}`}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography className="color_secondary" style={{ fontFamily: "Poppins", fontSize: "14px", padding: "10px 0" }}>
                        No recently used methods
                    </Typography>
                )

                }


                {bankMethods && bankMethods.length > 0 && (
                    <Box className="other_methods">
                        <Typography className="sub_heading">Bank Accounts</Typography>
                        {bankMethods.map((method: any, index: number) => (
                            <Box style={{ cursor: "pointer", padding: "8px", borderRadius: "10px", backgroundColor: paymentData.bankName === method.name ? '#F5F5F5' : 'white' }} key={index} className="d-flex gap-3 method" onClick={() => handleMethodSelect(method)}>
                                <Avatar sx={{ width: 45, height: 45 }} className='avatar'>
                                    <BankIcon style={{ width: "22px" }} />
                                </Avatar>
                                <Box>
                                    <Typography style={{ fontFamily: "Poppins" }}>{method.name}</Typography>
                                    <Typography className="color_secondary" style={{ fontFamily: "Poppins" }}>{`Account ${method.account}`}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}

                {otherMethods && otherMethods.length > 0 && (
                    <Box className="other_methods">
                        <Typography className="sub_heading">Other methods</Typography>
                        {otherMethods.map((method: any, index: number) => (
                            <Box style={{ cursor: "pointer", padding: "8px", borderRadius: "10px", backgroundColor: paymentData.bankName === method.name ? '#F5F5F5' : 'white' }} key={index} className="d-flex gap-3 method" onClick={() => handleMethodSelect(method)}>
                                <Avatar sx={{ width: 45, height: 45 }} className='avatar'>
                                    <PesaIcon style={{ width: "22px" }} />
                                </Avatar>
                                <Box>
                                    <Typography style={{ fontFamily: "Poppins" }}>{method.name}</Typography>
                                    <Typography className="color_secondary" style={{ fontFamily: "Poppins", fontSize: "13px" }}>{method.description}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}

                <Box className="add_payment_method_btn_wrapper">
                    <Button className='add_payment_method_btn' onClick={() => { stepupdatefun(0); setIsAddPayeeComponent(true) }}>
                        <AddIcon className="add_icon" /> <Typography className='button_text'>Add payment method</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default AddPaymentMethod