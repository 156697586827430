import { Box, Typography } from "@mui/material"
import "./step4.scss"
import { CustomInput } from "../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import { DefaultBtn, LightBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons"

const Step4 = () => {
  return (
    <Box className="step4_container d-flex flex-column justify-content-center">
          <Box className="otp_box d-flex flex-column justify-content-center gap-4">
            <Box className="d-flex flex-column justify-content-center align-items-center gap-3">
              <Typography className="authorize_heading">Authorise Transaction</Typography>
              <Typography className="authorize_sub_heading">To authorise transaction enter security code that
                was send via SMS to phone number +49 211 *****62</Typography>
            </Box>
            <Box>
              <label className="otp_label">Security code</label>
              <CustomInput className="otp_input" />
            </Box>
            <Box className="btn_wrapper">
              <LightBtn text="Cancel" style={{ border: "none", width: "35%" }} />
              <DefaultBtn text="Confirm" style={{ width: "55%" }}/>
            </Box>
          </Box>
    </Box>
  )
}

export default Step4