
import { Button } from "react-bootstrap";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { DownloadIcon , UplaodIcon } from "../Icons/Icons";
import "./buttons.scss"
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Typography } from "@mui/material";


export const DefaultBtn = ({ text, onClick, style, loader }: any) => {
    return (
        <>
            <Button style={style} onClick={onClick} type='submit' className="defaultHalfWidthBtn">
                {text}
                <span style={{ paddingLeft: "3px" }}>
                 {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
                </span>
            </Button>
        </>
    )
}
export const LightBtn = ({ text, onClick, style, loader }: any) => {
    return (
        <>
            <Button style={style} onClick={onClick} className="lightHalfWidthBtn">
                {text}
                <span style={{ paddingLeft: "3px" }}>
                 {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
                </span>

            </Button>
        </>
    )
}

export const TextBtn = ({ text, onClick, style, loader }: any) => {
    return (
        <>
            <Button style={style} onClick={onClick} className="textBtn">
                {text}
                <span style={{ paddingLeft: "3px" }}>
                 {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
                </span>

            </Button>
        </>
    )
}

export const DefaultGoBackBtn=({onClick,style , hidden}:any)=>{

    if(hidden){
        return null
    }

    return(
        <Button className="defaultGoBackBtn" style={style} onClick={onClick}>
            <KeyboardBackspaceIcon /> Go Back
        </Button>
    )
}

export const  LightDownloadBtn = ({text , onClick , style , iconStyle} : any) =>{
   return (
    <Button className="downlaodBtn" style={style} onClick={onClick}>
       {text? text : "Text"}
       <span>
          <SaveAltIcon className="downloadIcon" style={iconStyle} />
       </span>
    </Button>                
   )
}

export const  LightUploadBtn = ({text , onClick , style , iconStyle} : any) =>{
    return (
     <Button className="uploadBtn" style={style} onClick={onClick}>
        {text? text : "Text"}
        <span>
           <SaveAltIcon className="uploadIcon" style={{transform:"rotate(180deg)" , ...iconStyle}} />
        </span>
     </Button>                
    )
 }


 export const LightIconAndTextButton = ({ onClick, style, hidden, icon, text, disabled }: any) => {
    if (hidden) {
        return null;  // Return null to hide the button
    }
    return (
        <Button 
            className={`LightIconAndTextButton d-flex gap-2 align-items-center ${disabled ? "disabledd" : ""}`} 
            style={style} 
            disabled={disabled} 
            onClick={disabled ? null : onClick}
        >
            <Box className={`${disabled ? "disabledd" : ""}`} >
                {icon}
            </Box>
            <Typography 
                className={`${disabled ? "disabledd" : ""}`} 
                style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "500" }}
            >
                {text}
            </Typography>
        </Button>
    );
};
