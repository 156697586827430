import { Button , Box, Grid, Typography, FormControl, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { CustomInput } from '../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import "./addnewpayee.scss"
import FullWidthTabs from './Components/tabs'
import { DefaultGoBackBtn } from '../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { useEffect, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import { LightIconAndTextButton } from '../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { AddCircleIcon, BinIcon } from '../../../../../../../../ui/ReuseableComponents/Icons/Icons'
import { CurrencySelectBox } from '../../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import { useDispatch } from 'react-redux'
import { payablesFlowactions } from '../../../../../../../../../_actions'
import * as Yup from "yup";
import { Formik } from "formik";
import { ErrorMessage } from '../../../../../../../../ui/eroor';
import { CountrySelectBox } from '../../../../../../../../ui/selectBox';
import { Form } from 'react-bootstrap';
import { InputNumber } from '../../../../../../../../ui/input'
import { RadioList } from '../../../../../../../../ui/RadioGroup'
const Step1AddNewPayeeComponent = ({ isAddPayeeComponent, setIsAddPayeeComponent, bankData }: any) => {
  const [isContactDetailsOpen, setIsContactDetailsOpen] = useState(false)
  const [currency, setCurrency] = useState([])
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const [render, setRender] = useState(true)
  const [mobilePaymentmethoad, setMobilePaymentMothoad] = useState([])
  const [bankPaymentMethoad, setBankPaymentMethoad] = useState([])
  const [countriesByCurrency, setCountriesByCurrency] = useState([])
  const [activeButton, setActiveButton] = useState('')
  const [PayOutMethodsByCountryId , setPayOutMethodsByCountryId] = useState([])
  const [countryId , setCountryId] = useState("1")
  const dispatch = useDispatch();
  const [Rediolist, setRedioList] = useState([
    {
      name: "Individual",
      value: "individual"
    },
    {
      name: "Organization",
      value: "organization"
    }
  ])
  const [fildForm, setFildFrom] = useState({
    First_Name: "",
    Last_Name: "",
    currency: "KES",
    Organization_Name: "",
    Type: "individual",
    Email: "",
    Phone_number: "",
    Street_address: "",
    Country: "",
    Country_id: "",
    till_number: "",
    paybill_number: "",
    send_money_number: "",
    account_number: "",
    mtn_number: "",
    orange_number: "",
    moov_number: "",
    free_number: "",
    airtel_number: "",
    vodacom_number: "",
    tnm_number: "",
  })
  
  const validationSchema = Yup.object().shape({
    First_Name: Yup.mixed().when('Type', {
      is: 'individual',
      then: Yup.mixed().required('Please enter the First Name.'),
      otherwise: Yup.mixed().notRequired(),
    }),
    Last_Name: Yup.mixed().when('Type', {
      is: 'individual',
      then: Yup.mixed().required('Please enter the Last Name.'),
      otherwise: Yup.mixed().notRequired(),
    }),
    Organization_Name: Yup.mixed().when('Type', {
      is: 'organization',
      then: Yup.mixed().required('Please enter the Organization Name.'),
      otherwise: Yup.mixed().notRequired(),
    }),
    currency: Yup.string()
      .required("Please select the Currency."),
    Country_id : Yup.string()
      .required("Please select the Country."),
    Type: Yup.string()
      .required("Please select the Type."),
  });
  const CurrancyList = (data: any) => {
    // setCurrency(data)
    var NewArray: any = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.currency == "GHS") {
      }
      else {
        NewArray.push(element)
      }
    }
    setCurrency(NewArray)
  }
  useEffect
    (() => {
      if (render) {
        dispatch(payablesFlowactions.getApipayment("/get-all-currencies-details-by-user", CurrancyList) as any);
      }
    }, [render])
    useEffect(() => {
      if (countriesByCurrency.length > 0) {
          setCountryId((countriesByCurrency[0] as any).id);
      }
  }, [countriesByCurrency]);

  useEffect(()=>{
      dispatch(payablesFlowactions.getApipayment(`/v4/get-payment-methods-payee/${SelectCurrency}/${countryId}/pay_out`, getPayOutMethodsByCountryId) as any);
  },[countryId])
  // const PaymentMethoad = (data: any) => {
  //   var NewArray: any = []
  //   for (let index = 0; index < data?.methods?.pay_out?.length; index++) {
  //     const element = data.methods?.pay_out[index];
  //     NewArray.push(element)
  //   }
  //   setMobilePaymentMothoad(NewArray)
  // }
  // const BankPaymentMethoad = (data: any) => {
  //   var NewArray: any = []
  //   for (let index = 0; index < data?.methods?.pay_out?.length; index++) {
  //     const element = data.methods?.pay_out[index];
  //     NewArray.push(element)
  //   }
  //   setBankPaymentMethoad(NewArray)
  //   console.log(data, "wajidbankdata")
  // }
  const getCountries = (data: []) => {
      console.log(data, "wajidcountrydata")
      setCountriesByCurrency(data);  
      console.log(countriesByCurrency, "wajidcountrystate")
  }
  const getPayOutMethodsByCountryId = (data: any) => {
      setPayOutMethodsByCountryId(data);
      console.log(data , "wajidpayout");
  }


  useEffect(() => {
    // dispatch(payablesFlowactions.getApipayment(`/mobile-money/get-currencies-with-payment-methods/${SelectCurrency}`, PaymentMethoad) as any);
    // dispatch(payablesFlowactions.getApipayment(`/bank-transfer/get-currencies-with-payment-methods/${SelectCurrency}`, BankPaymentMethoad) as any);
    dispatch(payablesFlowactions.getApipayment(`/v4/get-country-details/${SelectCurrency}`, getCountries) as any);
  }, [SelectCurrency])

  const handleDeleteContactDetails = () => {
    // setFild_data({
    //   ...fild_data,
    //   Email: '',
    //   Phone_number: '',
    //   Street_address: '',
    //   Country: '',
    //   tax_identification_number: ''
    // })
    setIsContactDetailsOpen(false)
  }
  const handleCountryButtonClick = (buttonName: any) => {
    setActiveButton(buttonName);
  };

  return (
    <Box>
      <Formik
        // innerRef={formdata}
        initialValues={fildForm}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
        }}
      >
        {(formik) => (
          <>
            {console.log(formik, formik.values, formik.errors, "formikformik")}
            <Form onSubmit={formik.handleSubmit} >
              <DefaultGoBackBtn onClick={() => setIsAddPayeeComponent(false)} style={{ marginTop: "-60px" }} />
              <Box className="d-flex justify-content-center flex-column ">
                <Typography className="add_payee_heading">
                  Add payee details
                </Typography>
                <Grid container display={'flex'} justifyContent={'space-between'}>
                  {formik.values.Type === 'individual' && (
                    <>
                      <Grid item md={5.8}>
                        <InputNumber label={'First Name'} mainDevstyle={{ margin: '0px' }} value={formik.values.First_Name} onChange={(e: any) => formik.setFieldValue('First_name', e.target.value)} />
                        {formik.touched.First_Name && formik.errors.First_Name ? (
                          <ErrorMessage>{formik.errors.First_Name}</ErrorMessage>
                        ) : null}
                      </Grid>
                      <Grid item md={5.8}>
                        <InputNumber label={'Last Name'} mainDevstyle={{ margin: '0px' }} value={formik.values.Last_Name} onChange={(e: any) => formik.setFieldValue('Last_name', e.target.value)} />
                        {formik.touched.Last_Name && formik.errors.Last_Name ? (
                          <ErrorMessage>{formik.errors.Last_Name}</ErrorMessage>
                        ) : null}
                      </Grid>
                    </>
                  )}
                  {formik.values.Type === 'organization' && (
                    <Grid item md={12}>
                      <InputNumber label={'Organization Name'} mainDevstyle={{ margin: '0px' }} placeholder="Organization name" style={{ width: "100% !important" }} value={formik.values.Organization_Name} onChange={(e: any) => formik.setFieldValue('Organization_Name', e.target.value)} />
                      {formik.touched.Organization_Name && formik.errors.Organization_Name ? (
                        <ErrorMessage>{formik.errors.Organization_Name}</ErrorMessage>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
                <Box sx={{ padding: "10px" }}>
                  <RadioList list={Rediolist} value={formik.values.Type} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('Type', event.target.value)
                  }} />
                  {formik.touched.Type && formik.errors.Type ? (
                    <ErrorMessage>{formik.errors.Type}</ErrorMessage>
                  ) : null}
                </Box>
                <Box>
                  {
                    !isContactDetailsOpen ? (
                      <LightIconAndTextButton style={{ color: "#025041" }} icon={<AddCircleIcon style={{ color: "#025041", width: "20px" }} />} text="Add contact details" onClick={() => setIsContactDetailsOpen(!isContactDetailsOpen)} />
                    ) : (
                      <LightIconAndTextButton icon={<BinIcon style={{ color: "red", width: "20px" }} />} text="Remove contact details" onClick={handleDeleteContactDetails} />
                    )
                  }
                  <Box style={{ transition: "height 0.3s ease" }}>
                    {
                      isContactDetailsOpen && (
                        <Box className="mt-3">
                          <Box className="mb-4">
                            <Typography className='name-label'>Email</Typography>
                            <CustomInput value={formik.values.Email} onChange={(event: any) => formik.setFieldValue('Email', event.target.value)} />
                          </Box>
                          <Box className="mb-4">
                            <Typography className='name-label'>Phone number</Typography>
                            <CustomInput value={formik.values.Phone_number} onChange={(event: any) => formik.setFieldValue('Phone_number', event.target.value)} />
                          </Box>
                          {/* <Box className="mb-4">
                          <Typography className='name-label'>Tax Number</Typography>
                          <CustomInput value={fild_data.tax_identification_number} onChange={(event: any) => setFild_data({ ...fild_data, tax_identification_number: event.target.value })} />
                        </Box> */}
                          <Box className="mb-4">
                            <Typography className='name-label'>Street address</Typography>
                            <CustomInput value={formik.values.Street_address} onChange={(event: any) => formik.setFieldValue('Street_address', event.target.value)} />
                          </Box>
                          <Box className="mb-4">
                            <Typography className='name-label'>Country</Typography>
                            <CountrySelectBox value={formik.values.Country} onChange={(event: any) => formik.setFieldValue('Country', event.target.value)} />
                          </Box>
                        </Box>
                      )
                    }
                  </Box>
                </Box>
              </Box>
              <Box className="mt-4">
                <Box className="d-flex justify-content-between align-items-center">
                  <Typography className="add_payee_payment_details_heading">Add payment details</Typography>
                  <CurrencySelectBox countryList={currency}
                    value={formik.values.currency}
                    onChange={(e: any) => {
                      console.log(e.target.value, "asfsafasffasf");
                      formik.setFieldValue("currency", e.target.value)
                      setSelectCurrency(e.target.value)
                      // handleCurrencyChange();
                    }} />
                </Box>
                <Box style={{display: countriesByCurrency.length == 1 ? "none" : "flex" , margin: countriesByCurrency.length == 1 ? "0px" : "14px 0px"}} className="gap-2 justify-content-center align-items-center " >
                  {
                    countriesByCurrency?.map((country: any , index) => {
                        return(
                          <Button
                           
                           className={`country_btn ${activeButton === country.name ? "active-btn" : index === 0 && !countriesByCurrency?.find((c: any) => c.name === activeButton) ? "active-btn" : ""}`}
                           onClick={() => {
                            formik.setFieldValue('Country_id', country.id)
                            console.log(formik.values.Country_id, "wajidid");
                            handleCountryButtonClick(country.name)
                            setCountryId(country.id)
                            
                          }}>
                          { country.currency === "TZS" ? "Tanzania" : country.currency === "CDF" ? "Congo" : country.name}
                            
                          </Button>
                        )
                    })
                  }
                </Box>
                <Box>
                  <FullWidthTabs PayOutMethodsByCountryId={PayOutMethodsByCountryId} countryId={formik.values.Country_id} formik={formik} currency={formik.values.currency} bankPaymentMethoad={bankPaymentMethoad} mobilePaymentmethoad={mobilePaymentmethoad} bankData={bankData} />
                </Box>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Box >
  )
}
export default Step1AddNewPayeeComponent