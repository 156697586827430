import { createTheme, FormControlLabel, Radio, RadioGroup, ThemeProvider } from "@mui/material"
import { orange } from "@mui/material/colors";
import styles from './index.module.scss';

export const RadioList = ({ value, onChange, list }: any) => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                className={styles.main_radio_button_container}
                value={value}
                onChange={onChange}
            >
                {list?.map((item: any, index: Number) => {
                    return (
                        <FormControlLabel
                            className={styles.main_radio_button}
                            value={item.value}
                            control={
                                <Radio
                                    //   disabled={!!datadisplay ? true : false}
                                    icon={<img src="/Checkbox.svg" />}
                                    checkedIcon={<img src="/Checkbox_two.svg" />}
                                />
                            }
                            label={item.name}
                        />
                    )
                })}

                {/* <FormControlLabel
                    className="main_radio_button"
                    value="organization"
                    control={
                        <Radio
                            //   disabled={!!datadisplay ? true : false}
                            icon={<img src="/Checkbox.svg" />}
                            checkedIcon={<img src="/Checkbox_two.svg" />}
                        />
                    }
                    label="Organization"
                /> */}
            </RadioGroup>

        </ThemeProvider>
    )
}