import { Box, TextField, Typography } from "@mui/material"
import "./step4.scss"
import ProgressComponent from "../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress";
import PaymentFailed from "../../../../../../../ui/ReuseableComponents/Notifications/FailedPayment/PaymentFailed";

const Step4 = () => {
  
  return (
      //  <ProgressComponent heading="lorem ipsum doller emit" text="lorem ipsum doller emet"/>
         <PaymentFailed buttonText="Retry" heading="Payment failed" />
      
  )
}

export default Step4