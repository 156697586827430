import "./paymentfailed.scss"
import Model from "../Model/Model"
import { Box , Typography } from "@mui/material"
import { DefaultBtn } from "../../Buttons/Buttons"

const PaymentFailed = ({heading , text , buttonText , onClick}: any) => {
  return (
    <Model>
    <Box className="transaction_failed_box d-flex justify-content-center align-items-center flex-column">
      <img src="./paymentfailed.png" style={{width:"55px"}}/>
      <Typography className="transaction_failed_heading">{heading}</Typography>
      <DefaultBtn text={buttonText} onClick={onClick}/>
    </Box>
  </Model>
  )
}

export default PaymentFailed