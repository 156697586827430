import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import "./MPesa.scss";
import { useNavigate } from "react-router-dom";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import { useDispatch, useSelector } from "react-redux";
import { payablesActions } from "../../../../../../_actions/payables.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { isUserAuthenticated } from "../../../../../../_helpers";
import { selectAllData } from "../../../../../../_reducers/dashboard.reducer";
import { useSearchParams } from "react-router-dom";
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userActions, dashboardActions } from "../../../../../../_actions";
import PhoneInput from "react-phone-input-2";
import { Box, CircularProgress, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import countryList from "react-select-country-list";

interface Props {
  onSave: any;
  onCancel: any;
  counterpart: any;
  item: any;
  bodypyement: any;
  button_dess: any;
  transactionExist: any;
  setAccountName: any;
  ChackBalancefun: any;
  MobileMoney: any;
  setPhone_numberonther: any;
  ChackLimet: any;
  setGetFee: any;
  phone_numberSet: any;
  listContryset: any;
}
interface countryProps {
  dialCode: string;
}

export const MPesa = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [option, setOption] = useState("");
  const [render, setRender] = useState(true);
  const [feesNew, setFeesNew] = useState<any>();
  const [balance, setBalance] = useState<any>();
  const [feesNewRender, setFeesNewRender] = useState(true);
  const [feesNewRender_two, setFeesNewRender_two] = useState(false);
  const [tillRender, setTillRender] = useState(false);
  const [balanceRender, setBalanceRender] = useState(true);
  const [itemm, setitem] = useState<any>();
  const [itemSelectedRender, setitemSelectedRender] = useState(true);
  const [paybleOption, setPaybleOption] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [itemRender, setItemRender] = useState(true);
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const data = useSelector((state) => selectAllData(state));
  const dispatch = useDispatch();
  const [phoneinit, setphoneinit] = useState(true);
  const [country_code, setcountry_code] = useState("");
  const [datadisplay, setDatadisplay] = useState("");
  const [button_desebal, setButton_desebal] = useState();
  const [button_dataget, setButton_dataget] = useState();
  const [new_responss_data, setNwe_respons_data] = useState();
  const [loading, setLoader] = useState(false);
  const [phoneVlide, setPhoneVlide] = useState("");
  const [phoneVlidetwo, setPhoneVlidetwo] = useState("");
  const [Error, setError] = useState(false);
  const [tabdata, setTabdata] = useState("MPesa");
  const [LimetChack, setLimetChack] = useState(false);
  const [cNupdate, setcNupdate] = useState<any>({
    phone_number: null,
    paybill_number: null,
    account_number: null,
    till_number: null,
  });
  const [rqBody, setrqBody] = useState<any>({
    amount: null,
    counterpart: null,
    type: null,
    tags: [],
  });
  const [submittedData, setSubmittedData] = useState<any>({
    phone_number: null,
    paybill_number: null,
    account_number: null,
    till_number: null,
  });
  const [startAPI, setStartAPI] = useState(false);
  const [ChackBalance, setChackBalance] = useState(true);
  const [LoaderPage, setLoaderPage] = useState(false);
  const [contryCodephone, setcountry_codephone] = useState("");
  const [typeChack, setTypeChack] = useState("");
  const [phoneMothed, setPhoneMothed] = useState<any>([]);
  const [phoneData, setPhoneData] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [LimetMethoad, setLimetMethoad] = useState(false);
  const [Balanceloader, setBalanceloader] = React.useState(false)

  const options = useMemo(() => countryList().getData(), []);

 
  

  useEffect(() => {
    const popup: any = searchParams.get("currency");

    setDatadisplay(popup);
    setcNupdate(props?.bodypyement?.counterpart);
  }, [searchParams]);
  useEffect(() => {
    if (!!props?.MobileMoney) {
      var ArrayData: any = [];
      for (
        let index = 0;
        index < props?.MobileMoney?.methods?.pay_out?.length;
        index++
      ) {
        const element = props?.MobileMoney?.methods?.pay_out[index];
        var object = {
          key: element.key,
          logo: element?.logo,
          correspondent: element?.correspondent,
          title: element.title,
          id: element.id,
          phone_number: "",
        };
        ArrayData.push(object);
      }

      setPhoneMothed(ArrayData);
    }
  }, [props?.MobileMoney]);
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const handleOnChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ) => {
    // setPhoneMothed({ ...phoneMothed, phone_number: value });
    setSubmittedData({ ...submittedData, phone_number: value });
  };
  useEffect(() => {
    setButton_desebal(props.button_dess);
  }, [props.button_dess]);
  useEffect(() => {
    setButton_dataget(props.bodypyement);
    setrqBody(props.bodypyement);
  }, [props.bodypyement]);
  useEffect(() => {
    if (itemRender && props.counterpart !== undefined) {
      setItemRender(false);
      if (!!props.counterpart?.phone_number) {
        // setPhone(props.counterpart?.phone_number);
        setPhoneVlide(props.counterpart?.phone_number);
      }

      setSubmittedData({
        phone_number: props.counterpart?.phone_number,
        paybill_number: props.counterpart?.paybill_number,
        account_number: props.counterpart?.account_number,
        till_number: props.counterpart?.till_number,
      });
    }
  }, [props.counterpart, itemRender]);
  useEffect(() => {
    if (!!datadisplay) {
      setitem(props.bodypyement);
    } else {
      setitem(props.item);
    }
  }, [props]);

  useEffect(() => {
    if (isUserAuthenticated() && render && !!itemm?.amount) {
      setRender(false);
      dispatch(
        dashboardActions.getBalance(
          "/wallet/get-balance",
          setBalanceRender, setBalanceloader
        ) as any
      );
      dispatch(paymentJourneyActions.getFeesNew(itemm?.amount) as any);
    }
  }, [render, isUserAuthenticated]);
  let navigate = useNavigate();

  useEffect(() => {
    if (feesNewRender && dataList?.GETNEWFEES_SUCCESS !== undefined) {
      setFeesNewRender(false);
      setFeesNew(dataList.GETNEWFEES_SUCCESS);
    }
  }, [dataList, feesNewRender]);

  useEffect(() => {
    if (!!itemm && itemm.amount != undefined) {
      dispatch(paymentJourneyActions.getFeesNew(itemm?.amount) as any);
    }
  }, [itemm]);
  useEffect(() => {
    if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
    }
  }, [data, balanceRender]);

  const handleSubmit = (e: any) => {
    const { name, value }: any = e.target;

    setSubmittedData({ ...submittedData, [name]: value });
  };

  const onSave = (reqBoody: any) => {
    props.onSave(option, reqBoody);
  };
  const onCancel = () => {
    props.onCancel();
  };

  const handleOpenPopup = (e: any) => {
    let path = `/balance?type=topup`;
    navigate(path, { state: { type: "topup", color: "green" } });
  };


  // handle-paybill 
  const dataadd = () => {
    var valuse = submittedData?.account_number;
    var sanitizedValue = valuse?.replace(/[^\w\s]/gi, "");
  
    if (
      option === "pay bill" &&
      !!submittedData.paybill_number &&
      !!submittedData.account_number
      // valuse === sanitizedValue
    ) {
      try {
        dispatch(paymentJourneyActions.getPaybillNew(submittedData.paybill_number) as any);
        // dispatch(paymentJourneyActions.getValidateAccount(submittedData.paybill_number, "PayBill") as any);
        setLoader(true);
        // onSubmitted();
        setTimeout(() => {
          setFeesNewRender_two(true);
        }, 3500);
      } catch (error) {
        console.error("Error dispatching actions:", error);
      }
    }
    else if (
      option === "pay bill" &&
      !!submittedData.paybill_number &&
      !!submittedData.account_number
      // valuse !== sanitizedValue
    ) {

      // toast.error(
      //   "One of the payment details is invalid or unsupported. Kindly check and try again."
      // );
    }
  };


  // handle-till-Number
  // const till_numberFun = () => {
  //   dispatch(
  //     paymentJourneyActions.getValidateAccount(
  //       submittedData.till_number,
  //       "TillNumber"
  //     ) as any
  //   );
  //   setLoader(true);
  //   setTimeout(() => {
  //     setTillRender(true);
  //   }, 4000);
  // };


  // useEffect(() => {
  //   if (tillRender && dataList?.GETVALIDATE_ACCOUNT_SUCCESS !== undefined) {
  //     if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == true) {
  //       onSubmitted();
  //       setTillRender(false);
  //       props.setAccountName(dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.data?.name);
  //       setLoader(false);
  //     } else if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == false) {
  //       setLoader(false);
  //       setTillRender(false);
  //       toast.error(dataList?.GETVALIDATE_ACCOUNT_SUCCESS.message);
  //     }
  //   }
  // }, [tillRender, dataList?.GETVALIDATE_ACCOUNT_SUCCESS]);



  useEffect(() => {
    if (feesNewRender_two && dataList?.GET_PAYBILL_NEW_SUCCESS !== undefined) {
      setFeesNewRender_two(false);
   
      if (dataList.GET_PAYBILL_NEW_SUCCESS.success == true) {
        // if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS !== undefined) {
       
        //   if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == true) {
        //     onSubmitted();
        //     setLoader(false);
        //     props.setAccountName(
        //       dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.data?.name
        //     );
        //   } else if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == false) {
        //     setLoader(false);
        //     toast.error(dataList?.GETVALIDATE_ACCOUNT_SUCCESS.message);
        //   }
        // }
        onSubmitted();
        setLoader(false);

        if (!!dataList.GET_PAYBILL_NEW_SUCCESS.message) {
          toast.success(dataList.GET_PAYBILL_NEW_SUCCESS.message);
        }
      }
      // } else {
 
      //   toast.error(dataList?.GET_PAYBILL_NEW_SUCCESS.message);
      //   setLoader(false);
      // }
    }
  }, [feesNewRender_two]);





  const onSubmitted = () => {

    if (option === "send money") {
      props.ChackBalancefun("MPESA-B2C-DARAJA", '', '', '', '', '');
      setLoaderPage(true);
      setTimeout(() => {
        setStartAPI(true);
      }, 5000);
    } else if (option === "pay bill") {
      props.ChackBalancefun("MPESA-B2B-PAYBILL", submittedData.account_number, 'paybill', submittedData.paybill_number, '', '');
      setLoaderPage(true);
      setTimeout(() => {
        setStartAPI(true);
      }, 5000);
    } else if (option === "till number") {
      props.ChackBalancefun("MPESA-B2B-TILL", '', '', '', '', '');
      setLoaderPage(true);
      setTimeout(() => {
        setStartAPI(true);
      }, 5000);
    } else {
      props.ChackBalancefun(typeChack, '', '', '', '', '');
      setLoaderPage(true);
      setTimeout(() => {
        setStartAPI(true);
      }, 5000);
    }
  };
  

  useEffect(() => {
    if (startAPI && dataList?.CHACKBALANCE_SUCCESS !== undefined) {
      if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
        if (datadisplay == "KES") {
          var body = props.bodypyement;
          body.type = option;
          var newBody = cNupdate;
          var reqBoody = rqBody;
          let request: any = "";
          if (option === "send money") {
            newBody = {
              ...newBody,
              ["phone_number"]: submittedData.phone_number,
            };
            setcNupdate({
              ...newBody,
              ["phone_number"]: submittedData.phone_number,
            });
          } else if (option === "pay bill") {
            newBody = {
              ...newBody,
              ["paybill_number"]: submittedData.paybill_number,
            };
            newBody = {
              ...newBody,
              ["account_number"]: submittedData.account_number,
            };
            setcNupdate({
              ...newBody,
              ["paybill_number"]: submittedData.paybill_number,
            });
            setcNupdate({
              ...newBody,
              ["account_number"]: submittedData.account_number,
            });
          } else {
            newBody = {
              ...newBody,
              ["till_number"]: submittedData.till_number,
            };
            setcNupdate({
              ...newBody,
              ["till_number"]: submittedData.till_number,
            });
          }
          if (option === "send money") {
            request = {
              id: props.counterpart?.monite_counterpart_id,
              phone_number: submittedData.phone_number,
            };
          } else if (option === "pay bill") {
            request = {
              id: props.counterpart?.monite_counterpart_id,
              paybill_number: submittedData.paybill_number,
              account_number: submittedData.account_number,
            };
          } else if (option === "till number") {
            request = {
              id: props.counterpart?.monite_counterpart_id,
              till_number: submittedData.till_number,
            };
          }
          reqBoody = { ...reqBoody, ["counterpart"]: newBody };
          setNwe_respons_data(reqBoody);
          var valuse = submittedData?.account_number;
          const sanitizedValue = valuse?.replace(/[^\w\s]/gi, "");

          if (option === "pay bill" && valuse !== sanitizedValue) {
            dispatch(userActions.getOtp(true) as any);
          } else {
            dispatch(userActions.getOtp(false) as any);
          }
          props.transactionExist(option);
          props.setGetFee(dataList?.CHACKBALANCE_SUCCESS?.data)
          dispatch(payablesActions.getOtpverifay() as any);
          if (!!props.counterpart?.monite_counterpart_id) {
            dispatch(payablesActions.createPayableRequest(request) as any);
          }
          {
          }
          onSave(reqBoody);
        } else if (!!datadisplay && datadisplay != "KES") {
          var body = props.bodypyement;
          body.type = option;
          var newBody = cNupdate;
          var reqBoody = rqBody;
          let request: any = "";

          // if (!!option) {
          //   newBody = {
          //     ...newBody,
          //     ["phone_number"]: submittedData.phone_number,
          //   };
          //   setcNupdate({
          //     ...newBody,
          //     ["phone_number"]: submittedData.phone_number,
          //   });
          // }
          var FindNumber = phoneMothed.find((o: any) => o.key == option);
          reqBoody = { ...reqBoody, ["counterpart"]: newBody };
         

          props.setPhone_numberonther(FindNumber?.phone_number);
          // props.setPhone_numberonther()
          onSave(reqBoody);
          props.setGetFee(dataList?.CHACKBALANCE_SUCCESS?.data)
        } else if (!datadisplay) {
          let request: any = "";
          if (option === "send money") {
            request = {
              id: props.counterpart?.monite_counterpart_id,
              phone_number: submittedData.phone_number,
            };
          } else if (option === "pay bill") {
            request = {
              id: props.counterpart?.monite_counterpart_id,
              paybill_number: submittedData.paybill_number,
              account_number: submittedData.account_number,
            };
          } else if (option === "till number") {
            request = {
              id: props.counterpart?.monite_counterpart_id,
              till_number: submittedData.till_number,
            };
          }

          var valuse = submittedData?.account_number;
          const sanitizedValue = valuse?.replace(/[^\w\s]/gi, "");
          if (option === "pay bill" && valuse !== sanitizedValue) {
            dispatch(userActions.getOtp(true) as any);
          } else {
            dispatch(userActions.getOtp(false) as any);
          }
          props.setGetFee(dataList?.CHACKBALANCE_SUCCESS?.data)

          props.transactionExist(option);
          dispatch(payablesActions.getOtpverifay() as any);
          dispatch(payablesActions.createPayableRequest(request) as any);
          onSave(request);
        }
      } else {
        setChackBalance(false);
        setLoaderPage(false);
        setStartAPI(false);
        setLoader(false);
      }
    }
  }, [startAPI, dataList?.CHACKBALANCE_SUCCESS]);
  useEffect(() => {
    if (!!options && !!props?.MobileMoney[0]?.country?.currency) {
      const findcurrencies: any = options.find(
        (data: any) => data.label == props?.MobileMoney[0]?.country?.name
      );

      var country_sortname_list = findcurrencies?.value?.toLowerCase();
  
      setcountry_codephone(country_sortname_list);
      setcountry_code(country_sortname_list);
    }
  }, [props?.MobileMoney, options]);


  useEffect(() => {
    if (LimetChack && dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS !== undefined) {
      if (dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS?.success == true) {
        setLimetChack(false);
        setLoaderPage(false);
        setLimetMethoad(true);
      } else if (dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS?.success == false) {
        setLoaderPage(false);
        setLimetChack(false);
        setLimetMethoad(false);
        setErrorMessage(dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS.message);
        // toast.error(dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS.message, {
        //   duration: 8000,
        // });
      }
    }
  }, [LimetChack, dataList?.GETVALIDATE_ACCOUNT_SUCCESS]);


  return (
    <>
      {LoaderPage && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
      {props?.MobileMoney?.country?.currency == "KES" ? (
        <div className="mpesa-container">
          {/* <div className='mpesa-container__heading'>
                <img src='/assets/mpesa_logo.svg' />
            </div> */}
          <div
            className={`mpesa-container__option-wrapper mpaisa ${option === "send money" ? "border-green" : ""
              } ${!!itemm && itemm.amount < 10 ? "disabled" : ""}`}
            onClick={() => {
              if (!!itemm && itemm.amount >= 10) {
                setErrorShow(false);
                setPaybleOption(true);
                // if (!!balance[0]?.total_balance && feesNew && itemm && itemm.amount) {
                //     if ((balance[0]?.total_balance / 100) <= (feesNew['MPESA B2C DARAJA'] + itemm.amount)) {
                //         setPaybleOption(false)
                //         setErrorShow(true)
                //     }
                // }
                setOption("send money");
              }
            }}
          >
            <div className="mpesa-container__option-wrapper__txt" style={{ display: "flex", alignItems: "center" }}>
              <div className="imgMetheds">
                <img src='https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/mpesa-kenya.jpeg' width="100%" height="100%" alt="" />
              </div>
              Send Money
            </div>
            {option === "send money" ? <UpArrow /> : <DownArrow />}
          </div>
          {option === "send money" && (
            <div
              className={`mpaisa mb-3 ${!!itemm && itemm.amount < 10 ? "disabled" : ""
                }`}
            >
              <PhoneInput
                inputProps={{
                  name: "phone_number",
                  required: true,
                  autoFocus: true,
                }}
                country={"ke"}
                prefix=""
                value={submittedData?.phone_number}
                onChange={(value, country: countryProps, e, formattedValue) => {
                  handleOnChange(value, country, e, formattedValue);
                  // setPhone(
                  //   formattedValue.substring(country?.dialCode?.length + 1)
                  // );
                  setphoneinit(false);
                  setcountry_code(country.dialCode);
                  setPhoneVlide(value);
                }}
                inputClass={"inputBx-mobile"}
              />
              {phoneinit == false ? (
                !!phoneVlide ? (
                  phoneVlide.length != 12 ? (
                    <div className="red invalid-feedback d-block">
                      Please enter 9 digit phone number
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="red invalid-feedback d-block">
                    Phone number is required
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          )}
          {!!itemm && itemm.amount < 10 ? (
            <div
              style={{ padding: "10px 0px", color: "red" }}
              className="mpesa-container__errorMsg"
            >
              For Send Money, please enter an amount greater than or equal to
              10.
            </div>
          ) : (
            ""
          )}
          <div
            className={`mpesa-container__option-wrapper ${option === "pay bill" ? "border-green" : ""
              }`}
            onClick={() => {
              setErrorShow(false);
              setPaybleOption(true);
              // if (!!balance[0]?.total_balance && feesNew && itemm && itemm.amount) {
              //     if ((balance[0]?.total_balance / 100) <= (feesNew['MPESA B2B PAYBILL'] + itemm.amount)) {
              //         setPaybleOption(false)
              //         setErrorShow(true)
              //     }
              // }
              setOption("pay bill");
            }}
          >
            <div className="mpesa-container__option-wrapper__txt" style={{ display: "flex", alignItems: "center" }}>
              <div className="imgMetheds">
                <img src='https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/mpesa-kenya.jpeg' width="100%" height="100%" alt="" />
              </div>
              Paybill Number
            </div>
            {option === "pay bill" ? <UpArrow /> : <DownArrow />}
          </div>
          {option === "pay bill" && (
            <div>
              <InputGroup className="input-wrapper input">
                <Form.Control
                  className="mpesa-form-input"
                  placeholder="Input paybill number here."
                  value={submittedData?.paybill_number}
                  onChange={handleSubmit}
                  name="paybill_number"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>
              <InputGroup className="input-wrapper input">
                <Form.Control
                  className="mpesa-form-input"
                  placeholder="Input account number here."
                  value={submittedData?.account_number}
                  onChange={(e: any) => {
                    handleSubmit(e);
                    const sanitizedValue = e.target.value.replace(
                      /[^\w\s]/gi,
                      ""
                    ); // Regex to remove special characters
                    setError(e.target.value !== sanitizedValue ? true : false);
                  }}
                  name="account_number"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>
              {submittedData?.account_number?.length > 13 ? (
                <div className="red invalid-feedback d-block">
                  {" "}
                  The account number must not exceed 13 digits.
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <div
            className={`mpesa-container__option-wrapper ${option === "till number" ? "border-green" : ""
              }`}
            onClick={() => {
              setErrorShow(false);
              setPaybleOption(true);
              // if (balance[0]?.total_balance && feesNew && itemm && itemm.amount) {
              //     if ((balance[0].total_balance / 100) <= (feesNew['MPESA B2B TILL'] + itemm.amount)) {
              //         setPaybleOption(false)
              //         setErrorShow(true)
              //     }
              // }
              setOption("till number");
            }}
          >
            <div className="mpesa-container__option-wrapper__txt" style={{ display: "flex", alignItems: "center" }}>
              <div className="imgMetheds">
                <img src='https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/mpesa-kenya.jpeg' width="100%" height="100%" alt="" />
              </div>
              Till Number
            </div>
            {option === "till number" ? <UpArrow /> : <DownArrow />}
          </div>
          {option === "till number" && (
            <div>
              <InputGroup className="input-wrapper input">
                <Form.Control
                  className="mpesa-form-input"
                  placeholder="Input till number here."
                  value={submittedData?.till_number}
                  onChange={handleSubmit}
                  name="till_number"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>
            </div>
          )}
          {/* {errorShow == true ? (<div className='mpesa-container__errorMsg'>
                Add funds to account to cover payment for the bill
            </div>) : ""} */}
          {ChackBalance == false ? (
            <div className="mpesa-container__errorMsg">
              Please top up your account to cover the payment.
            </div>
          ) : (
            ""
          )}
          <div className="mpesa-container__btn-row">
            <Button
              className="mpesa-container__btn-row__white"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            {datadisplay == "KES" ? (
              <>
                {button_desebal == false ? (
                  <Button
                    disabled
                    className="mpesa-container__btn-row__green"
                    onClick={() => {
                      onSubmitted();
                    }}
                  >
                    Proceed{" "}
                    {loading == true && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                  </Button>
                ) : (
                  <Button
                    className="mpesa-container__btn-row__green"
                    disabled={
                      option === "" ||
                      // paybleOption == false ||
                      (option == "send money" && phoneVlide.length != 12) ||
                      (option == "till number" && !submittedData.till_number) ||
                      (option == "pay bill" &&
                        (!submittedData.account_number ||
                          !submittedData.paybill_number ||
                      submittedData?.account_number?.length > 13))

                    }
                    onClick={() => {
                      if (option === "pay bill") {
                        dataadd();
                        onSubmitted();
                        setChackBalance(true);
                      } else if (option === "till number") {
                        // till_numberFun();
                        onSubmitted();
                        setChackBalance(true);
                      } else if (option == "send money") {
                        onSubmitted();
                        setChackBalance(true);
                      } else {

                        onSubmitted();
                        setChackBalance(true);

                      }
                    }}
                  >
                    Proceed{" "}
                    {loading == true && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  className="mpesa-container__btn-row__green"
                  disabled={
                    option === "" ||
                    // paybleOption == false ||
                    (option == "send money" && phoneVlide.length != 12) ||
                    (option == "till number" && !submittedData.till_number) ||
                    (option == "pay bill" &&
                      (!submittedData.account_number ||
                        !submittedData.paybill_number ||
                    submittedData?.account_number?.length > 13))
                  }
                  onClick={() => {
                    if (option === "pay bill") {
                      dataadd();
                      onSubmitted();
                      setChackBalance(true);
                    } else if (option === "till number") {
                      // till_numberFun();
                      onSubmitted();
                      setChackBalance(true);
                    } else {
                      onSubmitted();
                      setChackBalance(true);
                    }
                  }}
                >
                  Proceed{" "}

                  {loading == true && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="mpesa-container">

          {phoneMothed.map((data: any, inndex: number) => {
            return (
              <>
                <div
                  className={`mpesa-container__option-wrapper mpaisa ${option === "send money" ? "border-green" : ""
                    }
                   `}
                  onClick={() => {
                    // if (!!itemm && itemm.amount >= 10) {
                    setErrorShow(false);
                    setPaybleOption(true);
                    setTypeChack(data?.correspondent);
                    // if(typeChack === data?.correspondent){
                    setPhoneVlidetwo('');
                    // }
                    setOption(data.key);
                    var body = {
                      currency: datadisplay,
                      amount: itemm?.amount,
                      paymentchannel: data.key,
                    };
                    props.ChackLimet(body);
                    setLoaderPage(true)
                    setTimeout(() => {
                      setLimetChack(true);
                    }, 3000);
                    // }
                  }}
                >
                  <div
                    className="mpesa-container__option-wrapper__txt"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="imgMetheds">
                      <img src={data.logo} width="100%" height="100%" alt="" />
                    </div>
                    {data.title}
                  </div>
                  {data?.correspondent === typeChack ? (
                    <UpArrow />
                  ) : (
                    <DownArrow />
                  )}
                </div>
                {!!ErrorMessage && LimetMethoad == false ? (
                  <>
                    {typeChack == data?.correspondent ? (
                      <div className="red invalid-feedback d-block">
                        {ErrorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {typeChack === data?.correspondent && LimetMethoad == true && (
                  <div
                    className={`mpaisa mb-3
                      
                    `}
                  >
                    <PhoneInput
                      // inputProps={{
                      //   name: "phone_number",
                      //   required: true,
                      //   autoFocus: true,
                      // }}
                      country={props.listContryset[0]}
                      onlyCountries={props.listContryset}
                      // localization={{ de: contryCodephone }}
                      // country={"ke"}
                      prefix=""
                      value={data.phone_number}
                      onChange={(
                        value,
                        country: countryProps,
                        e,
                        formattedValue
                      ) => {
                        var newArray = [];
                        // // handleOnChange(value, country, e, formattedValue);
                        // setPhone(
                        //   formattedValue.substring(
                        //     country?.dialCode?.length + 1
                        //   )
                        // );
                    
                        setphoneinit(false);
                        setcountry_code(country.dialCode);
                        setPhoneVlidetwo(value);

                        for (
                          let index = 0;
                          index < phoneMothed.length;
                          index++
                        ) {
                          const element = phoneMothed[index];
                          if (element?.id === data?.id) {
                            data.phone_number = value.toString();
                            newArray.push(JSON.parse(JSON.stringify(data)));
                          } else {
                            newArray.push(JSON.parse(JSON.stringify(element)));
                          }
                        }
                        setPhoneMothed(newArray);
                      }}
                      inputClass={"inputBx-mobile"}
                    />
                    {phoneinit == false ? (
                      !!phoneVlidetwo ? (
                        phoneVlidetwo.length != 12 ? (
                          <div className="red invalid-feedback d-block">
                            Please enter 9 digit phone number
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="red invalid-feedback d-block">
                          Phone number is required
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {/* {!!itemm && itemm.amount < 10 ? (
                  <div
                    style={{ padding: "10px 0px", color: "red" }}
                    className="mpesa-container__errorMsg"
                  >
                    For Send Money, please enter an amount greater than or equal
                    to 10.
                  </div>
                ) : (
                  ""
                )} */}
              </>
            );
          })}
          <div className="mpesa-container__btn-row">
            <Button
              className="mpesa-container__btn-row__white"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <>
              {button_desebal == true && LimetMethoad == true && phoneVlidetwo.length == 12 ?
                (
                  <Button
                    className="mpesa-container__btn-row__green"
                    // disabled={
                    //   option === ""
                    // }
                    onClick={() => {
                      // if (option === "pay bill") {
                      //   dataadd();
                      //   setChackBalance(true);
                      // } else if (option === "till number") {
                      //   till_numberFun();
                      //   setChackBalance(true);
                      // } else {
                      // if(phoneVlide.length == 12){
                      onSubmitted();
                      setChackBalance(true);
                      // }

                      // }
                    }}
                  >
                    Proceed{" "}
                    {loading == true && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                  </Button>
                )
                : (
                  <Button
                    disabled
                    className="mpesa-container__btn-row__green"
                    onClick={() => {
                      // onSubmitted();
                    }}
                  >
                    Proceed{" "}
                    {loading == true && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                  </Button>
                )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

const DownArrow = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.03613 4.91386L8.8028 1.14053C8.86529 1.07855 8.91488 1.00482 8.94873 0.92358C8.98257 0.84234 9 0.755203 9 0.667195C9 0.579187 8.98257 0.49205 8.94873 0.41081C8.91488 0.329571 8.86529 0.255837 8.8028 0.193862C8.67789 0.0696944 8.50893 0 8.3328 0C8.15668 0 7.98771 0.0696944 7.8628 0.193862L4.52947 3.49386L1.22947 0.193862C1.10456 0.0696944 0.935593 0 0.759469 0C0.583344 0 0.414377 0.0696944 0.289469 0.193862C0.226477 0.255605 0.176363 0.329235 0.142032 0.410485C0.1077 0.491735 0.0898352 0.57899 0.089469 0.667195C0.0898352 0.7554 0.1077 0.842655 0.142032 0.923905C0.176363 1.00516 0.226477 1.07879 0.289469 1.14053L4.05613 4.91386C4.11856 4.98153 4.19433 5.03554 4.27866 5.07248C4.363 5.10941 4.45407 5.12848 4.54613 5.12848C4.6382 5.12848 4.72927 5.10941 4.81361 5.07248C4.89794 5.03554 4.97371 4.98153 5.03613 4.91386Z"
      fill="#DDDDDD"
    />
  </svg>
);
const UpArrow = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.05371 0.214556L0.287043 3.98789C0.224557 4.04986 0.174961 4.1236 0.141115 4.20484C0.107269 4.28608 0.0898438 4.37322 0.0898438 4.46122C0.0898438 4.54923 0.107269 4.63637 0.141115 4.71761C0.174961 4.79885 0.224557 4.87258 0.287043 4.93456C0.411951 5.05872 0.580919 5.12842 0.757043 5.12842C0.933166 5.12842 1.10213 5.05872 1.22704 4.93456L4.56038 1.63456L7.86038 4.93456C7.98528 5.05872 8.15425 5.12842 8.33037 5.12842C8.5065 5.12842 8.67547 5.05872 8.80037 4.93456C8.86337 4.87281 8.91348 4.79918 8.94781 4.71793C8.98214 4.63668 9.00001 4.54943 9.00037 4.46122C9.00001 4.37302 8.98214 4.28576 8.94781 4.20451C8.91348 4.12326 8.86337 4.04963 8.80037 3.98789L5.03371 0.214556C4.97128 0.146886 4.89551 0.0928807 4.81118 0.0559425C4.72685 0.0190043 4.63578 -6.58035e-05 4.54371 -6.58035e-05C4.45164 -6.58035e-05 4.36057 0.0190043 4.27624 0.0559425C4.19191 0.0928807 4.11614 0.146886 4.05371 0.214556Z"
      fill="#DDDDDD"
    />
  </svg>
);
