import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button } from "react-bootstrap";
import "../PayOptions/Payoptions.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { selectAllData } from '../../../../../../_reducers/dashboard.reducer';
import { Box, CircularProgress } from "@mui/material";
import { isUserAuthenticated } from "../../../../../../_helpers";
import { dashboardActions } from "../../../../../../_actions";


const USDExpressPayment = (props: any) => {
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [data_ahahhahhajaja, setData_sjshhsgsjs] = useState(false);
  const [selectMethod, setSelectMethod] = useState("");
  const [selectType, setSelectType] = useState("");
  const [selectMethodType, setSelectMethodType] = useState("");
  const [startAPI, setStartAPI] = useState(false);
  const [LoaderPage, setLoaderPage] = useState(false);
  const [chackBalance, setChackBalance] = useState(true);
  const [balance, setBalance] = useState<any>([]);
  const [balanceRender, setBalanceRender] = useState(true);
  const [loderinapi, setLoderapi] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const data = useSelector((state) => selectAllData(state));
  const [render, setRender] = useState(true)
  const dataList = useSelector((state) => selectPaymentJourneyData(state))
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const[Balanceloader,setBalanceloader]=React.useState(false)

 

  //   useEffect(() => {
  //     const popup:any = searchParams.get("currency");
  //   if(!!popup){
  //       setDatadisplay(popup);
  //   }else{
  //     setDatadisplay("")
  //   }
  //   }, [searchParams]);

  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(
        dashboardActions.getBalance(
          '/wallet/get-balance',
          setBalanceRender,setBalanceloader
        ) as any
      );
      //   setTimeout(()=>{
      //     setBalanceRender(true);
      //   },4000)
    }
  }, [render, isUserAuthenticated]);

  // get dashboard balance
  useEffect(() => {
    if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
      setLoderapi(false);
      setLoader(false);

    }
  }, [data, balanceRender]);



  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const onSelect = (el: string) => {
    props.onSelect(el);
  };
  const onSelect_modefe = (el: string) => {
    props.onSelect_modefe(el);
  };
  useEffect(() => {
    if (startAPI && dataList?.CHACKBALANCE_SUCCESS !== undefined) {
      if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
        setStartAPI(false);
        setLoaderPage(false);
      

        if (selectMethodType == "RTGS") {
          onSelect("RTGS");
        } else if (selectMethodType == "EFT") {
          onSelect("EFT");
        } else if (selectMethodType == "SWIFT") {
          onSelect("SWIFT");
        } else if (selectMethodType == "Digital currency") {
      

          onSelect("Digital Currency");
        }
       
        props.setGetFee(dataList?.CHACKBALANCE_SUCCESS?.data)
        setChackBalance(true);
      } else {
        setChackBalance(false);
        setStartAPI(false);
        setLoaderPage(false);
      }
    }
  }, [startAPI, dataList?.CHACKBALANCE_SUCCESS]);


  return (
    <>
      {LoaderPage && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
      <div className="m-pesa-container">
        <div className="available_com">
          {balance &&
            <div>
              <span className="balance_lable">Available Balance </span>
              <span className="balance_amount">
                {balance[1]?.available_balance
                  ? (
                    balance[1]?.currency + " " + (balance[1]?.available_balance / 100).toLocaleString('en', {
                      minimumFractionDigits: 2,
                    })
                  )
                  : '0.00'
                }
              </span>
            </div>
          }
        </div>
        <div className="calss_accordion_contenar">
          <ThemeProvider theme={theme}>
            <div
              className="maian_adaat_listtts"
              onClick={() => {
                if (paymentacodiyan == false) {
                  setPaymentacodiyan(true);
                } else {
                  setPaymentacodiyan(false);
                }
              }}
            >
              <div className="avayar_text">
                <Avatar className="Avatar_fast">3</Avatar>
                <p className="accordion_p_data_contean">Payment Method</p>
              </div>

              {paymentacodiyan == false ?
                <ExpandMoreIcon style={{ color: "#1D1D1D" }} /> : <ExpandLessIcon style={{ color: "#1D1D1D" }} />}
            </div>
            {paymentacodiyan == true ?
              <div className="accordion_details_contean">
                <div className="mainana_cantena_daat_potrrr">
                  {props.button_dess == true ?
                    <Button className={selectMethod == "Bank transfer" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                      disabled={props?.item?.amount >= 99 && !!props?.item?.invoice_record ? false : true}
                      onClick={() => {
                        // if(!!props?.item?.invoicerecord){
                        setSelectMethod("Bank transfer")
                        // }
                      }}>
                        <VisaCardIcon />
                        <p className="textTypo"> Bank Transfer</p>
                    </Button> : <Button
                      className={selectMethod == "Bank transfer" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                      onClick={() => {
                        onSelect_modefe('')
                        setData_sjshhsgsjs(true)

                      }}>
                        <VisaCardIcon />
                        <p className="textTypo"> Bank Transfer</p>
                    </Button>}
                  {(props.button_dess == true)
                    ?
                    <Button
                      disabled={props?.item?.amount >= 99 && !!props?.item?.invoice_record ? false : true}
                      className={selectMethod == "Digital currency" ? "Minn_button_mpesa_datata" : "calss_not_active_data"} onClick={() => {
                        setSelectMethodType("Digital currency")
                        setSelectMethod("Digital currency")
                        props.ChackBalancefun('digital')
                        setLoaderPage(true)
                        setTimeout(() => {
                          setStartAPI(true)
                        }, 5000)
                      }}>
                        <VisaCardIcon />
                        <p className="textTypo">
                      Digital currency</p>
                    </Button> : <Button
                      className={selectMethod == "Digital currency" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                      onClick={() => {
                        onSelect_modefe('')
                      }}>
                         <VisaCardIcon />
                         <p className="textTypo">
                      Digital currency
                      </p>
                    </Button>}
                </div>
                {/* {props.chackBalance == "failed" ? <div className='mpesa-container__errorMsg'> Please top up your account to cover the payment.</div> : ""}
                                {props?.item?.amount <= 99 == true ? <div className='mpesa-container__errorMsg'> Please enter an amount greater or equal to USD 100</div> : ""} */}
                {selectMethod == "Bank transfer"&&!!props?.item?.invoice_record ?
                  <div style={{ display: "block" }} className="mainana_cantena_daat_potrrr">
                    <Button style={{ width: "100%", marginBottom: "10px", fontSize: "14px", display: "block" }}
                      className={selectType == "RTGS" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                      onClick={() => {

                        setSelectMethodType("RTGS")
                        props.ChackBalancefun('rtgs')
                        setLoaderPage(true)
                        setTimeout(() => {
                          setStartAPI(true)
                        }, 5000)

                      }}>
                      RTGS
                      <br />
                      <span className="description">Debits within 2-3 hours</span>
                      <p className='description_limit'>No transaction limit</p>
                    </Button>
                    <Button style={{ width: "100%", marginBottom: "10px", fontSize: "14px", display: "block" }}
                      className={selectType == "EFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                      onClick={() => {
                        setSelectMethodType("EFT")
                        props.ChackBalancefun('eft')
                        setLoaderPage(true)
                        setTimeout(() => {
                          setStartAPI(true)
                        }, 5000)
                      }}>
                      EFT
                      <br />
                      <span className="description">Debits within 2-3 business days</span>
                      <p className='description_limit'>No transaction limit</p>
                    </Button>
                    <Button style={{ width: "100%", marginBottom: "10px", fontSize: "14px", display: "block" }}
                      className={selectType == "SWIFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                      onClick={() => {
                        setSelectMethodType("SWIFT")
                        props.ChackBalancefun('swift')
                        setLoaderPage(true)
                        setTimeout(() => {
                          setStartAPI(true)
                        }, 5000)
                      }}>
                      SWIFT
                      <br />
                      <span className="description">Debits within 2-3 business days</span>
                      <p className='description_limit'>No transaction limit</p>
                    </Button>
                  </div>

                  : ""}
              </div> : ""}

            {/* available balance  */}

            {!!props.item && props.item.amount <= 99 && message === "" ?
              <div style={{ padding: "10px 0px", color: "red" }} className='mpesa-container__errorMsg'>
                Please enter an amount greater than or equal to USD 100.
              </div>
              : ''
            }

            {!!message ?
              <div style={{ padding: "10px 0px", color: "red" }} className='mpesa-container__errorMsg'>
                {message}
              </div>
              : ''
            }

            {/* {!!props.item && props.item.amount >= 100 && props.item.amount <= 2500 ? <div style={{ padding: "10px 0px", color: "black" }} className='mpesa-container__errorMsg'>
              All USD payment methods are subject to a recommended minimum transaction amount of USD 2,500. You can still proceed to transact amounts lower than this, subject to a flat fee of USD 50.</div> : ""} */}
           
            {!!props?.item&&!props?.item?.invoice_record ? 
            <div className='mpesa-container__errorMsg'>
              Please attach a supporting document for the transaction.
            </div> : ""}
            {chackBalance == false ? <div className='mpesa-container__errorMsg'>
              Please top up your account to cover the payment.
            </div> : ""}
          </ThemeProvider>
        </div>
      </div>
    </>
  )
}
export default USDExpressPayment;

const VisaCardIcon = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
      fill="#025041"
    />
  </svg>
);
